import React from 'react';
import _ from 'lodash';
import { Modal, Button, Header, List, Message } from 'buildingBlocks';

type Props = {
  itemsToDelete: { [key: string]: { id: number } }
  onCancel: (...args: Array<any>) => void
  onDelete: (...args: Array<any>) => void
  open: boolean
  deleting?: boolean
  footerMessage?: string
  modalName: string
  deleteMessage?: string
  itemName?: string
  disabled?: boolean
};

type RemoveModalListProps = {
  itemsToDelete: { [key: string]: { id: number } }
  itemName?: string
};

export const RemoveModalList = ({ itemsToDelete, itemName = 'name' }: RemoveModalListProps) => (
  <List celled>
    {
      _.map(itemsToDelete, (item) => (
        <List.Item key={item.id}>
          <List.Icon name="trash" color="red" />
          <List.Content>
            {_.get(item, itemName, item.id)}
          </List.Content>
        </List.Item>
      ))
    }
  </List>
);

export const DeleteConfirmationModalComponent = ({
  itemsToDelete,
  onCancel,
  open,
  onDelete,
  deleting,
  footerMessage,
  modalName,
  deleteMessage,
  itemName,
  disabled,
}: Props) => (
  <Modal
    open={open}
  >
    <Header icon="trash" content={modalName} />
    <Modal.Content>
      {deleteMessage
      && (
      <p>
        {deleteMessage}
      </p>
      )}
      <RemoveModalList
        itemsToDelete={itemsToDelete}
        itemName={itemName}
      />
      <p>Are you sure you wish to proceed? This action cannot be undone.</p>
    </Modal.Content>
    <Modal.Actions>
      {footerMessage && <Message style={{ textAlign: 'center' }} error size="small">{footerMessage}</Message>}
      <Button secondary onClick={onCancel}>
        No
      </Button>
      <Button primary onClick={() => onDelete(itemsToDelete)} loading={deleting} disabled={disabled}>
        Yes
      </Button>
    </Modal.Actions>
  </Modal>
);

export default DeleteConfirmationModalComponent;
