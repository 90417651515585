import { AnyAction } from 'redux';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import { Effect, delay } from 'redux-saga';
import _ from 'lodash';
import moment from 'moment';
import { HTTP_STATUS, FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { Microservices } from 'utils/copilotAPI';
import { fetchFlights, fetchMemberAdvertiserAndFlights } from 'utils/sharedSagas';
import metrics from 'containers/StrategyAnalytics/constants/metricsConstants';
import {
  FETCH_FLIGHTS,
  FORM_SUBMITTED,
  FLIGHT_DROPDOWN_LIMIT,
  DEFAULT_FAIL_MESSAGE,
  INIT_FROM_QUERY_STRING,
} from './constants';
import {
  fetchFlightsSucceeded,
  fetchFlightsFailed,
  fetchDataCompleted,
  fetchDataFailed,
  endDateError,
  handleFormSubmit,
  resetPageTwoLoader,
} from './actions';
import { FormValues, FlightExtIdToAlias } from './types';
import { getABInsightsDataAdapter } from './interfaces';

const setError = (err: string | Error, overrideMessage?: string) => (
  {
    error: overrideMessage || DEFAULT_FAIL_MESSAGE,
    status: _.get(err, 'response.status', HTTP_STATUS.ERROR),
  });

enum Status {
  queued = 'queued',
  inProgress = 'in_progress',
  complete = 'complete',
  error = 'error',
}

type TaskStatus = {
  status: Status
  error?: string
};

const POLL_DELAY_TIME_MS = 10 * 1000;
const MAX_TOTAL_POLLING_TIME_MS = 10 * 60 * 1000;
const MAX_ITERATIONS = MAX_TOTAL_POLLING_TIME_MS / POLL_DELAY_TIME_MS;

export function* pollABInsightsMicroservice(
  taskId: string,
  flightExtIdToAlias: FlightExtIdToAlias,
  flightLeftId: string,
  flightRightId: string,
  dsp: number,
) {
  try {
    for (let i = 0; i < MAX_ITERATIONS; i++) { // eslint-disable-line no-plusplus
      const { data: { status, error } }: { data: TaskStatus } = yield call(Microservices.getTaskStatus, taskId);
      if (status === Status.error) {
        if (error.includes('NoDataError')) {
          yield put(fetchDataFailed({ error: 'There was no data for the line items you selected.', status: 204 }));
          return;
        }
        throw new Error(error);
      } else if (status === Status.complete) {
        const { data } = yield call(Microservices.getTaskResult, taskId);
        const abInsightsData = getABInsightsDataAdapter(dsp, data, flightLeftId, flightRightId, flightExtIdToAlias);
        yield put(fetchDataCompleted(abInsightsData));
        return;
      }
      yield call(delay, POLL_DELAY_TIME_MS);
    }
    yield put(fetchDataFailed(setError(
      '',
      'Request for data has timed out. Please check back in about an hour, or reduce the date range.',
    )));
  } catch (error) {
    yield put(fetchDataFailed(setError(error)));
  }
}

export function* fetchData(values: AnyAction) {
  try {
    const {
      startDate,
      endDate,
      flightLeft,
      flightLeftAlias,
      flightRight,
      flightRightAlias,
      flightType,
      member,
      advertiser,
    } = values.payload as FormValues;
    if (_.isNil(flightLeft) || _.isNil(flightRight)) {
      yield put(fetchDataFailed(setError(
        '',
        `One or both of your ${FLIGHT_EXTERNAL_TYPE.getById(flightType).displayName}s are invalid.`,
      )));
      return;
    }
    const flightExtIdToAlias = {
      [flightLeft.externalId]: flightLeftAlias,
      [flightRight.externalId]: flightRightAlias,
    };

    const { data: { id } } = yield call(
      Microservices.runService,
      {
        member_ext_id: member.externalId,
        adv_ext_id: advertiser.externalId,
        control_flight_ext_id: flightLeft.externalId,
        control_ext_type: flightType,
        test_flight_ext_id: flightRight.externalId,
        test_ext_type: flightType,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
      },
      'ab_test_dataviz',
    );

    yield call(pollABInsightsMicroservice, id, flightExtIdToAlias, flightLeft.externalId, flightRight.externalId, member.dsp);
  } catch (error) {
    yield put(fetchDataFailed(setError(error)));
  }
}

const handleInitFailure = (err: any) => fetchDataFailed(setError(err));

export function* initializeFromQueryString({ payload: { initialValues, startDate, endDate } }: AnyAction) {
  try {
    yield put(resetPageTwoLoader());
    const { member, advertiser, flightLeft, flightRight, flightType, title, flightLeftAlias, flightRightAlias, KPIs, currentTab } = initialValues;
    const ret = yield* fetchMemberAdvertiserAndFlights(
      { member, flightType, flightLeft, flightRight, advertiser },
      handleInitFailure,
    );

    if (ret) {
      const earliestAllowedDate = moment.utc().subtract(60, 'd');
      if (endDate.isBefore(earliestAllowedDate)) {
        yield put(endDateError());
        return;
      }

      const KPI_OPTIONS = _.assign({}, metrics.ratePercentage, metrics.aggregator);
      const queryStringDetails = {
        ...ret,
        currentTab,
        KPIs: _.map(_.split(KPIs, ','), (v) => KPI_OPTIONS[v]),
        startDate: moment(startDate),
        endDate: moment(endDate),
        title,
        flightLeftAlias,
        flightRightAlias,
      };

      yield put(handleFormSubmit(queryStringDetails));
    }
  } catch (error) {
    yield put(handleInitFailure(error));
  }
}

const onFlightFetchFailed = () => fetchFlightsFailed({ error: 'Failed to fetch flights', status: HTTP_STATUS.ERROR });

function* ABReportingSagas(): Iterable<Effect> {
  yield all([
    // @ts-ignore redux-saga fetchFlights
    takeLatest(
      FETCH_FLIGHTS,
      fetchFlights,
      fetchFlightsSucceeded,
      onFlightFetchFailed,
      FLIGHT_DROPDOWN_LIMIT,
    ),
    takeLatest(FORM_SUBMITTED, fetchData),
    takeLatest(INIT_FROM_QUERY_STRING, initializeFromQueryString),
  ]);
}

export default ABReportingSagas;
