import React, { ReactNode } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Menu } from 'buildingBlocks';

type Props = {
  to: string,
  children: ReactNode,
};
const TabItem = ({ to, children }: Props) => {
  const match = useMatch(to);
  return (
    <Link to={to}>
      <Menu.Item active={match && match.pathname === to}>
        {children}
      </Menu.Item>
    </Link>
  );
};

export default TabItem;
