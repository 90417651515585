import moment, { Moment } from 'moment';
import { Flight } from 'utils/types';
import { VizId } from 'charts/ABReportViz/constants';
import {
  FORM_SUBMITTED,
  FETCH_FLIGHTS,
  FETCH_FLIGHTS_SUCCEEDED,
  FETCH_FLIGHTS_FAILED,
  DEFAULT_FLIGHT_TYPE,
  FETCH_DATA_COMPLETED,
  FETCH_DATA_FAILED,
  RESET_LOADER,
  END_DATE_ERROR,
  INIT_FROM_QUERY_STRING,
  UPDATE_CURRENT_VISUALIZATION,
} from './constants';
import { FormValues, QueryStringValues } from './types';
import { ABInsightsDataDSPSubtype } from './interfaces';

export const handleFormSubmit = (values: FormValues) => ({
  type: FORM_SUBMITTED,
  payload: {
    ...values,
    startDate: moment(values.startDate),
    endDate: moment(values.endDate),
  },
});

export const fetchDataCompleted = (data: ABInsightsDataDSPSubtype) => ({
  type: FETCH_DATA_COMPLETED,
  payload: {
    dataAdapter: data,
  },
});

export const fetchDataFailed = (msg: { error: string, status: number }) => ({
  type: FETCH_DATA_FAILED,
  payload: msg,
});

export const fetchFlights = (
  memberId: number,
  advertiserId: number,
  flightType: number = DEFAULT_FLIGHT_TYPE,
  searchString: string = '',
) => ({
  type: FETCH_FLIGHTS,
  payload: {
    memberId,
    advertiserId,
    flightType,
    searchString,
  },
});

export const fetchFlightsSucceeded = (flights: Array<Flight>) => ({
  type: FETCH_FLIGHTS_SUCCEEDED,
  payload: flights,
});

export const fetchFlightsFailed = (msg: {
  error: string,
  status: number
}) => ({
  type: FETCH_FLIGHTS_FAILED,
  payload: msg,
});

export const resetPageTwoLoader = () => ({
  type: RESET_LOADER,
});

export const endDateError = () => ({
  type: END_DATE_ERROR,
  payload: 'Copilot can only display data from the last 60 days - please select a more recent End Date.',
});

export const initFromQueryString = (initialValues: QueryStringValues, startDate: Moment, endDate: Moment) => ({
  type: INIT_FROM_QUERY_STRING,
  payload: { initialValues, startDate, endDate },
});

export const updateCurrentTab = (tabName: VizId) => ({
  type: UPDATE_CURRENT_VISUALIZATION,
  payload: { currentTab: tabName },
});
