export const subnav = {
  color: '#607D8B',
  marginBottom: '0px',
};
export const header = {
  margin: 'initial',
  fontWeight: '500',
  display: 'inline-block',
  userSelect: 'initial',
  color: '#63747a',
  fontSize: '1.571em',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};
export const containerStyle = {
  maxHeight: '45px',
  marginTop: '27px',
  marginBottom: '-3px',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};
