import React from 'react';
import { WppActionButton, WppButton, WppFullScreenModal, WppIconClose, WppTypography } from 'buildingBlocks';
import fullScreenModalStyles from './style';

const { modalHeaderContainerStyle, modalHeaderStyle, modalHeaderTitleStyle, modalCrossIcon, modalBodyStyle, modalButtonAlignment } = fullScreenModalStyles;

type Props = {
  headerTitle: string
  body: string
  detailsModalOpen: boolean
  handleDetailsModalClose: () => void
};

const FullScreenModal = ({ headerTitle, body, detailsModalOpen, handleDetailsModalClose }: Props) => (
  <WppFullScreenModal open={detailsModalOpen}>
    <WppTypography type="xl-heading" slot="header" style={modalHeaderContainerStyle}>
      <div style={modalHeaderStyle}>
        <div style={modalHeaderTitleStyle}>{headerTitle}</div>
        <WppActionButton onClick={handleDetailsModalClose}>
          <WppIconClose size="m" style={modalCrossIcon} />
        </WppActionButton>
      </div>
    </WppTypography>
    <WppTypography type="s-body" slot="body" style={modalBodyStyle}>{body}</WppTypography>
    <div slot="actions" style={modalButtonAlignment}>
      <WppButton variant="primary" size="s" onClick={handleDetailsModalClose}>Done</WppButton>
    </div>
  </WppFullScreenModal>
);

export default FullScreenModal;
