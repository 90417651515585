// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actionMenu {
  transform: rotate(90deg);
}

.heliosReportModal::part(actions-wrapper) {
  padding: 0;
}
.heliosReportModal::part(content) {
  transform: translate(-50%, -50%) !important;
}

.flightItem {
  width: 100%;
}
.flightItem::part(item) {
  width: 100%;
  height: 100%;
  padding: 8px;
}

.messageContainer::part(wrapper) {
  padding: 12px 12px 12px 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/StrategyActions/style.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AAEE;EACE,UAAA;AACJ;AACE;EACE,2CAAA;AACJ;;AAGA;EACE,WAAA;AAAF;AACE;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACJ;;AAIE;EACE,4BAAA;AADJ","sourcesContent":[".actionMenu{\n  transform: rotate(90deg);\n}\n.heliosReportModal{\n  &::part(actions-wrapper){\n    padding: 0\n  }\n  &::part(content){\n    transform: translate(-50%, -50%) !important\n  }\n}\n\n.flightItem{\n  width: 100%;\n  &::part(item){\n    width: 100%;\n    height: 100%;\n    padding: 8px;\n  }\n}\n\n.messageContainer{\n  &::part(wrapper){\n    padding: 12px 12px 12px 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
