import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { WppActionButton, WppIconGear, WppPaginationSelect, WppTypography } from 'buildingBlocks';
import { ELEMENT_ID } from 'cssTagConstants';
import { PaginationPageChangeEventDetail, WppPaginationSelectCustomEvent } from 'utils/types';
import NotificationItems from './NotificationItems';
import { NotificationMsgObject, NotificationListState } from '../types';
import { NOTIFICATION_STYLES } from '../style';
import { NOTIFICATIONS_ELEMENTS_PER_PAGE } from '../constants';

const { notificationsStyle, noNotifications, notificationFooter, notificationPagination, notificationFooterAction } = NOTIFICATION_STYLES;

type NotificationsProps = {
  hasNotifications: boolean
  notifications: Array<NotificationMsgObject>
  notificationsCount: number
  onClickNotification: (e: React.MouseEvent<HTMLDivElement>, selectedNotification: NotificationMsgObject) => void
  onDataRangeChange: (x: any) => void
  closeNotificationDropdown: () => void
  state: NotificationListState
};

const newPageRange = (currentPage, elementsPerPage, numberOfElements) => {
  const start = (currentPage - 1) * elementsPerPage;
  const end = Math.min(((currentPage - 1) * elementsPerPage) + elementsPerPage, numberOfElements);
  return { start, end };
};

const Notifications: React.FC<NotificationsProps> = (props: NotificationsProps): React.ReactElement => {
  const {
    hasNotifications,
    notifications,
    notificationsCount,
    onClickNotification,
    onDataRangeChange,
    closeNotificationDropdown,
    state,
  } = props;

  const pageCount = _.round(notificationsCount / NOTIFICATIONS_ELEMENTS_PER_PAGE);

  return (
    <>
      <div id={ELEMENT_ID.navBar.notificationInbox} style={notificationsStyle} className="notification-dropdown-tab">
        {hasNotifications
          ? (<NotificationItems notifications={notifications} selectedMsgId={state.id} onClickNotification={onClickNotification} />)
          : (
            <div style={noNotifications}>
              <WppTypography tag="p" type="s-strong">There are no notifications.</WppTypography>
            </div>
          )}
      </div>
      <div style={notificationFooter}>
        {
            hasNotifications && (
              <div style={notificationPagination}>
                <WppPaginationSelect
                  count={_.lt(pageCount, 1)
                    ? 1 : pageCount}
                  activePageNumber={state.startingPage}
                  onWppChange={(event: WppPaginationSelectCustomEvent<PaginationPageChangeEventDetail>) => onDataRangeChange({ limit: NOTIFICATIONS_ELEMENTS_PER_PAGE,
                    ...newPageRange(
                      event.detail.page,
                      NOTIFICATIONS_ELEMENTS_PER_PAGE,
                      notificationsCount,
                    ) })}
                />
              </div>
            )
          }
        <div style={notificationFooterAction}>
          <Link
            to="/usersettings"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeNotificationDropdown}
          >
            <WppActionButton id={ELEMENT_ID.navBar.notificationBellContainerSetting} variant="secondary">
              <WppIconGear slot="icon-start" />
              Settings
            </WppActionButton>
          </Link>
          <Link
            to="/notifications"
            target="_blank"
            rel="noopener noreferrer"
            onClick={closeNotificationDropdown}
          >
            <WppActionButton id={ELEMENT_ID.navBar.notificationBellAllNotification}>View All Notifications</WppActionButton>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Notifications;
