import React from 'react';
import { Button, Icon, Segment } from 'buildingBlocks';
import { ButtonClickHandler } from 'utils/types';
import { snackBar, deleteBtn } from './style';

type Props = {
  /** Message displayed to user next to delete icon. */
  displayMessage: string,
  /**
   * Function called when delete icon is clicked, which may manage a resulting
   * modal.
   */
  toggleModal: ButtonClickHandler,
};

export const DeletePanelComponent = ({ displayMessage, toggleModal }: Props) => (
  <Segment
    style={snackBar}
    raised
  >
    <Button style={deleteBtn} negative onClick={toggleModal}>
      <Icon name="trash" />
      Delete
    </Button>
    {displayMessage}
  </Segment>
);

export default DeletePanelComponent;
