import moment from 'moment';
import { FlightTypeLowerCase } from 'constantsBase';
import { ISO_DATE } from 'utils/dateTime';

export const DEFAULT_START_DATE = moment().utc().subtract(30, 'd').format(ISO_DATE);
export const DEFAULT_END_DATE = moment().utc().format(ISO_DATE);

export enum ActiveTab {
  actions,
  updates,
}

export const HIGHER_ORDER_FLIGHT_TYPES = new Set<string>([
  FlightTypeLowerCase.insertionOrder,
  FlightTypeLowerCase.campaign,
  FlightTypeLowerCase.order,
]);

const PREFIX = 'NOTIFICATIONS';

// redux login reducer actions
export const NOTIFICATIONS_FETCH_EXISTING = `${PREFIX}:FETCH_EXISTING`;
export const NOTIFICATIONS_FETCH_EXISTING_COMPLETED = `${PREFIX}:FETCH_EXISTING_COMPLETED`;
export const NOTIFICATIONS_FETCH_UPDATES = `${PREFIX}:FETCH_UPDATES`;
export const NOTIFICATIONS_READ_COMPLETED = `${PREFIX}:READ_COMPLETED`;
export const NOTIFICATIONS_READ_FAILED = `${PREFIX}:READ_FAILED`;
export const NOTIFICATIONS_NEW_COMPLETED = `${PREFIX}:NEW_COMPLETED`;

// local reducer actions
export const NOTIFICATIONS_ON_BELL_CLICK = `${PREFIX}:ON_BELL_CLICK`;
export const NOTIFICATIONS_PAGE_CHANGE = `${PREFIX}:PAGE_CHANGE`;
export const NOTIFICATIONS_SWITCH_CATEGORY = `${PREFIX}:SWITCH_CATEGORY`;
export const NOTIFICATIONS_CLICK_NOTIFICATION = `${PREFIX}:CLICK_NOTIFICATION`;

export const PAYLOAD_VERBS = {
  INITIAL: 'initial',
  UPDATED: 'updated',
  CREATED: 'created',
  DESTROYED: 'destroyed',
};

export const NOTIFICATIONS_ELEMENTS_PER_PAGE = 5;
export const NOTIFICATIONS_STARTING_PAGE = 1;
export const NOTIFICATIONS_INITIAL_PAGE_SKIP = 0;

export enum SelectedCategoryFilter {
  actions = 'actions',
  updates = 'updates',
}
