import React from 'react';
import _ from 'lodash';
import { Grid, GridProps, SemanticWIDTHS } from 'buildingBlocks';
import strechedDivStyle from './style';

function createColumns(children) {
  if (_.isArray(children)) {
    return children.map((elem) => (
      <Grid.Column key={elem.key}>
        {elem}
      </Grid.Column>
    ));
  }
  return (
    <Grid.Column key={0}>
      {children}
    </Grid.Column>
  );
}

const EvenlySpacedGrid = (props: { children: Array<React.ReactChild> } & GridProps) => (
  <Grid columns={props.children.length as SemanticWIDTHS} {...props}>
    <Grid.Row stretched style={strechedDivStyle}>
      {createColumns(props.children)}
    </Grid.Row>
  </Grid>
);

export default EvenlySpacedGrid;
