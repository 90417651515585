const formSectionHeaderStyle = {
  header: {
    fontWeight: 'normal',
  },
  leftJustified: {
    paddingRight: '0.5em',
  },
  icon: {
    color: '#546E7A',
    fontSize: '0.6em',
    marginRight: '-0.2em',
  },
  inline: {
    display: 'inline-flex',
    marginRight: '60px',
  },
};

export default formSectionHeaderStyle;
