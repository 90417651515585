import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { Form } from 'buildingBlocks';
import FormSectionHeader from 'components/FormSectionHeader/index';
import { GlobalState } from 'reducers';
import { User, Member, Advertiser } from 'utils/types';
import { useMemberFetcher } from 'utils/hooks/useMemberFetcher';
import AdvertiserDropdown from './AdvertiserDropdown';
import MemberDropdown from './MemberDropdown';

type MemberAndAdvertiserProps = {
  change: InjectedFormProps['change']
  selectedMember: Member
  selectedAdv: Advertiser
  onMemberSelect: Function
  onAdvSelect: Function
  clearRef: any
  dspIds?: Array<number>
};

const MemberAndAdvertiser = (props: MemberAndAdvertiserProps) => {
  const { change, selectedMember, selectedAdv, onMemberSelect, onAdvSelect, clearRef, dspIds } = props;
  const user = useSelector<GlobalState>((state) => state.login.user) as User;

  const [members, memberFetchError] = useMemberFetcher(user);
  // AB insights currently only supports Xandr, TTD, & DV360
  const selectableMembers = dspIds ? _.filter(members, (mem) => _.includes(dspIds, mem.dsp)) : members;

  const clear = () => {
    change('member', null);
    change('advertiser', null);
  };

  return (
    <>
      <FormSectionHeader group="flightInput" title="Member and Advertiser" />
      <Form.Group widths="equal">
        <MemberDropdown
          selectedMember={selectedMember}
          members={selectableMembers}
          memberFetchError={memberFetchError}
          onMemberSelect={onMemberSelect}
        />
        <AdvertiserDropdown
          selectedMember={selectedMember}
          selectedAdv={selectedAdv}
          onAdvSelect={onAdvSelect}
        />
        <button
          style={{ display: 'none' }}
          type="button"
          ref={clearRef}
          onClick={clear}
        />
      </Form.Group>
    </>
  );
};

export default MemberAndAdvertiser;
