import React, { CSSProperties } from 'react';
import { Menu, Container, Header } from 'buildingBlocks';
import { containerStyle, header as headerStyle, subnav } from './style';

export const BasicHeader = ({ header }: { header: string }) => (
  <Menu.Item title={header} fitted style={{ margin: 0, minWidth: 0 }}><Header style={headerStyle}>{header}</Header></Menu.Item>
);

const SubNav = ({ header, children, customHeader, subNavStyle }: SubNavProps) => (
  <Menu secondary style={subnav}>
    <Container style={subNavStyle ? { ...containerStyle, flexWrap: 'none' } : containerStyle}>
      {customHeader || <BasicHeader header={header} />}
      {children && <Menu.Item style={subNavStyle ? { ...subNavStyle, margin: 0 } : { margin: 0 }} fitted position="right">{children}</Menu.Item>}
    </Container>
  </Menu>
);

type SubNavProps = {
  /** Text of the header, used only if `customHeader` is not defined. */
  header: string,
  /** Contents of the header. */
  children: React.ReactNode,
  /** Custom header element. If set, `header` is ignored. */
  customHeader: React.ReactNode,
  subNavStyle?: CSSProperties
};

export default SubNav;
