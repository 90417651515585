import React from 'react';
import { WppGrid, WppTypography } from 'buildingBlocks';
import { wppHeaderContainer } from './style';

type WppSubNavProps = {
  /** Text of the header, used only if `customHeader` is not defined. */
  header: string
  /** Contents of the header. */
  children: React.ReactNode
  /** Custom header element. If set, `header` is ignored. */
  customHeader: React.ReactNode
};

export const WppBasicHeader: React.FC<{ header: string }> = ({ header }: { header: string }): React.ReactElement => (
  <WppTypography tag="h1" type="3xl-heading">
    {header}
  </WppTypography>
);

const WppSubNav: React.FC<WppSubNavProps> = ({ header, children, customHeader }: WppSubNavProps): React.ReactElement => (
  <WppGrid item all={24} style={wppHeaderContainer}>
    {customHeader || <WppBasicHeader header={header} />}
    {children}
  </WppGrid>
);

export default WppSubNav;
