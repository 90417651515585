import React from 'react';
import { WppTag } from 'buildingBlocks';
import { stratTypeStyle } from './styles';
import './style.scss';

type StratTypeBadgeProps = {
  stratType: string
};

const StratTypeBadge = ({ stratType }: StratTypeBadgeProps) => (
  <WppTag
    label={stratType}
    className="tag"
    variant="neutral"
    style={stratTypeStyle}
  />
);

export default StratTypeBadge;
