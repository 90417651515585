import moment from 'moment';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import metrics from 'containers/StrategyAnalytics/constants/metricsConstants';

export const ROUTE = '/abinsights';
export const DEFAULT_FAIL_MESSAGE = (
  'There was a problem running AB Analytics. Please reach out to the Copilot team for assistance.'
);

/*
Page Config
*/
export const PAGE_TITLE = 'A/B Insights Visualizations';
export const PAGE_NAME = 'A/B Insights';
export const FORM_NAME = 'ABInsights';

/*
Redux Action Types
*/
export const FORM_SUBMITTED = 'AB_INSIGHTS_NEW:FORM_SUBMITTED';
export const FETCH_DATA_COMPLETED = 'AB_INSIGHTS_NEW:FETCH_DATA_COMPLETED';
export const FETCH_DATA_FAILED = 'AB_INSIGHTS_NEW:FETCH_DATA_FAILED';
export const FETCH_FLIGHTS = 'AB_INSIGHTS_NEW:FETCH_FLIGHTS';
export const FETCH_FLIGHTS_SUCCEEDED = 'AB_INSIGHTS_NEW:FETCH_FLIGHTS_SUCCEEDED';
export const FETCH_FLIGHTS_FAILED = 'AB_INSIGHTS_NEW:FETCH_FLIGHTS_FAILED';
export const INIT_FROM_QUERY_STRING = 'AB_INSIGHTS_NEW:INIT_FROM_QUERY_STRING';
export const INIT_FROM_QUERY_STRING_FAILED = 'FLIGHT_COMPARE_NEW:INIT_FROM_QUERY_STRING_FAILED';
export const RESET_LOADER = 'AB_INSIGHTS_NEW:RESET_LOADER';
export const END_DATE_ERROR = 'AB_INSIGHTS_NEW:END_DATE_ERROR';
export const UPDATE_CURRENT_VISUALIZATION = 'UPDATE_CURRENT_VISUALIZATION';

/*
Form values
*/
export const createStartDate = () => moment.utc().subtract(60, 'days').toDate();
export const createEndDate = () => moment.utc().toDate();
export const DEFAULT_FLIGHT_TYPE = FLIGHT_EXTERNAL_TYPE.apnLineItem.id;
export const FLIGHT_DROPDOWN_LIMIT = 10;

// Making this a function because we only want dates to be initialized when default values are actually set
export const createDefaultFormValues = () => ({
  startDate: createStartDate(),
  endDate: createEndDate(),
  flightType: DEFAULT_FLIGHT_TYPE,
  title: '',
  KPIs: [],
});

export const metricsToOmitForBeta = [
  'expTimeImps',
  'expTimeMeasured',
  'viewMeasuredRate',
  'goalConversionRate',
  'conversionRevenueRate',
  'impressionValueRate',
  'averageBid',
  'netCpm',
  'netCpm',
  'netVcpm',
  'netCpc',
  'netCpa',
  'goalCpa',
  'netGoalCpa',
  'netCpcv',
  'cpcl',
  'netCpcl',
  'viewDurationImps',
  'goalConversions',
  'videoStarts',
  'audioStarts',
  'audioCompletes',
  'margin',
  metrics.aggregator.videoViewableCompletes.value,
];

export const metricsToOmit = [
  'impressions',
  'revenue',
  'currentPacing',
  'currentPacingPaused',
  'finalPacing',
  'conversionRevenue',
  'cpvYoutube',
  'impressionValue',
  'vcrYoutube',
  metrics.ratePercentage.eventBudgetDelivered.value,
];
