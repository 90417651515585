import _ from 'lodash';
import React from 'react';
import { WppActionButton } from 'buildingBlocks';
import { HandleSubmitType, userPrompts } from '../constants';
import CHATBOT_STYLES from '../styles';

const { promptButton } = CHATBOT_STYLES;

type ChatbotPromptsProps = {
  handleSubmit: HandleSubmitType
};

const ChatbotPrompts = (props: ChatbotPromptsProps) => {
  const { handleSubmit } = props;

  return (
    <>
      {_.map(userPrompts, (prompt: string) => (
        <WppActionButton
          className="chatbot-prompt"
          variant="secondary"
          key={prompt}
          style={promptButton}
          onClick={(e) => handleSubmit(e, prompt)}
        >
          {prompt}
        </WppActionButton>
      ))}
    </>
  );
};

export default ChatbotPrompts;
