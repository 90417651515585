import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { REDS },
} = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;

export default {
  errorMessage: {
    display: 'flex',
    background: REDS.R50_VISAGE,
    color: REDS.R700_VALENTINE,
    border: `1px solid ${REDS.R700_VALENTINE}`,
    padding: '8px 16px',
    borderRadius: SPACING[4],
    gap: SPACING[8],
    width: '100%',
  },
  errorIcon: {
    color: REDS.R700_VALENTINE,
  },
};
