import _ from 'lodash';
import React, { Component } from 'react';
import HighCharts from 'react-highcharts';
import { Message, Icon } from 'buildingBlocks';
import { chartStyle, errorContainerStyle, errorMessageStyle } from './styles';

type Props = {
  defaultChartOptions: {},
  noData?: boolean,
  series?: Array<{}>,
};

class HighchartsWrapper extends Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    defaultChartOptions: {},
  };

  componentDidMount() {
    // @ts-ignore - this.ref
    if (this.ref) {
      this.update(this.props);
      this.updateSeries(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // @ts-ignore - this.ref
    if (this.ref) {
      if (!_.isEqual(nextProps, this.props)) {
        if (!_.isEqual(nextProps.series, this.props.series)) {
          this.updateSeries(nextProps);
        }
        this.update(nextProps);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    // We only render the chart once, unless the noData prop has changed. Thereafter,
    // we make updates to the chart display via the HighCharts API.
    if (this.props.noData !== nextProps.noData) {
      return true;
    }
    return false;
  }

  update(props) {
    // update tooltip when props change in case custom metrics are included
    const config = { ..._.omit(props, 'series'), tooltip: props.defaultChartOptions.tooltip };
    // @ts-ignore - this.ref
    this.ref.chart.update(config);
  }

  updateSeries(props) {
    const series = _.get(props, 'series');
    // @ts-ignore - this.ref
    while (this.ref.chart.series.length > 0) {
      // @ts-ignore - this.ref
      this.ref.chart.series[0].remove(false);
    }
    _.each(series, (ser) => {
      // @ts-ignore - this.ref
      this.ref.chart.addSeries(ser, false);
    });
  }

  render() {
    if (this.props.noData) {
      return (
        <div style={errorContainerStyle}>
          <div style={errorMessageStyle}>
            <Message icon>
              <Icon size="small" name="attention" />
              <Message.Content>
                <Message.Header>There is currently no data available</Message.Header>
              </Message.Content>
            </Message>
          </div>
        </div>
      );
    }

    return (
      <div style={{ textAlign: 'left', marginBottom: '15px' }} id="hc-container">
        <HighCharts
          config={this.props.defaultChartOptions}
          // @ts-ignore - this.ref
          ref={(c) => { this.ref = c; }}
          // @ts-ignore highcharts (although I don't think these styles are getting applied)
          style={chartStyle}
          isPureConfig
        />
      </div>
    );
  }
}

export default HighchartsWrapper;
