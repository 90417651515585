import _ from 'lodash';
import React, { Component, FunctionComponent } from 'react';
import { InjectedFormProps } from 'redux-form';
import { Grid, Container, Form } from 'buildingBlocks';
import DoubleFlightSelect from 'components/DoubleFlightSelect';
import { FetchFlightsAction } from 'components/DoubleFlightSelect/DoubleFlightSelect';
import MemberAndAdvertiser from 'components/RFMemberAndAdvertiser/MemberAndAdvertiserState';
import FormButtons from 'components/FormButtons';
import strategyWizard from 'containers/StrategyWizard/styles';
import { formatApnObj } from 'utils/formattingUtils';
import { Member, Advertiser, Flight } from 'utils/types';

const { column, base } = strategyWizard;

type Props = {
  change: InjectedFormProps['change'],
  fetchFlights: FetchFlightsAction,
  member: Member,
  advertiser: Advertiser,
  flightFetchError: string | null,
  flights: Array<Flight>,
  flightLeft: Flight | null,
  flightRight: Flight | null,
  handleSubmit: Function,
  handleFormSubmit: Function,
  canSubmit: boolean,
  resetFields: Function,
  pristine: boolean,
  flightType: number,
  formName: string,
  showFlightTypeSelector: boolean,
  flightTypeOptions: Array<{ id: number, displayName: string, wizardDisplayName: string }>
  lowerForm?: FunctionComponent<{ change: InjectedFormProps['change'], flightType: number, memberDsp?: number }>,
  dspIds?: Array<number>,
};

class MemberAdvertiserAndFlights extends Component<Props> {
  clearFlightFields = () => {
    const { change } = this.props;
    change('flightLeft', '');
    change('flightRight', '');
  };

  onAdvSelect = (advertiser: Advertiser) => {
    this.clearFlightFields();
    const { member, flightType, fetchFlights, change } = this.props;
    change('advertiser', advertiser);
    fetchFlights(member.id, advertiser.id, flightType);
    change('flightType', _.get(_.first(this.props.flightTypeOptions), 'id'));
  };

  onMemberSelect = (mem: Member) => {
    const { change } = this.props;
    change('member', mem);
    change('advertiser', null);
  };

  innerClearButton: HTMLButtonElement;

  consumeInnerClearButton = (element: HTMLButtonElement) => {
    this.innerClearButton = element;
  };

  resetAllFields = () => {
    this.innerClearButton.click();
    this.props.resetFields();
  };

  render() {
    const {
      member,
      advertiser,
      flightFetchError,
      canSubmit,
      change,
      flights,
      flightLeft,
      flightRight,
      pristine,
      flightType,
      handleSubmit,
      handleFormSubmit,
      fetchFlights,
      formName,
      showFlightTypeSelector,
      flightTypeOptions,
      lowerForm: LowerForm,
      dspIds,
    } = this.props;
    return (
      <Container fluid>
        <div style={base}>
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid stackable style={{ margin: '0px 50px' }}>
              <Grid.Row>
                <Grid.Column style={column} width={12}>
                  <MemberAndAdvertiser
                    change={change}
                    clearRef={this.consumeInnerClearButton}
                    selectedMember={member}
                    selectedAdv={advertiser}
                    onAdvSelect={this.onAdvSelect}
                    onMemberSelect={this.onMemberSelect}
                    dspIds={dspIds}
                  />
                </Grid.Column>
              </Grid.Row>
              {this.props.advertiser && (
                <DoubleFlightSelect
                  flightType={flightType}
                  member={member}
                  advertiser={advertiser}
                  flights={flights}
                  flightLeft={flightLeft}
                  flightRight={flightRight}
                  fetchFlights={fetchFlights}
                  clearFlightFields={this.clearFlightFields}
                  formatApnObj={formatApnObj}
                  flightFetchError={flightFetchError}
                  showFlightTypeSelector={showFlightTypeSelector}
                  flightTypeOptions={flightTypeOptions}
                />
              )}
              {(this.props.advertiser && LowerForm) && (
                <LowerForm change={change} flightType={flightType} memberDsp={_.get(member, 'dsp', null)} />
              )}
              <Grid.Row>
                <FormButtons
                  cancelName={`${formName}-cancel`}
                  submitName={`${formName}-submit`}
                  canSubmit={canSubmit}
                  pristine={pristine}
                  resetFields={this.resetAllFields}
                />
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </Container>
    );
  }
}

export default MemberAdvertiserAndFlights;
