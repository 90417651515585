/**
*
* PageTemplate
*
*/

import React, { CSSProperties } from 'react';
import DocumentTitle from 'react-document-title';
import { Container } from 'buildingBlocks';
import { containerStyle, fixedContainerStyle } from './style';
import SubNav from '../SubNav';

export type PageTemplateProps = {
  /** The name of the current page. */
  name: string
  /** The title of the current page. */
  title?: string,
  /** The contents of the page. */
  children: React.ReactNode,
  /** React element representing sub-navigation. */
  subNavElements?: React.ReactNode,
  /** React element representing a custom header. */
  customHeader?: React.ReactNode,
  /** Whether page should expand to fill its container. */
  fluid?: boolean,
  subNavStyle? : CSSProperties
};

const PageTemplate = (props: PageTemplateProps) => (
  <div id={props.title || props.name}>
    <DocumentTitle title={props.title || props.name} />
    <SubNav header={props.name} customHeader={props.customHeader} subNavStyle={props.subNavStyle}>{props.subNavElements}</SubNav>
    <Container
      fluid={props.fluid}
      style={props.fluid ? containerStyle : fixedContainerStyle}
    >
      {props.children}
    </Container>
  </div>
);

export default PageTemplate;
