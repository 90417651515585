import _ from 'lodash';
import React, { useState, SyntheticEvent } from 'react';
import { createPortal } from 'react-dom';
import { WppModal, WppTypography, WppGrid, WppButton, WppInput } from 'buildingBlocks';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import CalcTrigger from './components/CalcTrigger';
import style from './style';
import { calculateConvRevGoalValue } from './utils';

type InputBoxProps = {
  label: string
  placeholder: string
  onChange: (value: string) => void
};

const InputBox = ({ label, placeholder, onChange }: InputBoxProps) => (
  <WppGrid className="calc-inputbox no-spinners" style={style.inputForm}>
    <WppTypography tag="p" type="s-strong" style={style.labelText}>{label}</WppTypography>
    <WppInput
      style={style.roasCalcInput}
      type="decimal"
      size="s"
      placeholder={placeholder}
      onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => onChange(event.detail.value)}
    />
  </WppGrid>
);

type Props = {
  onChange: (value: number) => void
};

const ConvRevenueCalculator = ({ onChange }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [mediaCostValue, setMediaCostValue] = useState<string>('');
  const [revPerConversionValue, setRevPerConversionValue] = useState<string>('');
  const [conversionVolumeValue, setConversionVolumeValue] = useState<string>('');

  const closeModal = () => {
    setMediaCostValue('');
    setRevPerConversionValue('');
    setConversionVolumeValue('');
    setOpen(false);
  };

  const setGoalValue = (v: number) => {
    onChange(v);
    closeModal();
  };

  const isNotValid = (isValidResult: boolean) => _.isEqual(mediaCostValue, '')
    || _.isEqual(conversionVolumeValue, '')
    || _.isEqual(revPerConversionValue, '')
    || !isValidResult;

  const estimatedConvRevGoalValue = calculateConvRevGoalValue(revPerConversionValue, conversionVolumeValue, mediaCostValue);
  const isValidCalculation = _.isFinite(estimatedConvRevGoalValue) && !_.isNaN(estimatedConvRevGoalValue);

  return (
    <>
      <CalcTrigger onClick={() => setOpen((prevOpen) => !prevOpen)} />
      {createPortal(
        <WppModal open={open} size="s">
          <WppTypography slot="header" tag="p" type="xl-heading">ROAS Calculator</WppTypography>
          <div slot="body">
            <div>
              <WppTypography slot="header" tag="p" type="s-strong">What is ROAS?</WppTypography>
              <WppTypography slot="header" tag="p" type="s-body">
                ROAS is the sum of revenue tracked in a conversion pixel, divided by the sum of media cost for a given flight.
              </WppTypography>
              <WppTypography slot="header" tag="p" type="s-body" style={style.roasCalcInput}>
                It can also be referred to as Return on Investment (ROI).
              </WppTypography>
            </div>
            <WppGrid>
              <InputBox
                label="My estimated media cost is"
                placeholder="e.g. 10,000"
                onChange={setMediaCostValue}
              />
              <InputBox
                label="My estimated revenue per conversion is"
                placeholder="e.g. 50"
                onChange={setRevPerConversionValue}
              />
              <InputBox
                label="My estimated Conversion Volume is"
                placeholder="e.g. 500"
                onChange={setConversionVolumeValue}
              />
            </WppGrid>
            <div style={style.roasEstimateVal}>
              <WppTypography slot="header" tag="p" type="s-body">Estimated ROAS Goal Value:</WppTypography>
              <WppTypography slot="header" tag="span" type="m-strong">
                {isValidCalculation ? estimatedConvRevGoalValue : 0}
              </WppTypography>
            </div>
          </div>
          <div slot="actions" style={style.roasCalcBtn}>
            <WppButton variant="secondary" size="s" onClick={closeModal}>Cancel</WppButton>
            <WppButton
              variant="primary"
              size="s"
              onClick={(e: SyntheticEvent) => {
                setGoalValue(estimatedConvRevGoalValue);
                e.stopPropagation();
              }}
              disabled={isNotValid(isValidCalculation)}
            >
              Use This Value
            </WppButton>
          </div>
        </WppModal>,
        document.body,
      )}
    </>
  );
};

export default ConvRevenueCalculator;
