import React, { Component, CSSProperties } from 'react';
import _ from 'lodash';
import { Segment } from 'buildingBlocks';

import PageSizeSelector, { PageSize } from './PageSizeSelector';
import PageControl from './PageControl';
import { paginationContainerStyle } from './style';

type Props = {
  /** Total number of elements in the collection of records. */
  numberOfElements: number,
  /** Function called when page is changed. */
  onDataRangeChange: Function,
  /**
   * How many elements to display per page.
   * Must be one of `elementsPerPageOptions`.
   */
  startingElementsPerPage: number,
  /** Which page to start on. */
  startingPage?: number,
  /**
   * Array of options for page size to display to user for selection, e.g. [10, 25, 50].
   */
  elementsPerPageOptions?: Array<number>,
  /**
   * Size option passed to the underlying Semantic <Segment> element.
   * One of:  [ 'mini', 'tiny', 'small', 'large', 'big', 'huge', 'massive']
   */
  size: PageSize,
  /** When true, renders a version of the component with less whitespace. */
  compact: boolean,
  /** change the color of the page size selector (default: #fff (white)) */
  backgroundColor?: CSSProperties['backgroundColor'],
  /** extra styles for the Segment which wraps the pagination */
  style?: CSSProperties,
};

type State = {
  elementsPerPage: number,
  currentPage: number,
  numberOfPages: number,
  numberOfElements: number,
};

/**
 * Control to paginate through a table of records.
 */
export default class PaginationControl extends Component<Props, State> {
  static newPageRange(currentPage, elementsPerPage, numberOfElements) {
    const start = (currentPage - 1) * elementsPerPage;
    const end = Math.min(((currentPage - 1) * elementsPerPage) + elementsPerPage, numberOfElements);
    return { start, end };
  }

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    size: 'tiny',
    compact: false,
    startingPage: undefined,
  };

  constructor(props) {
    super(props);
    const { startingElementsPerPage, numberOfElements, startingPage } = props;
    this.state = {
      elementsPerPage: startingElementsPerPage,
      currentPage: startingPage || 1,
      numberOfPages: _.ceil(numberOfElements / startingElementsPerPage),
      numberOfElements,
    };
  }

  // Workaround to overcome the totalNumberofElements issue. If we do not have this method, the numberofElements will be
  // the initial number(passed from Reducers.js in each component) and it wnt update the state even after the
  // props is updated.
  UNSAFE_componentWillReceiveProps(newProps) {
    const { numberOfElements, startingPage, startingElementsPerPage } = newProps;

    const page = startingPage || this.state.currentPage;
    if (this.state.numberOfElements !== numberOfElements || this.state.currentPage !== page) {
      this.setState({
        elementsPerPage: startingElementsPerPage,
        currentPage: page,
        numberOfPages: _.ceil(numberOfElements / startingElementsPerPage),
        numberOfElements,
      });
    }
  }

  onPageSizeChange(newPageSize) {
    const { numberOfElements } = this.props;
    const newDataRange = PaginationControl.newPageRange(1, newPageSize, numberOfElements);
    this.props.onDataRangeChange({ limit: newPageSize, ...newDataRange });
    this.setState({
      elementsPerPage: newPageSize,
      numberOfPages: _.ceil(numberOfElements / newPageSize),
      currentPage: 1,
    });
  }

  onPageChange(newPage) {
    const newDataRange = PaginationControl.newPageRange(
      newPage,
      this.state.elementsPerPage,
      this.props.numberOfElements,
    );
    this.props.onDataRangeChange({ limit: this.state.elementsPerPage, ...newDataRange });
    this.setState({
      currentPage: newPage,
    });
  }

  render() {
    return (
      <Segment basic style={{ ...paginationContainerStyle, ...this.props.style }}>
        <PageControl
          size={this.props.size}
          currentPage={this.state.currentPage}
          numberOfPages={this.state.numberOfPages}
          onPageChange={(page) => this.onPageChange(page)}
          compact={this.props.compact}
          backgroundColor={this.props.backgroundColor}
        />
        {_.size(this.props.elementsPerPageOptions) > 1
          && (
            <PageSizeSelector
              size={this.props.size}
              onPageSizeChange={(pageSize) => this.onPageSizeChange(pageSize)}
              elementsPerPageOptions={this.props.elementsPerPageOptions}
              activeItem={this.state.elementsPerPage}
              compact={this.props.compact}
              backgroundColor={this.props.backgroundColor}
            />
          )}
      </Segment>
    );
  }
}
