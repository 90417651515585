// @ts-nocheck redux-form & ObjectDropdown props issues
import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { FieldProps, Form, ObjectDropdown, ObjectDropdownProps } from 'buildingBlocks';
import { marginBottom10px, marginTop20px } from 'components/MemberAdvertiserAndFlights/constants';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import { DSP } from 'constantsBase';
import { Member } from 'utils/types';
import { searchByNameOrExtId } from 'utils/semanticUISearch';

type MemberDropdownProps = {
  selectedMember: Member
  members: Array<Member>
  memberFetchError: string
  onMemberSelect: Function
};

const MemberDropdown = (props: MemberDropdownProps) => {
  const { selectedMember, members, memberFetchError, onMemberSelect } = props;

  return (
    <Form.Field style={marginTop20px}>
      <label htmlFor="member" style={marginBottom10px}>Select Member</label>
      { /* @ts-ignore ObjectDropdown refactor */}
      <Field<FieldProps<ObjectDropdownProps<Member>>>
        id="member"
        name="member"
        component={ObjectDropdown}
        keyFn={(mem) => `${DSP.getById(mem.dsp as number).code} - ${mem.displayName}`}
        placeholder="Search Members..."
        fluid
        search={{
          searchType: 'local',
          onSearchChange: searchByNameOrExtId(),
        }}
        options={getOptionsWithDspIcon(members)}
        onChange={onMemberSelect}
        text={_.get(selectedMember, 'displayName', '')}
        disabled={memberFetchError}
        selection
        required
      />
      <span style={{ color: '#DB2828' }}>{memberFetchError ?? ''}</span>
    </Form.Field>
  );
};

export default MemberDropdown;
