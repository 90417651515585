import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const styles: { [key: string]: CSSProperties } = {
  popUpMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '350px',
    gap: SPACING[8],
  },
  popUpMessageText: {
    textDecoration: 'underline',
    display: 'block',
    wordBreak: 'break-all',
  },
};

export default styles;
