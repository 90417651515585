import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS },
} = COPILOT_COLORS;

export const containerStyle: CSSProperties = {
  padding: '1.5rem',
};

export const fixedContainerStyle: CSSProperties = {
  padding: '1.5rem 0',
};

export const wppPageTemplateContainer: CSSProperties = {
  marginTop: SPACING[8],
};

export const wppBodyContainer: CSSProperties = {
  borderRadius: SPACING[8],
  boxShadow: '0px 1px 4px 0px rgba(52, 58, 63, 0.12)',
  padding: SPACING[24],
  backgroundColor: NEUTRALS.N0_WHITE,
};

export const wppActionContainer: CSSProperties = {
  marginTop: SPACING[12],
};
