import _ from 'lodash';
import React from 'react';
import { InjectedFormProps, Field } from 'redux-form';
import { Grid, Form, Input } from 'buildingBlocks';
import FormSectionHeader from 'components/FormSectionHeader';
import { marginBottom10px, marginTop20px } from 'components/MemberAdvertiserAndFlights/constants';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { validatedFieldWrapper } from 'utils/wrapperComponents';
import { StartDate, EndDate } from './DateSelect';
import KPISelect from './KPISelect';

type Props = {
  change: InjectedFormProps['change'],
  flightType: number,
  memberDsp?: number
};

const ValidatedInput = validatedFieldWrapper(Input);

const LowerForm = ({ change, flightType, memberDsp }: Props) => {
  const flightTypeName = _.get(FLIGHT_EXTERNAL_TYPE.getById(flightType), 'displayName', '');
  return (
    <>
      <Grid.Row>
        <Grid.Column width={12} style={{ padding: '0 1.5rem' }}>
          <Form.Group>
            <Form.Field width={6}>
              <label htmlFor="flightLeftAlias">{flightTypeName} A Alias</label>
              <Field
                name="flightLeftAlias"
                component={ValidatedInput}
                placeholder={`Copilot ${flightTypeName}`}
              />
            </Form.Field>
            <Form.Field width={6}>
              <label htmlFor="flightRightAlias">{flightTypeName} B Alias</label>
              <Field
                name="flightRightAlias"
                component={ValidatedInput}
                placeholder={`Non-Copilot ${flightTypeName}`}
              />
            </Form.Field>
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ marginTop: '42px' }}>
        <Grid.Column style={{ padding: '0 1.5rem' }}>
          <FormSectionHeader group="flightInput" title="Report Title & Date Range" />
          <Form.Group style={marginTop20px}>
            <Form.Field width={6}>
              <label htmlFor="title" style={marginBottom10px}>Title</label>
              <Field
                name="title"
                component={ValidatedInput}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="startDate" style={marginBottom10px}>Start Date</label>
              <StartDate fieldName="startDate" change={change} />
            </Form.Field>
            <Form.Field>
              <label htmlFor="endDate" style={marginBottom10px}>End Date</label>
              <EndDate fieldName="endDate" change={change} />
            </Form.Field>
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ marginTop: '42px' }}>
        <Grid.Column style={{ padding: '0 1.5rem' }}>
          <FormSectionHeader group="flightInput" title="KPIs" />
          <Form.Group style={marginTop20px}>
            <Form.Field width={6}>
              <label htmlFor="KPIs" style={marginBottom10px}>Impressions and Revenue will be included in the report by default</label>
              <KPISelect fieldName="KPIs" selection dsp={memberDsp} />
            </Form.Field>
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default LowerForm;
