// @ts-nocheck redux-form & ObjectDropdown props issues
import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { Form, FieldProps, ObjectDropdownProps, WppGrid, WppTypography } from 'buildingBlocks';
import { OnSearchChange } from 'buildingBlocks/ObjectDropdown';
import { Advertiser, Member, EventHandler, PrettyOption } from 'utils/types';
import ObjectDropdown from 'buildingBlocks/ObjectDropdown/ObjectDropdown';
import userGroupPageStyles from 'containers/UserGroups/style';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import { DSP } from 'constantsBase';

const { setUserGroupsStyle } = userGroupPageStyles;

type AdvertiserProps = {
  advertisers: Array<Advertiser>
  selectedAdvertiser: Advertiser
  loadingAdvertisers: boolean
  onSearchChange: OnSearchChange
  onAdvSelect: Function
  onAdvClick: Function
  selectedMember: {
    displayName: string
  }
  advertiserFetchError?: string
};

type MemberProps = {
  prettyMembers: Array<PrettyOption>
  selectedMember: {
    displayName: string
  }
  onSeatSelect: EventHandler<any>
  members: Array<Member>
  memberFetchError?: string
};

export type MemberAndAdvertiserProps = AdvertiserProps & MemberProps;

const MemberFormField = (props: MemberProps) => (
  <Form.Field>
    <WppTypography type="s-strong" style={setUserGroupsStyle.formTitleStyle}>Member</WppTypography>
    { /** @ts-ignore */}
    <Field<FieldProps<ObjectDropdownProps<PrettyOption>>>
      id="member"
      name="member"
      component={ObjectDropdown}
      options={props.prettyMembers}
      keyFn={(mem) => `${DSP.getById(mem.dsp as number).code} - ${mem.displayName}`}
      text={props.selectedMember ? props.selectedMember.displayName : 'Search Members...'}
      onChange={props.onSeatSelect}
      disabled={!!props.memberFetchError || (props.members && props.members.length === 1)}
      placeholder="Search Members..."
      fluid
      search={{
        searchType: 'local',
        onSearchChange: searchByNameOrExtId(),
      }}
      selection
      required
    />
    <span style={{ color: '#DB2828' }}>{props.memberFetchError}</span>
  </Form.Field>
);

const AdvertiserFormField = (props: AdvertiserProps) => (
  <Form.Field>
    <WppTypography type="s-strong" style={setUserGroupsStyle.formTitleStyle}>Advertiser</WppTypography>
    { /** @ts-ignore */}
    <Field<FieldProps<ObjectDropdownProps<Advertiser>>>
      id="advertiser"
      name="advertiser"
      component={ObjectDropdown}
      onClick={props.onAdvClick}
      onChange={props.onAdvSelect}
      options={props.advertisers}
      keyFn={(adv) => `[ ${adv.externalId} ] - ${adv.name}`}
      text={props.selectedAdvertiser
        ? `[ ${props.selectedAdvertiser.externalId} ] - ${props.selectedAdvertiser.name}`
        : 'Search Advertisers...'}
      disabled={!_.isEmpty(props.advertiserFetchError) || _.isEmpty(props.selectedMember)}
      loading={props.loadingAdvertisers}
      placeholder="Search Advertisers..."
      fluid
      selection
      required
      search={{
        searchType: 'api',
        onSearchChange: props.onSearchChange,
        debounce: { timer: 500 },
      }}
    />
    <span style={{ color: '#DB2828' }}>{props.advertiserFetchError}</span>
  </Form.Field>
);

export const MemberAndAdvertiser = ({
  prettyMembers,
  selectedMember,
  onSeatSelect,
  memberFetchError,
  members,
  advertisers,
  advertiserFetchError,
  loadingAdvertisers,
  onAdvSelect,
  onAdvClick,
  onSearchChange,
  selectedAdvertiser,
}: MemberAndAdvertiserProps) => (
  <>
    <div style={setUserGroupsStyle.memberAdvertiserContainer}>
      <WppGrid item all={12}>
        <MemberFormField
          prettyMembers={prettyMembers}
          selectedMember={selectedMember}
          onSeatSelect={onSeatSelect}
          memberFetchError={memberFetchError}
          members={members}
        />
      </WppGrid>
      <WppGrid item all={12}>
        <AdvertiserFormField
          selectedMember={selectedMember}
          advertisers={advertisers}
          advertiserFetchError={advertiserFetchError}
          loadingAdvertisers={loadingAdvertisers}
          onAdvSelect={onAdvSelect}
          onAdvClick={onAdvClick}
          onSearchChange={onSearchChange}
          selectedAdvertiser={selectedAdvertiser}
        />
      </WppGrid>
    </div>
  </>
);

export default MemberAndAdvertiser;
