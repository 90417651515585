import _ from 'lodash';
import moment, { Moment } from 'moment';
import qs from 'qs';
import { DSP } from 'constantsBase';
import { ISO_DATE, dateTimeFormatter } from 'utils/dateTime';
import { Metric, metrics } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { VizId } from 'charts/ABReportViz/constants';
import { metricsToOmit, metricsToOmitForBeta } from './constants';
import { FormValues } from './types';

export const ensureDatesAreValid = (startDate: string, endDate: string): Array<Moment> => {
  let startDateMoment = moment.utc(startDate, ISO_DATE);
  let endDateMoment = moment.utc(endDate, ISO_DATE);
  const earliestAllowedDate = moment.utc().subtract(60, 'd');

  if (startDateMoment.isBefore(earliestAllowedDate)) {
    startDateMoment = earliestAllowedDate;
  }

  if (endDateMoment.isBefore(startDateMoment)) {
    endDateMoment = startDateMoment;
  }

  return [startDateMoment, endDateMoment];
};

export const formValuesToQueryString = (formValues: FormValues): string => {
  const formValuesWithCurrentTabInitialized = {
    ...formValues,
    currentTab: VizId.overview,
  };
  const mapped = _.mapValues(formValuesWithCurrentTabInitialized, (val, key) => {
    const externalId = _.get(val, 'externalId');
    if (externalId) {
      return externalId;
    }
    switch (key) {
      case 'currentTab':
      case 'flightType':
      case 'flightLeftAlias':
      case 'flightRightAlias':
      case 'title':
        return val;
      case 'startDate':
      case 'endDate':
        return dateTimeFormatter.isoDate(val as Moment);
      case 'KPIs':
        return _.join(_.map(val as Array<Metric>, 'value'), ',');
      default:
        return null;
    }
  });
  return qs.stringify(_.omitBy(mapped, _.isNil));
};

const [ratePercentageMetrics, aggregatorMetrics] = _.values(metrics);

const metricsToOmitByDsp = {
  [DSP.APN.id]: [],
  [DSP.TTD.id]: [],
  [DSP.DBM.id]: [metrics.aggregator.conversionRevenue.value],
};

export const getKpiOptions = (dsp: number) => (
  _.omit(
    { ...ratePercentageMetrics, ...aggregatorMetrics },
    [
      ...metricsToOmitForBeta,
      ...metricsToOmit,
      ..._.get(metricsToOmitByDsp, dsp, []),
    ],
  )
);
