import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { Radio } from 'buildingBlocks';

type Props = {
  selectedValue: number,
  input?: {
    onChange: Function,
  },
  afterChange: Function,
  disabled?: boolean,
  flightTypeOptions: Array<unknown>,
};

const FlightType = ({
  selectedValue, flightTypeOptions, input: { onChange }, afterChange, disabled,
}: Props) => _.map(flightTypeOptions, ({ id, displayName }: { id: number, displayName: string }) => (
  <Radio
    key={id}
    style={{ marginRight: '40px' }}
    checked={selectedValue === id}
    label={displayName}
    value={id}
    onChange={(_e, data) => {
      onChange(data.value);
      afterChange(data.value);
    }}
    disabled={disabled}
  />
));

const FlightTypeField = ({ selectedValue, flightTypeOptions, afterChange, disabled }: Omit<Props, 'input'>) => (
  <Field
    id="flightType"
    name="flightType"
    component={FlightType}
    selectedValue={selectedValue}
    afterChange={afterChange}
    disabled={disabled}
    flightTypeOptions={flightTypeOptions}
  />
);

export default FlightTypeField;
