import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import ObjectMultiDropdown from 'buildingBlocks/ObjectMultiDropdown';
import { ObjectMultiDropdownProps, FieldProps } from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponents';
import { getKpiOptions } from '../utils';

type Props = {
  fieldName: string
  selection?: boolean
  dsp?: number
};

const ValidatedDropdown = validatedFieldWrapper(ObjectMultiDropdown);

const KPISelect = ({
  fieldName,
  selection,
  dsp,
}: Props) => {
  const options = getKpiOptions(dsp);
  return (
    <Field<FieldProps<ObjectMultiDropdownProps<any>>>
      id={fieldName}
      name={fieldName}
      component={ValidatedDropdown}
      options={_.values(options)}
      keyFn={(item) => item.text}
      selection={selection}
      search={{ searchType: 'local' }}
      scrolling
    />
  );
};

export default KPISelect;
