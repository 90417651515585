import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { RootState } from 'reducers';
import { LOADING_STATES } from 'constantsBase';
import { withQueryString } from 'utils/functionHelpers';
import { GoalType } from 'utils/types';
import { Permission } from 'utils/featureFlags';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import PageLoading from 'components/PageLoading';
import ErrorComponent from 'components/ErrorComponent';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import ABReportViz from 'charts/ABReportViz';
import TextLinkBack from 'components/TextLinkBack';
import { VizId } from 'charts/ABReportViz/constants';
import withRouter, { RouterProps } from 'utils/withRouter';
import { initFromQueryString, updateCurrentTab } from '../actions';
import { ensureDatesAreValid } from '../utils';
import { ROUTE, PAGE_TITLE, PAGE_NAME } from '../constants';
import { ErrorDetail, QueryStringValues } from '../types';
import { ABInsightsDataDSPSubtype } from '../interfaces';

type Props = RouterProps & {
  qsParams: QueryStringValues
  initFromQueryString: typeof initFromQueryString
  reportTitle: string
  startDate: Moment
  endDate: Moment
  KPIs: Array<Metric & GoalType>
  loading: string
  dataAdapter: ABInsightsDataDSPSubtype
  errorDetail: ErrorDetail
  currentTab: VizId
  updateCurrentTab: typeof updateCurrentTab
};

const ABInsightsResults = (props: Props) => {
  const {
    qsParams,
    initFromQueryString: initFromQueryStringProp,
    errorDetail: { dataFetchError, endDateError },
    reportTitle,
    KPIs,
    startDate: startDateProp,
    endDate: endDateProp,
    currentTab,
    updateCurrentTab: updateCurrentTabProp,
    loading,
    dataAdapter,
  } = props;
  useMount(() => {
    // If KPIs exist, that means user navigated directly from the form, no need to initialize from query string
    if (!_.isEmpty(KPIs)) {
      return;
    }
    const [startDate, endDate] = ensureDatesAreValid(qsParams.startDate, qsParams.endDate);
    initFromQueryStringProp(qsParams, startDate, endDate);
  });
  if (loading === LOADING_STATES.loading) {
    return (
      <PermissionPageTemplate
        title={PAGE_TITLE}
        name={PAGE_NAME}
        permissions={[Permission.abInsightsRedesign]}
      >
        <PageLoading>
          <h3>This page may take a few minutes to load due to the large amount of data being processed.</h3>
        </PageLoading>
      </PermissionPageTemplate>
    );
  }
  return (
    <PermissionPageTemplate
      title={PAGE_TITLE}
      name={PAGE_NAME}
      subNavElements={<TextLinkBack name="Back to A/B Insights Form" linkTo={`${ROUTE}/1`} />}
      permissions={[Permission.abInsightsRedesign]}
    >
      {
        (dataFetchError || endDateError)
          ? (
            <ErrorComponent
              msg={dataFetchError || endDateError}
              pageTitle=""
              onBack={() => props.router.navigate(`${ROUTE}/1`)}
              buttonText="A/B Insights Form"
            />
          )
          : (
            <ABReportViz
              dataAdapter={dataAdapter}
              KPIs={KPIs}
              reportTitle={reportTitle}
              startDate={startDateProp}
              endDate={endDateProp}
              currentTab={currentTab}
              updateCurrentTab={updateCurrentTabProp}
            />
          )
}
    </PermissionPageTemplate>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.login.session.data.user,
  member: state.abInsights.member,
  advertiser: state.abInsights.advertiser,
  selectedFlights: state.abInsights.selectedFlights,
  reportTitle: state.abInsights.title,
  KPIs: state.abInsights.KPIs,
  startDate: state.abInsights.startDate,
  endDate: state.abInsights.endDate,
  currentTab: state.abInsights.currentTab,
  flightType: state.abInsights.flightType,
  errorDetail: state.abInsights.errorDetail,
  loading: state.abInsights.loading,
  dataAdapter: state.abInsights.dataAdapter,
});

const ABReportResultsWithQS = withRouter(withQueryString(ABInsightsResults));

export default connect(mapStateToProps, {
  initFromQueryString,
  updateCurrentTab,
})(ABReportResultsWithQS);
