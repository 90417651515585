import _ from 'lodash';
import React, { Component, Key, CSSProperties } from 'react';
import { Menu, Segment, Image, MenuProps, SegmentSizeProp } from 'buildingBlocks';
import { IMG_ICONS_16_BASE_PATH } from 'constantsBase';
import { pageContainerStyle, itemStyle, activeItemStyle, compactStyle } from './style';
import ListItem from './ListItem';

const CONTIGUOUS_PAGES = 6;
const MAX_DISPLAY_PAGES = 9;
const ELEMENTS_AROUND_CURRENT = 2;

const angleRightAlt = 'angle right';
const angleRightSrc = `${IMG_ICONS_16_BASE_PATH}/angle-right.svg`;
const angleLeftAlt = 'angle left';
const angleLeftSrc = `${IMG_ICONS_16_BASE_PATH}/angle-left.svg`;

// Needed so we can supply a key for react element list.
export const LEFT_ELLIPSIS = { id: 'left', value: '...' };
export const RIGHT_ELLIPSIS = { id: 'right', value: '...' };

export const createRange = ({ numberOfPages, currentPage }) => {
  if (numberOfPages <= MAX_DISPLAY_PAGES) {
    // No gap in the page numbers.
    return _.range(1, numberOfPages + 1);
  }
  if (currentPage < CONTIGUOUS_PAGES) {
    // Gap on the right hand side.
    return _.range(1, MAX_DISPLAY_PAGES - 1).concat([RIGHT_ELLIPSIS, numberOfPages]);
  }
  if (numberOfPages - currentPage < MAX_DISPLAY_PAGES / 2) {
    // Gap on the left hand side.
    return [1, LEFT_ELLIPSIS].concat(_.range(numberOfPages - CONTIGUOUS_PAGES, numberOfPages + 1));
  }
  // General case we'll have gaps on both sides.
  const left = [1, LEFT_ELLIPSIS];
  const middle = _.range(currentPage - ELEMENTS_AROUND_CURRENT, currentPage + ELEMENTS_AROUND_CURRENT + 1);
  const right = [RIGHT_ELLIPSIS, numberOfPages];
  return left.concat(middle).concat(right);
};

type Props = {
  /** The current page number. */
  currentPage: number
  /** The total number of pages. */
  numberOfPages: number
  /** Function called when user selects a page number. */
  onPageChange: Function
  /** When true, renders a version of the component with less whitespace. */
  compact?: boolean
  /**
   * Size option passed to the underlying Semantic <Segment> element.
   * One of:  [ 'mini', 'tiny', 'small', 'large', 'big', 'huge', 'massive']
   */
  size: SegmentSizeProp
  /** change the color of the page size selector (default: #fff (white)) */
  backgroundColor?: CSSProperties['backgroundColor']
};

export default class PageControl extends Component <Props, null> {
  onPageChange = (e, newPage) => {
    e.preventDefault();
    this.props.onPageChange(newPage || e.target.value);
  };

  createListItems() {
    const pageNumbers = createRange(this.props);

    if (this.props.compact) {
      return pageNumbers.map((item) => {
        if (item === LEFT_ELLIPSIS || item === RIGHT_ELLIPSIS) {
          return <div key={item.id}>...</div>;
        }
        return (
          <div key={item as Key}>
            {
              this.props.currentPage === item
                ? <p className="pagination-page-active" style={activeItemStyle}>{item}</p>
                : <a className="pagination-page" style={itemStyle} href="" onClick={(e) => this.onPageChange(e, item)}>{item}</a>
            }
          </div>
        );
      });
    }

    return pageNumbers.map((number) => {
      if (number === LEFT_ELLIPSIS || number === RIGHT_ELLIPSIS) {
        return <ListItem key={number.id} element="..." />;
      }
      return (
        <ListItem
          className="pagination-page"
          key={number as Key}
          element={number}
          activeItem={this.props.currentPage}
          onClick={() => this.props.onPageChange(number)}
        />
      );
    });
  }

  renderNext() {
    const {
      currentPage, compact, onPageChange, numberOfPages,
    } = this.props;
    const newPage = currentPage + 1;

    if (compact) {
      return newPage <= numberOfPages
        ? <a href="" onClick={(e) => this.onPageChange(e, newPage)}><Image alt={angleRightAlt} src={angleRightSrc} /></a>
        : <Image alt={angleRightAlt} src={angleRightSrc} />;
    }
    return newPage <= numberOfPages
      ? <ListItem className="pagination-angle" element={<Image alt={angleRightAlt} src={angleRightSrc} />} activeItem={-1} onClick={() => onPageChange(newPage)} />
      : <ListItem className="pagination-angle" element={<Image alt={angleRightAlt} src={angleRightSrc} />} />;
  }

  renderPrevious() {
    const { currentPage, compact, onPageChange } = this.props;
    const newPage = currentPage - 1;

    if (compact) {
      return newPage > 0
        ? <a href="" onClick={(e) => this.onPageChange(e, newPage)}><Image alt={angleLeftAlt} src={angleLeftSrc} /></a>
        : <Image alt={angleLeftAlt} src={angleLeftSrc} />;
    }
    return newPage > 0
      ? <ListItem className="pagination-angle" element={<Image alt={angleLeftAlt} src={angleLeftSrc} />} activeItem={-1} onClick={() => onPageChange(newPage)} />
      : <ListItem className="pagination-angle" element={<Image alt={angleLeftAlt} src={angleLeftSrc} />} />;
  }

  render() {
    const { backgroundColor } = this.props;
    return (
      <Segment size={this.props.size} style={{ ...pageContainerStyle, justifyContent: 'flex-start', overflowX: 'auto' }} basic>
        {
          this.props.compact ? (
            <div style={compactStyle}>
              <div>{this.renderPrevious()}</div>
              <div style={compactStyle}>{this.createListItems()}</div>
              <div>{this.renderNext()}</div>
            </div>
          ) : (
            <Menu size={this.props.size as MenuProps['size']} pagination style={{ backgroundColor }}>
              {this.renderPrevious()}
              {this.createListItems()}
              {this.renderNext()}
            </Menu>
          )
        }
      </Segment>
    );
  }
}
