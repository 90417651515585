import { CSSProperties } from 'react';
import { COPILOT_COLORS } from 'globalStyles';

const { WPP: { grey100 } } = COPILOT_COLORS;

const style: { [key: string]: CSSProperties } = {
  modalHeader: {
    borderTop: '10px solid #c3e03d',
  },
  label: {
    marginRight: '9px',
  },
  inputForm: {
    marginBottom: '7px',
  },
  roasCalcInput: {
    marginBottom: '13px',
  },
  roasCalcBtn: {
    display: 'flex',
    justifyContent: 'end',
    gap: '10px',
  },
  labelText: {
    marginBottom: '3px',
  },
  roasEstimateVal: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '13px',
    borderRadius: 9,
    background: grey100,
  },
  calcIcon: {
    margin: 0,
  },
};

export default style;
