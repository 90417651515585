import _ from 'lodash';
import React, { CSSProperties } from 'react';
import RCTimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import 'containers/UserSettings/components/TimePicker.css';
import { PossibleMoment } from 'utils/dateTime';

type Props = {
  input: {
    value: PossibleMoment,
    onChange: Function,
  },
  disabled?: boolean
  use12Hours?: boolean
  allowEmpty?: boolean
  showSecond?: boolean
  hideDisabledOptions?: boolean
  disabledMinutes?: Function
  placeholder?: string
  className?: string
  defaultValue?: PossibleMoment
  defaultOpenValue?: PossibleMoment
  style?: CSSProperties
  popupStyle?: CSSProperties
  popupClassName?: string
  format?: string
};

export const TimePicker = ({ input: { value, onChange }, ...rest }: Props) => (
  <RCTimePicker value={value} onChange={onChange} {..._.omit(rest, 'meta')} />
);

export default TimePicker;
