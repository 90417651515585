import React from 'react';
import { Button } from 'buildingBlocks';
import { ButtonClickHandler } from 'utils/types';

type Props = {
  padding?: string,
  /** CSS value for hamburger border radius. */
  borderRadius?: string,
  onClick?: ButtonClickHandler,
  className?: string
};

const Hamburger = ({ onClick = (e) => e.preventDefault(), padding = '.5', borderRadius = '10px', className }: Props) => (
  <Button
    icon="bars"
    style={{
      padding: `${padding}em ${padding}em ${padding}em`,
      borderRadius,
    }}
    onClick={onClick}
    className={className}
  />
);

export default Hamburger;
