import { CSSProperties } from 'react';
import { DSP } from 'constantsBase';
import { COPILOT_COLORS, COPILOT_TYPOGRAPHY, COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;
const {
  NEW_DESIGN_SYSTEM: { DSP: DSP_COLORS },
} = COPILOT_COLORS;

const dspIconStyles: { [key: string]: CSSProperties } = {
  base: {
    ...COPILOT_TYPOGRAPHY.DESCRIPTION.BOLD,
    padding: '0 10px',
    borderRadius: 4,
    textAlign: 'center' as 'center',
    minWidth: '57px',
    marginLeft: '3px',
    marginRight: SPACING[2],
    display: 'inline-flex',
    height: SPACING[20],
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
  },
  baseContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    height: SPACING[20],
    width: '63px',
  },
  [DSP.APN.code]: {
    backgroundColor: DSP_COLORS[DSP.APN.id].light,
    color: DSP_COLORS[DSP.APN.id].dark,
  },
  [DSP.TTD.code]: {
    backgroundColor: DSP_COLORS[DSP.TTD.id].light,
    color: DSP_COLORS[DSP.TTD.id].dark,
  },
  [DSP.DBM.code]: {
    backgroundColor: DSP_COLORS[DSP.DBM.id].light,
    color: DSP_COLORS[DSP.DBM.id].dark,
    padding: '0 8px',
  },
  [DSP.AMZN.code]: {
    backgroundColor: DSP_COLORS[DSP.AMZN.id].light,
    color: DSP_COLORS[DSP.AMZN.id].dark,
  },
  [DSP.MULTIPLE.code]: {
    backgroundColor: DSP_COLORS[DSP.MULTIPLE.id].light,
    color: DSP_COLORS[DSP.MULTIPLE.id].dark,
  },
  [DSP.WALMART.code]: {
    backgroundColor: DSP_COLORS[DSP.WALMART.id].light,
    color: DSP_COLORS[DSP.WALMART.id].dark,
  },
  PENDING: {
    color: '#ccc',
    border: '1px solid #CCC',
  },
  budgetGroup: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    height: SPACING[24],
  },
};

export default dspIconStyles;
