import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

const actionButton: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: SPACING[12],
};

const listItemTitle: CSSProperties = {
  wordBreak: 'break-all',
};

const listItem: CSSProperties = {
  marginBottom: SPACING[4],
};

const list: CSSProperties = {
  paddingLeft: SPACING[20],
  maxHeight: '150px',
  overflow: 'auto',
};

export default {
  actionButton,
  listItem,
  listItemTitle,
  list,
};
