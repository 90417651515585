import _ from 'lodash';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BudgetOptimizationVisualization from 'charts/BudgetOptimizationViz';
import HeliosRadialTreeViz from 'charts/HeliosDataViz';
import { Menu } from 'buildingBlocks';
import InsightsViz from 'charts/InsightsViz';
import Default from 'containers/StrategyAnalytics/components/View/Default';
import AudienceReport from 'containers/StrategyAnalytics/components/View/AudienceReporting';
import SegmentRecency from 'containers/StrategyAnalytics/components/View/SegmentRecency';
import Helios from 'containers/StrategyAnalytics/components/View/Helios';
import DataUploader from 'containers/StrategyAnalytics/components/View/DataUploader';
import CustomTree from 'containers/StrategyAnalytics/components/View/CustomTree/components/CustomTree';
import TabItem from './TabItem';

export type TabType = {
  label: string,
  to: (x: number | string) => string,
  component: any,
  render: any,
};

type Props = {
  id: number,
  tabs: Array<TabType>,
};

const TabView = ({ id, tabs }: Props) => (
  <div>
    {
      tabs
      && (
        <Menu className="custom-tab" style={{ marginBottom: 0 }} tabular>
          {
            _.map(tabs, (tab) => (
              <TabItem key={tab.label} to={tab.to(id)}>{tab.label}</TabItem>
            ))
          }
        </Menu>
      )
    }
    <Routes>
      <Route path="/" element={<Default />} />
      <Route path="/monitoring" element={<BudgetOptimizationVisualization />} />
      <Route path="/insights" element={<InsightsViz />} />
      <Route path="/audience" element={<AudienceReport />} />
      <Route path="/segmentrecency" element={<SegmentRecency />} />
      <Route path="/helios" element={<Helios />} />
      <Route path="/heliosgraph" element={<HeliosRadialTreeViz />} />
      <Route path="/customtree" element={<CustomTree />} />
      <Route path="/datauploader" element={<DataUploader />} />
    </Routes>
  </div>
);

export default TabView;
