import React, { CSSProperties } from 'react';
import { Container, WppInlineMessage } from 'buildingBlocks';
import errorStyles from './style';

type ErrorMessageProps = {
  errorText: string
  style?: CSSProperties
};

const ErrorMessage = ({ errorText, style }: ErrorMessageProps) => (
  <Container style={style ? { ...errorStyles.errorMessage, ...style } : errorStyles.errorMessage}>
    <WppInlineMessage
      size="s"
      message={errorText}
      type="error"
    />
  </Container>
);

export default ErrorMessage;
