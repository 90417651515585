import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const logoStyle = {
  marginRight: SPACING[32],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: SPACING[12],
};

export const wppTopbar: CSSProperties = {
  top: 0,
};

export const rightNavContainer: CSSProperties = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, auto)',
  gridGap: 10,
  alignItems: 'center',
};

export const logoImageStyle: CSSProperties = {
  width: SPACING[32],
};

export const userAvatarStyle: CSSProperties = {
  cursor: 'pointer',
};
