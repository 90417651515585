import { CSSProperties } from 'react';

export const chartStyle: CSSProperties = {
  minWidth: '310px',
  height: '600px',
  margin: '0 auto',
};

export const errorContainerStyle: CSSProperties = {
  margin: '0 0 1.5rem 0',
  textAlign: 'center',
  background: 'url(/img/components/StrategyAnalytics/default-chart.png) center no-repeat',
  minHeight: '400px',
  padding: '100px 0',
};

export const errorMessageStyle: CSSProperties = {
  width: '600px',
  textAlign: 'left',
  display: 'inline-block',
};
