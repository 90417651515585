import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { checkPermissions, MEMBER } from 'utils/featureFlags';
import { User, Member } from 'utils/types';

export type PermissionWrapperProps = {
  /** Logged-in user. */
  user?: User,
  /**
   * Permissions required for the child component to be rendered.  If this
   * attribute is omitted, the component will always be rendered.
   */
  permissions: Array<string> | string,
  /** Whether user has to have every permission in list, or just one. */
  allPermissions?: boolean,
  /** Member required for the child component to be rendered. If omitted, this defaults to MEMBER.ANY */
  member?: Member | string
  /** The component to render when user does not have access to the child component. */
  unauthorizedComponent?: React.ReactNode,
  /** When true, force the unauthorizedComponent to be rendered, or null if unauthorizedComponent is not provided. */
  unauthorized?: boolean,
  /** The child component to render if user has permission. */
  children: React.ReactNode,
};

const PermissionWrapper = ({
  user,
  permissions,
  member = MEMBER.ANY,
  children,
  unauthorizedComponent,
  allPermissions = true,
  unauthorized = false,
}: PermissionWrapperProps) => (
  unauthorized
  || !((_.isEmpty(permissions) || checkPermissions(user, permissions, member, allPermissions)))
    ? (<>{unauthorizedComponent}</> || null)
    : <>{children}</>
);

const mapStateToProps = (state) => ({
  user: _.get(state, 'login.session.data.user'),
});

export default connect(mapStateToProps)(PermissionWrapper);
