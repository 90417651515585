import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Grid, WppDatepicker, WppGrid, WppTypography, WppButton } from 'buildingBlocks';
import { DATE_FORMAT_JS_DATE, NUMBER_DATE_FORMAT, WPP_DATE_PICKER_FORMAT, WPP_DATE_PICKER_PLACEHOLDER_FORMAT } from 'utils/dateTime';
import { DatePickerEventDetail, WppDatepickerCustomEvent } from 'utils/types';
import { HEALTH_CARE_STYLES } from './style';

const { datePickerText, datepickerHealthGrid, applyBtnCopHealth } = HEALTH_CARE_STYLES;

type Props = {
  onDateRangeSubmit: Function
  hasApplyButton?: boolean
  initialStartDate?: Moment
  initialEndDate?: Moment
  enableFutureDateSelection?: boolean
  lookbackDays?: number
  timeZoneEnabled?: boolean
};

type State = {
  startDate: Date
  endDate: Date
  enableFutureDateSelection: boolean
  lookbackDays: number
  timeZoneEnabled: boolean
};

class DateRangePicker extends Component<Props, State> {
  static getInitialStartDate(startDate?: Moment) {
    if (startDate) {
      return moment(startDate).startOf('day');
    }
    return moment().subtract(1, 'days').startOf('day');
  }

  constructor(props: Props) {
    super(props);
    const startDate = DateRangePicker.getInitialStartDate(this.props.initialStartDate).toDate();
    const endDate = moment(this.props.initialEndDate).endOf('day').toDate();

    this.state = {
      startDate,
      endDate,
      enableFutureDateSelection: this.props.enableFutureDateSelection || false,
      lookbackDays: this.props.lookbackDays || 30,
      timeZoneEnabled: this.props.timeZoneEnabled,
    };
  }

  handleDateChanged = () => {
    if (!this.props.hasApplyButton) {
      if (this.state.startDate && this.state.endDate) {
        this.handleSubmit();
      }
    }
  };

  handleStartDateChange = (startDate: Date) => {
    if (moment(startDate).isAfter(this.state.endDate)) {
      this.setState({ endDate: moment(startDate).endOf('day').toDate() });
    }
    this.setState({ startDate }, () => {
      this.handleDateChanged();
    });
  };

  handleEndDateChange = (endDate: Date) => {
    if (moment(endDate).isBefore(this.state.startDate)) {
      this.setState({ startDate: moment(endDate).startOf('day').toDate() });
    }
    this.setState({ endDate }, () => {
      this.handleDateChanged();
    });
  };

  handleSubmit = () => {
    const { startDate, endDate } = this.state;
    this.props.onDateRangeSubmit(startDate, endDate);
  };

  render() {
    const { startDate, endDate, enableFutureDateSelection, timeZoneEnabled } = this.state;
    const datePickerProps = {
      class: 'datePicker',
      selected: this.state.startDate,
      onChange: this.handleStartDateChange,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      maxDate: this.state.enableFutureDateSelection ? null : moment(),
      minDate: moment().subtract(this.state.lookbackDays, 'days'),
      selectsStart: true,
      dateFormat: DATE_FORMAT_JS_DATE,
    };

    const wppDatePickerProps = {
      locale: { dateFormat: WPP_DATE_PICKER_FORMAT },
      size: 'm' as const,
      placeholder: WPP_DATE_PICKER_PLACEHOLDER_FORMAT,
      minDate: moment().subtract(this.state.lookbackDays, 'days').format(NUMBER_DATE_FORMAT),
      maxDate: enableFutureDateSelection ? null : moment().format(NUMBER_DATE_FORMAT),
    };

    return (
      <>
        {!this.props.hasApplyButton ? (
          <Form>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column width={timeZoneEnabled ? 6 : 2}>
                  <Form.Field>
                    <label htmlFor="datePicker">Select Start Date</label>
                    <DatePicker {...datePickerProps} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={timeZoneEnabled ? 6 : 2}>
                  <Form.Field>
                    <label htmlFor="datePicker">Select End Date</label>
                    <DatePicker
                      {...datePickerProps}
                      selected={this.state.endDate}
                      onChange={this.handleEndDateChange}
                      selectsEnd
                      selectsStart={false}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        ) : (
          <Form>
            <WppGrid item all={24} style={datepickerHealthGrid}>
              <WppGrid item all={timeZoneEnabled ? 12 : 4}>
                <WppTypography type="s-strong" style={datePickerText}>Start Date</WppTypography>
                <WppDatepicker
                  {...wppDatePickerProps}
                  value={startDate ? moment(startDate).format(NUMBER_DATE_FORMAT) : ''}
                  onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
                    const date = event.detail.date as Date;
                    this.handleStartDateChange(_.isNil(date) ? null : date);
                  }}
                  className="wppDateRangePicker"
                />
              </WppGrid>
              <WppGrid item all={timeZoneEnabled ? 12 : 4}>
                <WppTypography type="s-strong" style={datePickerText}>End Date</WppTypography>
                <WppDatepicker
                  {...wppDatePickerProps}
                  value={moment(endDate).format(NUMBER_DATE_FORMAT)}
                  onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
                    const date = event.detail.date as Date;
                    if (this.props.hasApplyButton && date) {
                      this.handleEndDateChange(date);
                    }
                  }}
                  className="wppDateRangePicker"
                  minDate={moment(startDate).format(NUMBER_DATE_FORMAT)}
                />
              </WppGrid>
              {this.props.hasApplyButton && (
                <WppGrid all={2}>
                  <WppButton onClick={this.handleSubmit} variant="primary" style={applyBtnCopHealth}>
                    Apply
                  </WppButton>
                </WppGrid>
              )}
            </WppGrid>
          </Form>
        )}
      </>
    );
  }
}

export default DateRangePicker;
