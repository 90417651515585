// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deleteConfirmationModal::part(content) {
  transform: translate(-50%, -50%) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/WppDeleteConfirmationModal/style.scss"],"names":[],"mappings":"AACE;EACE,2CAAA;AAAJ","sourcesContent":[".deleteConfirmationModal{\n  &::part(content){\n    transform: translate(-50%, -50%) !important\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
