// @ts-nocheck redux-form & ObjectDropdown props issues
import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { marginBottom10px, marginTop20px } from 'components/MemberAdvertiserAndFlights/constants';
import { Form, FieldProps, ObjectDropdownProps } from 'buildingBlocks';
import { OnSearchChange } from 'buildingBlocks/ObjectDropdown';
import { Advertiser, Member, EventHandler, PrettyOption } from 'utils/types';
import ObjectDropdown from 'buildingBlocks/ObjectDropdown/ObjectDropdown';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import FormSectionHeader from 'components/FormSectionHeader/index';
import { DSP } from 'constantsBase';

type AdvertiserProps = {
  advertisers: Array<Advertiser>,
  selectedAdvertiser: Advertiser,
  advertiserFetchError?: string,
  loadingAdvertisers: boolean,
  onSearchChange: OnSearchChange,
  onAdvSelect: Function,
  onAdvClick: Function,
};

type MemberProps = {
  prettyMembers: Array<PrettyOption>,
  selectedMember: {
    displayName: string,
  },
  onSeatSelect: EventHandler<any>,
  memberFetchError?: string,
  members: Array<Member>,
};

type SectionHeaderProps = {
  sectionHeaderProps: {
    group: string,
    title: string,
    help?: string,
  },
};

export type MemberAndAdvertiserProps = AdvertiserProps & MemberProps & SectionHeaderProps;

const MemberFormField = (props: MemberProps) => (
  <Form.Field style={marginTop20px}>
    <label htmlFor="member" style={marginBottom10px}>Select Member</label>
    { /** @ts-ignore */}
    <Field<FieldProps<ObjectDropdownProps<PrettyOption>>>
      id="member"
      name="member"
      component={ObjectDropdown}
      options={props.prettyMembers}
      keyFn={(mem) => `${DSP.getById(mem.dsp as number).code} - ${mem.displayName}`}
      text={props.selectedMember ? props.selectedMember.displayName : 'Search Members...'}
      onChange={props.onSeatSelect}
      disabled={!!props.memberFetchError || (props.members && props.members.length === 1)}
      placeholder="Search Members..."
      fluid
      search={{
        searchType: 'local',
        onSearchChange: searchByNameOrExtId(),
      }}
      selection
      required
    />
    <span style={{ color: '#DB2828' }}>{props.memberFetchError}</span>
  </Form.Field>
);

const AdvertiserFormField = (props: AdvertiserProps) => (
  <Form.Field style={marginTop20px}>
    <label htmlFor="advertiser" style={marginBottom10px}>Select Advertiser</label>
    { /** @ts-ignore */}
    <Field<FieldProps<ObjectDropdownProps<Advertiser>>>
      id="advertiser"
      name="advertiser"
      component={ObjectDropdown}
      onClick={props.onAdvClick}
      onChange={props.onAdvSelect}
      options={props.advertisers}
      keyFn={(adv) => `[ ${adv.externalId} ] - ${adv.name}`}
      text={props.selectedAdvertiser
        ? `[ ${props.selectedAdvertiser.externalId} ] - ${props.selectedAdvertiser.name}`
        : 'Search Advertisers...'}
      disabled={!_.isEmpty(props.advertiserFetchError)}
      loading={props.loadingAdvertisers}
      placeholder="Search Advertisers..."
      fluid
      selection
      required
      search={{
        searchType: 'api',
        onSearchChange: props.onSearchChange,
        debounce: { timer: 500 },
      }}
    />
    <span style={{ color: '#DB2828' }}>{props.advertiserFetchError}</span>
  </Form.Field>
);

export const MemberAndAdvertiser = ({
  prettyMembers,
  selectedMember,
  onSeatSelect,
  memberFetchError,
  members,
  advertisers,
  advertiserFetchError,
  loadingAdvertisers,
  onAdvSelect,
  onAdvClick,
  onSearchChange,
  selectedAdvertiser,
  sectionHeaderProps,
}: MemberAndAdvertiserProps) => (
  <>
    <FormSectionHeader {...sectionHeaderProps} />
    <Form.Group widths="equal">
      <MemberFormField
        prettyMembers={prettyMembers}
        selectedMember={selectedMember}
        onSeatSelect={onSeatSelect}
        memberFetchError={memberFetchError}
        members={members}
      />
      <AdvertiserFormField
        advertisers={advertisers}
        advertiserFetchError={advertiserFetchError}
        loadingAdvertisers={loadingAdvertisers}
        onAdvSelect={onAdvSelect}
        onAdvClick={onAdvClick}
        onSearchChange={onSearchChange}
        selectedAdvertiser={selectedAdvertiser}
      />
    </Form.Group>
  </>
);

export default MemberAndAdvertiser;
