export enum ActionTypes {
  STRATEGY_FOUND,
  NO_STRATEGY_FOUND,
  STRATEGY_FETCH_ERROR,
  FLIGHT_EXISTS_BUT_NO_STRATEGY_ATTACHED,
}

export enum StatusOptions {
  LOADING,
  STRATEGY_FOUND,
  NO_STRATEGY_FOUND,
  STRATEGY_FETCH_ERROR,
  FLIGHT_EXISTS_BUT_NO_STRATEGY_ATTACHED,
}

export type Action = {
  type: ActionTypes,
  payload?: { stratId?: number, error?: string, externalType?: { [key: string]: unknown } },
};
