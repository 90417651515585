import React from 'react';
import { Link } from 'react-router-dom';
import { BasicHeader } from 'components/SubNav';
import PermissionWrapper from 'components/PermissionWrapper';
import MaterialButton from 'components/MaterialButton';
import { ButtonClickHandler } from 'utils/types';

type Props = {
  id?: string,
  title: string,
  permission: any,
  to: string,
  onClick?: ButtonClickHandler,
};

const BtnPageHeader = ({ id, title, permission, to, onClick } : Props) => (
  <PermissionWrapper
    unauthorizedComponent={<BasicHeader header={title} />}
    permissions={permission}
  >
    <Link id={id} to={to}>
      <MaterialButton
        name={title}
        iconName="add"
        style={{ marginLeft: '-10px' }}
        onClick={onClick}
      />
    </Link>
  </PermissionWrapper>
);

export default BtnPageHeader;
