import _ from 'lodash';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { FetchFlightsAction } from 'components/DoubleFlightSelect/DoubleFlightSelect';
import MemberAdvertiserAndFlights from 'components/MemberAdvertiserAndFlights';
import PermissionPageTemplate from 'components/PageTemplate/PermissionPageTemplate';
import { DSP, FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { RootState } from 'reducers';
import { Permission } from 'utils/featureFlags';
import { Flight } from 'utils/types';
import withRouter, { RouterProps } from 'utils/withRouter';
import LowerForm from './LowerForm';
import { handleFormSubmit, fetchFlights } from '../actions';
import { PAGE_TITLE, PAGE_NAME, FORM_NAME, createDefaultFormValues, ROUTE } from '../constants';
import { FormValues, ErrorDetail } from '../types';
import { formValuesToQueryString } from '../utils';
import validate from '../validate';

const { apnLineItem, apnInsertionOrder, ttdCampaign, ttdMegagon, dbmInsertionOrder, dbmLineItem } = FLIGHT_EXTERNAL_TYPE;

type HandleFormSubmit = (values: FormValues) => void;

type Props = RouterProps & {
  fetchFlights: FetchFlightsAction
  handleFormSubmit: HandleFormSubmit
  flights: Array<Flight>
  formValues: FormValues
  errorDetail: ErrorDetail
};

const setBrowserUrl = (values: FormValues, props) => {
  const { location, navigate } = props.router;
  const search = formValuesToQueryString(values);
  navigate({ pathname: location.pathname, search }, { replace: true });
};

const flightTypeOptionsByDsp = {
  [DSP.APN.id]: [apnLineItem, apnInsertionOrder],
  [DSP.TTD.id]: [ttdMegagon, ttdCampaign],
  [DSP.DBM.id]: [dbmLineItem, dbmInsertionOrder],
};

export const ABInsightsFormComponent = (props: Props & InjectedFormProps) => {
  const {
    fetchFlights: fetchFlightsProp,
    change,
    handleSubmit,
    handleFormSubmit: handleFormSubmitProp,
    flights,
    formValues = {},
    valid,
    errorDetail: {
      flightFetchError,
    },
    router,
    reset,
  } = props;
  const { member, advertiser, flightLeft, flightRight, flightType } = formValues as FormValues;
  const { location, navigate } = router;

  const getRoute = useCallback((pageNum: string) => `${ROUTE}/${pageNum}/${location.search}`, [location.search]);

  const submit = useCallback((values: FormValues) => {
    const pageTwoRoute = getRoute('2');
    handleFormSubmitProp(values);
    // historyProp.push(pageTwoRoute, { from: `${ROUTE}/1` });
    navigate(pageTwoRoute);
  }, [getRoute, handleFormSubmitProp, navigate]);

  return (
    <PermissionPageTemplate title={PAGE_TITLE} name={PAGE_NAME} permissions={[Permission.abInsightsRedesign]}>
      <MemberAdvertiserAndFlights
        handleSubmit={handleSubmit}
        handleFormSubmit={submit}
        change={change}
        member={member}
        flights={flights}
        flightType={flightType}
        flightLeft={flightLeft}
        flightRight={flightRight}
        advertiser={advertiser}
        fetchFlights={fetchFlightsProp}
        dspIds={[DSP.APN.id, DSP.DBM.id, DSP.TTD.id]}
        lowerForm={LowerForm}
        canSubmit={valid}
        pristine={_.every({ member, advertiser }, _.isNil)}
        formName={FORM_NAME}
        showFlightTypeSelector
        flightTypeOptions={_.get(flightTypeOptionsByDsp, _.get(member, 'dsp') as number, [])}
        flightFetchError={flightFetchError}
        resetFields={reset}
      />
    </PermissionPageTemplate>
  );
};

const mapStateToProps = (state: RootState) => {
  const DEFAULT_FORM_VALUES = createDefaultFormValues();
  return {
    initialValues: DEFAULT_FORM_VALUES,
    flights: state.abInsights.flights,
    errorDetail: state.abInsights.errorDetail,
    formValues: state.form[FORM_NAME]?.values,
  };
};

const ABInsightsForm = reduxForm({
  form: FORM_NAME,
  validate,
  onChange: (values: FormValues, _dispatch, props: InjectedFormProps & Props) => setBrowserUrl(values, props),
})(ABInsightsFormComponent);

export default connect(mapStateToProps, {
  handleFormSubmit,
  fetchFlights,
})(withRouter(ABInsightsForm));
