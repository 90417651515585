import React from 'react';
import { Icon } from 'buildingBlocks';
import { GenericMouseEventHandler } from 'utils/types';
import styles from '../style';

type Props = {
  onClick: GenericMouseEventHandler,
};

const CalcTrigger = ({ onClick }: Props) => (
  <div slot="icon-end">
    <Icon className="calc-trigger" style={styles.calcIcon} link name="calculator" onClick={onClick} />
  </div>
);

export default CalcTrigger;
