import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ABInsightsForm from './components/ABInsightsForm';
import ABInsightsResults from './components/ABInsightsResults';

const ABInsightsRoutes = () => (
  <Routes>
    <Route path="/abinsights/1" element={<ABInsightsForm />} />
    <Route path="/abinsights/2" element={<ABInsightsResults />} />
  </Routes>
);

export default ABInsightsRoutes;
