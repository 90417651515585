import React from 'react';
import { Input, Label, Button } from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponents';

export const WrappedButton = (props) => <Button type="button" {...props} />;

export type Props = {
  input: {},
  min: number,
  disabled: boolean,
};

export const LabelledInput = ({ input, ...rest }: Props) => (
  <div>
    <Input fluid labelPosition="right" type="text">
      <input type="number" {...input} {...rest} />
      <Label>Days</Label>
    </Input>
  </div>
);

export const LabelledInputWrapper = validatedFieldWrapper(LabelledInput);

export const ButtonWrapper = validatedFieldWrapper(Button);
