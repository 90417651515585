// @ts-nocheck redux-form & ObjectDropdown props issues
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { Form, ObjectDropdown, ObjectDropdownProps, FieldProps } from 'buildingBlocks';
import { marginBottom10px, marginTop20px } from 'components/MemberAdvertiserAndFlights/constants';
import { Advertiser as AdvertiserAPI } from 'utils/copilotAPI';
import { Advertiser, Member } from 'utils/types';

type AdvertiserDropdownProps = {
  selectedMember: Member
  selectedAdv: Advertiser
  onAdvSelect: Function
};

const AdvertiserDropdown = (props: AdvertiserDropdownProps) => {
  const { selectedMember, selectedAdv, onAdvSelect } = props;
  const [advertisers, setAdvertisers] = useState<Array<Advertiser>>([]);
  const [advertiserFetchError, setAdvertiserFetchError] = useState<string>('');
  const [loadingAdvertisers, setLoadingAdvertiser] = useState<boolean>(false);

  const disabled = loadingAdvertisers || !_.isEmpty(advertiserFetchError) || _.isNil(selectedMember) || _.isEmpty(advertisers);

  const fetchAdvertisers = async (mem: number, searchTerm: string = '') => {
    setLoadingAdvertiser(true);
    const limit = 10;
    const sort = 'updatedAt DESC';
    const where = {
      member: mem,
      or: [
        { name: { contains: searchTerm } },
        { externalId: { contains: searchTerm } },
      ],
    };

    try {
      let res = await AdvertiserAPI.get({ where, sort, limit });
      if (res.data.length === 0) {
        const newWhere = {
          ...where,
          or: [
            { name: { contains: '' } },
            { externalId: { contains: '' } },
          ],
        };
        res = await AdvertiserAPI.get({ where: newWhere, sort, limit });
      }
      setLoadingAdvertiser(false);
      setAdvertisers(res.data);
    } catch {
      setLoadingAdvertiser(false);
      setAdvertiserFetchError('Failed to fetch advertisers');
    }
  };

  const handleSearchChange = (_e: React.FormEvent, allProps: { searchQuery: string }) => (
    fetchAdvertisers(selectedMember.id, allProps.searchQuery)
  );

  useEffect(() => {
    if (selectedMember) {
      fetchAdvertisers(selectedMember.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMember]);

  return (
    <Form.Field style={marginTop20px}>
      <label htmlFor="advertiser" style={marginBottom10px}>Select Advertiser</label>
      { /* @ts-ignore ObjectDropdown refactor */}
      <Field<FieldProps<ObjectDropdownProps<Advertiser>>>
        id="advertiser"
        name="advertiser"
        component={ObjectDropdown}
        keyFn={(adv) => `[ ${adv.externalId} ] - ${adv.name}`}
        placeholder="Search Advertisers..."
        onChange={onAdvSelect}
        options={advertisers}
        text={selectedAdv
          ? `[ ${selectedAdv.externalId} ] - ${selectedAdv.name}`
          : 'Search Advertisers...'}
        disabled={disabled}
        search={{
          searchType: 'api',
          onSearchChange: handleSearchChange,
          debounce: { timer: 500 },
        }}
        fluid
        selection
        required
      />
      <span style={{ color: '#DB2828' }}>{advertiserFetchError}</span>
    </Form.Field>
  );
};

export default AdvertiserDropdown;
