import _ from 'lodash';
import {
  required, applyAllValidators, crossValidate, isNotEqualTo, listLengthValidator,
} from 'utils/formValidators';

const KPIValidator = () => {
  const min = 1;
  const max = 6;
  const gt = listLengthValidator(_.lte, min, `Select at least ${min} KPI.`);
  const lt = listLengthValidator(_.gte, max, `Select ${max} or fewer KPIs.`);
  return [gt, lt];
};

const validators = {
  flightLeft: [required, crossValidate(isNotEqualTo, 'flightRight')],
  flightLeftAlias: required,
  flightType: required,
  member: required,
  advertiser: required,
  flightRight: required,
  flightRightAlias: required,
  startDate: required,
  endDate: required,
  title: required,
  KPIs: [required, ...KPIValidator()],
};

const validate = (values) => applyAllValidators(values, validators);

export default validate;
