// @ts-nocheck redux-form & ObjectDropdown props issues
import React, { ChangeEventHandler } from 'react';
import { Field } from 'redux-form';
import { FieldProps } from 'buildingBlocks';
import ObjectDropdown, { ObjectDropdownProps, OnSearchChange } from 'buildingBlocks/ObjectDropdown';
import { Flight, ApnObj } from 'utils/types';

type Props = {
  fieldName: string,
  options: Array<Flight>,
  onSearchChange: OnSearchChange,
  selectedFlight: Flight,
  formatApnObj: (key: ApnObj) => string,
  onChange: ChangeEventHandler<EventTarget>,
};

const FlightInputField = ({
  fieldName, options, onSearchChange, selectedFlight, formatApnObj, onChange,
}: Props) => (
  <Field<FieldProps<ObjectDropdownProps<Flight>>>
    id={fieldName}
    name={fieldName}
    component={ObjectDropdown}
    options={options}
    keyFn={(value) => formatApnObj(value)}
    search={{
      searchType: 'api',
      onSearchChange,
      debounce: { timer: 500 },
    }}
    text={selectedFlight
      ? formatApnObj(selectedFlight)
      : 'Search Flights...'}
    selection
    fluid
    placeholder="Search Flights..."
    onChange={onChange}
  />
);

export default FlightInputField;
