import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useState, useCallback, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, WppDatepicker, WppGrid, WppTypography, WppButton } from 'buildingBlocks';
import { NUMBER_DATE_FORMAT, WPP_DATE_PICKER_FORMAT, WPP_DATE_PICKER_PLACEHOLDER_FORMAT } from 'utils/dateTime';
import { DatePickerEventDetail, WppDatepickerCustomEvent } from 'utils/types';
import { HEALTH_CARE_STYLES } from './style';

const { datePickerText, datepickerHealthGrid, applyBtnCopHealth } = HEALTH_CARE_STYLES;

type Props = {
  onDateRangeSubmit: Function
  hasApplyButton?: boolean
  initialStartDate?: Moment
  initialEndDate?: Moment
  enableFutureDateSelection?: boolean
  lookbackDays?: number
  timeZoneEnabled?: boolean
};

const DateRangePicker: React.FC<Props> = ({
  onDateRangeSubmit,
  hasApplyButton,
  initialStartDate,
  initialEndDate,
  enableFutureDateSelection = false,
  lookbackDays = 30,
  timeZoneEnabled,
}) => {
  const getInitialStartDate = useCallback(() => {
    if (initialStartDate) {
      return moment(initialStartDate).startOf('day').toDate();
    }
    return moment().subtract(1, 'days').startOf('day').toDate();
  }, [initialStartDate]);

  const [startDate, setStartDate] = useState<Date>(getInitialStartDate());
  const [endDate, setEndDate] = useState<Date>(moment(initialEndDate).endOf('day').toDate());
  const [currentLookbackDays] = useState<number>(lookbackDays);
  const formattedStartDate = moment(startDate).format(NUMBER_DATE_FORMAT);
  const formattedEndDate = moment(endDate).format(NUMBER_DATE_FORMAT);

  const handleSubmit = useCallback(() => {
    onDateRangeSubmit(startDate, endDate);
  }, [onDateRangeSubmit, startDate, endDate]);

  const handleStartDateChange = (newStartDate: Date) => {
    if (moment(newStartDate).isAfter(endDate)) {
      setEndDate(moment(newStartDate).endOf('day').toDate());
    }
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate: Date) => {
    if (moment(newEndDate).isBefore(startDate)) {
      setStartDate(moment(newEndDate).startOf('day').toDate());
    }
    setEndDate(newEndDate);
  };

  const handleStartDatePickerChange = (event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
    const date = event.detail.date as Date;
    if (!moment(startDate, NUMBER_DATE_FORMAT).isSame(moment(date, NUMBER_DATE_FORMAT))) {
      handleStartDateChange(_.isNil(date) ? null : date);
    }
  };

  const handleEndDatePickerChange = (event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
    const date = event.detail.date as Date;
    if (date && !moment(endDate, NUMBER_DATE_FORMAT).isSame(moment(date, NUMBER_DATE_FORMAT))) {
      handleEndDateChange(date);
    }
  };

  useEffect(() => {
    if (!hasApplyButton && startDate && endDate) {
      handleSubmit();
    }
  }, [startDate, endDate, handleSubmit, hasApplyButton]);

  const wppDatePickerProps = {
    locale: { dateFormat: WPP_DATE_PICKER_FORMAT },
    size: 'm' as const,
    placeholder: WPP_DATE_PICKER_PLACEHOLDER_FORMAT,
    minDate: moment().subtract(currentLookbackDays, 'days').format(NUMBER_DATE_FORMAT),
    maxDate: enableFutureDateSelection ? null : moment().format(NUMBER_DATE_FORMAT),
  };

  return (
    <Form>
      <WppGrid item all={24} style={datepickerHealthGrid}>
        <WppGrid item all={timeZoneEnabled ? 12 : 4}>
          <WppTypography type="s-strong" style={datePickerText}>Start Date</WppTypography>
          <WppDatepicker
            {...wppDatePickerProps}
            value={startDate ? formattedStartDate : ''}
            onWppChange={handleStartDatePickerChange}
            className="wppDateRangePicker"
          />
        </WppGrid>
        <WppGrid item all={timeZoneEnabled ? 12 : 4}>
          <WppTypography type="s-strong" style={datePickerText}>End Date</WppTypography>
          <WppDatepicker
            {...wppDatePickerProps}
            value={formattedEndDate}
            onWppChange={handleEndDatePickerChange}
            className="wppDateRangePicker"
            minDate={formattedStartDate}
          />
        </WppGrid>
        {hasApplyButton && (
          <WppGrid all={2}>
            <WppButton onClick={handleSubmit} variant="primary" style={applyBtnCopHealth}>
              Apply
            </WppButton>
          </WppGrid>
        )}
      </WppGrid>
    </Form>
  );
};

export default DateRangePicker;
