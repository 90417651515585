// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wppTopbar::part(wrapper) {
  height: 56px;
  display: flex;
  align-items: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Nav/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;AACF","sourcesContent":[".wppTopbar::part(wrapper){\n  height: 56px;\n  display: flex;\n  align-items: center;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
