import React from 'react';
import { Field } from 'redux-form';
import { Grid, Form, FieldProps, ButtonProps } from 'buildingBlocks';
import strategyWizard from 'containers/StrategyWizard/styles';
import { AB_INSIGHTS_ELEMENT_TAG } from 'cssTagConstants';
import { ButtonWrapper } from './Wrappers';

const { column } = strategyWizard;

type SubmitButtonsProps = {
  canSubmit: boolean,
  pristine: boolean,
  onSubmit?: Function,
  resetFields: Function,
  submitName: string,
  cancelName: string,
};

type DoneButtonProps = {
  resetFields: Function,
  name: string,
};

export const FormButtons = ({
  canSubmit, onSubmit, resetFields, pristine, submitName, cancelName,
}: SubmitButtonsProps) => (
  <Grid.Column>
    <Form.Group widths="equal">
      <Form.Field style={{ textAlign: 'right' }}>
        <Field<FieldProps<ButtonProps>>
          id={AB_INSIGHTS_ELEMENT_TAG.submitBtn}
          component={ButtonWrapper}
          name={submitName}
          disabled={!canSubmit}
          onClick={onSubmit}
          content="Submit"
          primary
          noInput
        />
      </Form.Field>
      <Form.Field style={{ textAlign: 'left' }}>
        <Field<FieldProps<ButtonProps>>
          component={ButtonWrapper}
          name={cancelName}
          onClick={(e) => resetFields(e)}
          disabled={pristine}
          content="Clear"
          noInput
        />
      </Form.Field>
    </Form.Group>
  </Grid.Column>
);

export const DoneButton = ({ resetFields, name }: DoneButtonProps) => (
  <Grid.Column style={column}>
    <Form.Field>
      <Field<FieldProps<ButtonProps>>
        component={ButtonWrapper}
        name={name}
        onClick={resetFields}
        content="Done"
        noInput
      />
    </Form.Field>
  </Grid.Column>
);

export default FormButtons;
