import { CSSProperties } from 'react';

export const HEALTH_CARE_STYLES: { [key: string]: CSSProperties } = {
  datePickerText: {
    marginBottom: '5px',
  },
  datepickerHealthGrid: {
    display: 'flex',
    padding: '0',
    alignItems: 'center',
  },
  applyBtnCopHealth: {
    marginTop: '25px',
  },
};
