import _ from 'lodash';
import React, { useState, useReducer, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WppPopover } from 'buildingBlocks';
import { GlobalState } from 'reducers';
import NotificationTrigger from './components/NotificationTrigger';
import { NOTIFICATIONS_INITIAL_PAGE_SKIP, SelectedCategoryFilter, ActiveTab, NOTIFICATIONS_PAGE_CHANGE, NOTIFICATIONS_SWITCH_CATEGORY, NOTIFICATIONS_CLICK_NOTIFICATION, NOTIFICATIONS_ON_BELL_CLICK } from './constants';
import reducer, { INITIAL_STATE } from './reducer';
import { NOTIFICATION_STYLES } from './style';
import SegmentedControls from './components/SegmentedControls';
import { NotificationMsgObject } from './types';
import { getActionNotifications, getNotificationsToDisplay, getUpdateNotifications, readNotification } from './utils';
import './style.scss';

type DataRangeChangeProps = {
  limit: number
  start: number
};

const Notification: React.FC = (): React.ReactElement => {
  const {
    notifications: allNotifications,
    notificationsActionsUnreadCount,
    notificationsUpdatesUnreadCount,
  } = useSelector<GlobalState>((state) => state.login) as any;

  const popoverRef: React.MutableRefObject<HTMLWppPopoverElement> = useRef<HTMLWppPopoverElement>(null);

  const notificationsActionsCount = _.size(getActionNotifications(allNotifications));
  const notificationsUpdatesCount = _.size(getUpdateNotifications(allNotifications));

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [notifications, setNotifications] = useState<Array<NotificationMsgObject>>([]);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    // set notifications on initial load once allNotifications has populated
    if (_.isEmpty(notifications) && !_.isEmpty(allNotifications)) {
      setNotifications(getNotificationsToDisplay(allNotifications, ActiveTab.actions, 0));
    }
    // update currently displayed notifications as socket messages come in
    if (!_.isEmpty(notifications)) {
      setNotifications(getNotificationsToDisplay(allNotifications, state.activeTab, state.skip));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifications]);

  const onDataRangeChange = ({ limit, start }: DataRangeChangeProps) => {
    dispatch({
      type: NOTIFICATIONS_PAGE_CHANGE,
      payload: {
        skip: start,
        startingPage: (start / limit) + 1,
      },
    });
    setNotifications(getNotificationsToDisplay(allNotifications, state.activeTab, start));
  };

  const onClickNotification = (event: React.MouseEvent<HTMLDivElement>, selectedNotification: NotificationMsgObject) => {
    event.stopPropagation();
    dispatch({
      type: NOTIFICATIONS_CLICK_NOTIFICATION,
      payload: selectedNotification.id,
    });
    if (_.isNil(selectedNotification.readAt)) {
      const selectedCategoryFilter = state.activeTab === ActiveTab.actions ? SelectedCategoryFilter.actions : SelectedCategoryFilter.updates;
      readNotification(selectedNotification.id, selectedNotification.user, selectedCategoryFilter, true, reduxDispatch);
    }
  };

  const handleTabChange = async (activeIndex: number) => {
    if (_.isEqual(activeIndex, state.activeTab)) return;

    setNotifications(getNotificationsToDisplay(allNotifications, activeIndex, NOTIFICATIONS_INITIAL_PAGE_SKIP));
    dispatch({
      type: NOTIFICATIONS_SWITCH_CATEGORY,
      payload: { activeTab: activeIndex, open: true },
    });
  };

  const closeNotificationDropdown = () => dispatch({ type: NOTIFICATIONS_ON_BELL_CLICK, payload: false });

  useEffect(() => {
    if (!state.open && popoverRef && popoverRef.current?.closePopover) {
      // @ts-ignore - To ignore Property 'tippyInstance' does not exist on type 'HTMLWppPopoverElement'.
      if (popoverRef.current?.tippyInstance?.state?.isEnabled) {
        popoverRef.current?.closePopover();
      }
    }
  }, [state.open]);

  const segmentedControlsProps = {
    notifications,
    notificationsActionsCount,
    notificationsUpdatesCount,
    notificationsActionsUnreadCount,
    notificationsUpdatesUnreadCount,
    onClickNotification,
    onDataRangeChange,
    closeNotificationDropdown,
    state,
    handleTabChange,
  };

  return (
    <WppPopover
      id="cp-notification-dp"
      ref={popoverRef}
      config={{ appendTo: () => document.querySelector('#app')!, onClickOutside: () => dispatch({ type: NOTIFICATIONS_ON_BELL_CLICK, payload: false }), offset: [0, 22] }}
    >
      <span slot="trigger-element">
        <NotificationTrigger
          onClick={() => dispatch({ type: NOTIFICATIONS_ON_BELL_CLICK, payload: !state.open })}
          notificationsActionsUnreadCount={notificationsActionsUnreadCount}
        />
      </span>
      <div style={NOTIFICATION_STYLES.notificationContainer}>
        <SegmentedControls {...segmentedControlsProps} />
      </div>
    </WppPopover>
  );
};

export default Notification;
