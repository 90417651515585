import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { HOME_PAGE_ELEMENT_TAG } from 'cssTagConstants';
import { WppSortButton, WppListItem, WppMenuContext } from 'buildingBlocks';

type Option = {
  text: string
  value: string
  order: string
  id: string
};

type Props = {
  /** Label for the dropdown. */
  text: string
  /** Array of options for the dropdown. */
  options: Array<Option>
  /** Initial value of the dropdown. */
  value: string
  /** Function called when user changes sort option. */
  onChange: Function
  /** Order of the sort, either `'ASC'` or `'DESC'`. */
  order: string
  /** Input name for the underlying `<Dropdown>`. */
  name: string
};

/**
 * Renders a dropdown by which user can select sort options, as for a sortable
 * column in a table.
 */
const WppSortDropdown: React.FC<Props> = (props: Props): React.ReactElement => {
  const { order, value, name } = props;
  const [text, setText] = useState<string>(props.text);

  useEffect(() => {
    const Option: Option = props.options.find((option: Option) => _.isEqual(option.value, value) && _.isEqual(option.order, order));
    setText(Option.text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.order, props.value]);

  const handleOptionClick = (option: Option) => {
    props.onChange(option);
  };

  return (
    <WppMenuContext>
      <WppSortButton name={name} id={HOME_PAGE_ELEMENT_TAG.sortBtn} slot="trigger-element">Sort : {text}</WppSortButton>
      <div id={HOME_PAGE_ELEMENT_TAG.sortDropdown}>
        {
          props.options.map((option: Option) => (
            <WppListItem
              key={`${option.id}-${option.order}`}
              id={`${option.id}-${option.order}`}
              checked={_.isEqual(option.value, props.value) && _.isEqual(option.order, props.order)}
              onWppChangeListItem={() => handleOptionClick(option)}
            >
              <p slot="label">{option.text}</p>
            </WppListItem>
          ))
        }
      </div>
    </WppMenuContext>
  );
};

export default WppSortDropdown;
