/**
 *
 * PageTemplate
 *
 */

import React from 'react';
import DocumentTitle from 'react-document-title';
import WppSubNav from 'components/WppSubNav';
import { WppGrid } from 'buildingBlocks';
import {
  wppBodyContainer,
  wppActionContainer,
  wppPageTemplateContainer,
} from './style';

export type PageTemplateProps = {
  /** The name of the current page. */
  name?: string
  /** The title of the current page. */
  title?: string
  /** The contents of the page. */
  children: React.ReactNode
  /** React element representing sub-navigation. */
  subNavElements?: React.ReactNode
  /** React element representing form actions. */
  actionElements?: React.ReactNode
  /** React element representing a custom header. */
  customHeader?: React.ReactNode
  withoutLayout? : boolean
};

const WppPageTemplate = (props: PageTemplateProps) => {
  const { name, title, children, subNavElements, actionElements, customHeader, withoutLayout } = props;

  return (
    <div id={title || name} style={withoutLayout ? null : wppPageTemplateContainer}>
      {title || name ? <DocumentTitle title={title || name} /> : null}
      <WppGrid container>
        {name && (
          <WppSubNav header={name} customHeader={customHeader}>
            {subNavElements}
          </WppSubNav>
        )}
        <WppGrid item all={24} style={withoutLayout ? null : wppBodyContainer}>
          {children}
        </WppGrid>
        <WppGrid item all={24} style={wppActionContainer}>
          {actionElements}
        </WppGrid>
      </WppGrid>
    </div>
  );
};

export default WppPageTemplate;
