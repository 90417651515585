import React from 'react';
import PageLoading from 'components/PageLoading';

type Props = {
  error?: string,
  pageName?: string,
};

export const PageLoadError = ({ error, pageName }: Props) => (
  <PageLoading pageName={pageName}>
    <div style={{ color: 'red' }}>{error}</div>
  </PageLoading>
);

export default PageLoadError;
