import _ from 'lodash';
import React from 'react';
import { NotificationItem } from './NotificationItem';
import { NotificationMsgObject } from '../types';

type NotificationItemsProps = {
  notifications: Array<NotificationMsgObject>
  onClickNotification: (event: React.MouseEvent<HTMLDivElement>, selectedNotification: NotificationMsgObject) => void
  selectedMsgId: string | null
};

const NotificationItems: React.FC<NotificationItemsProps> = ({
  notifications,
  onClickNotification,
  selectedMsgId,
}: NotificationItemsProps): React.ReactElement => (
  <>
    {_.map(notifications, (notification: NotificationMsgObject) => (
      <NotificationItem
        key={notification.lastNotification}
        notification={notification}
        selectedMsg={_.isEqual(notification.id, selectedMsgId)}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => onClickNotification(event, notification)}
      />
    ))}
  </>
);

export default NotificationItems;
