import _ from 'lodash';
import React from 'react';
import { Grid, Form } from 'buildingBlocks';
import { marginBottom10px } from 'components/MemberAdvertiserAndFlights/constants';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import FlightTypeField from 'components/FlightTypeField';
import { Member, Advertiser, Flight, ApnObj } from 'utils/types';
import FlightInputField from './FlightInputField';

const preventOverflow = {
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  whiteSpace: 'pre-wrap',
};

export type FetchFlightsAction = (
  memberId: number,
  advertiserId: number,
  flightType: number,
  searchString?: string | null,
  includeDisabledFlights?: boolean,
  excludedIds?: Array<number>,
) => {
  type: string,
  payload: { memberId: number, advertiserId: number, flightType: number, searchString: string, includeDisabledFlights: boolean, excludedIds?: Array<number>; },
};

type Props = {
  fetchFlights: FetchFlightsAction,
  member: Member,
  advertiser: Advertiser,
  flightFetchError?: string,
  flightType: number,
  flights: Array<Flight>,
  flightLeft: Flight,
  flightRight: Flight,
  clearFlightFields: () => void,
  formatApnObj: (obj: ApnObj) => string,
  showFlightTypeSelector: boolean,
  flightTypeOptions: Array<{ id: number, displayName: string, wizardDisplayName: string }>
};

const DoubleFlightSelect = ({
  flightType,
  member,
  advertiser,
  flights,
  flightLeft,
  flightRight,
  fetchFlights,
  clearFlightFields,
  formatApnObj,
  flightFetchError,
  showFlightTypeSelector,
  flightTypeOptions,
}: Props) => {
  const flightTypeName = _.get(FLIGHT_EXTERNAL_TYPE.getById(flightType), 'displayName', '');
  return (
    <>
      {showFlightTypeSelector && (
        <Grid.Row key="1" style={marginBottom10px}>
          <Grid.Column style={{ padding: '0 1.5rem' }}>
            <Form.Field>
              <FlightTypeField
                selectedValue={flightType}
                flightTypeOptions={flightTypeOptions}
                afterChange={(flightTypeValue: number) => {
                  clearFlightFields();
                  fetchFlights(member.id, advertiser.id, flightTypeValue, '');
                }}
              />
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row key="2">
        <Grid.Column style={{ ...preventOverflow, padding: '0 1.5rem' }}>
          <Form.Group widths="equal">
            <Form.Field>
              <label htmlFor="flightLeft">{flightTypeName} A</label>
              <FlightInputField
                fieldName="flightLeft"
                options={flights}
                onSearchChange={
                  (_e: unknown, value: { searchQuery?: string }) => fetchFlights(member.id, advertiser.id, flightType, value.searchQuery)
                }
                onChange={
                  // fetch without searchQuery after select otherwise dropdown "B" is filtered by the searchquery
                  () => fetchFlights(member.id, advertiser.id, flightType)
                }
                selectedFlight={flightLeft}
                formatApnObj={formatApnObj}
              />
              <span style={{ color: '#DB2828' }}>{flightFetchError}</span>
            </Form.Field>
            <Form.Field>
              <label htmlFor="flightLeft">{flightTypeName} B</label>
              <FlightInputField
                fieldName="flightRight"
                options={flights}
                onSearchChange={
                  (_e: unknown, value: { searchQuery?: string }) => fetchFlights(member.id, advertiser.id, flightType, value.searchQuery)
                }
                onChange={
                  () => fetchFlights(member.id, advertiser.id, flightType)
                }
                selectedFlight={flightRight}
                formatApnObj={formatApnObj}
              />
            </Form.Field>
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default DoubleFlightSelect;
