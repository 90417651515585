import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

const style: { [key: string]: CSSProperties } = {
  legendBadges: {
    display: 'flex',
    gap: SPACING[8],
    alignItems: 'center',
    height: '100%',
    flexWrap: 'wrap',
  },
  menuContext: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  menuItem: {
    margin: 0,
  },
  menuContainer: {
    display: 'grid',
    gap: SPACING[4],
  },
  modalActionContainer: {
    padding: 0,
  },
  modalActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: SPACING[12],
    padding: 'var(--modal-actions-paddings)',
  },
  modalBodySection: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[12],
    marginBottom: SPACING[12],
  },
  selectFlightsTitle: {
    color: 'var(--wpp-dataviz-color-seq-grey-600)',
  },
  selectedFlightsTitle: {
    color: 'var(--wpp-grey-color-1000)',
    marginBottom: SPACING[12],
  },
  selectedFlightsItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[4],
    maxHeight: '188px',
    overflow: 'auto',
  },
  selectedFlightsContainer: {
    backgroundColor: 'var(--wpp-grey-color-100)',
    borderRadius: 'var(--wpp-border-radius-m)',
    padding: '12px 16px',
  },
};

export default style;
