import React from 'react';
import ReactDatePicker from 'react-datepicker';
import moment, { Moment } from 'moment';
import { Field, formValues } from 'redux-form';
import { Input } from 'buildingBlocks';
import { DATE_FORMAT_JS_DATE } from 'utils/dateTime';

type Props = {
  fieldName: string,
  disabled?: boolean,
  change: Function,
};

type PickerProps = {
  input?: {
    onChange: (date: Date) => void,
    value: Date,
  },
};

type PropsWithEndDateAndChange = PickerProps & { endDate: Moment, change: Function };
type PropsWithStartDate = PickerProps & { startDate: Moment };

const StartDatePicker = ({
  input: { onChange, value }, endDate, change,
}: PropsWithEndDateAndChange) => (
  <ReactDatePicker
    onChange={(startDateValue) => {
      const utcClone = moment(startDateValue).utc().toDate();
      onChange(utcClone);

      /*
        automatically adjust the endDate if the user selects a start
        date more than 60 days before the current endDate value
      */
      if (moment(endDate).diff(startDateValue, 'days') > 60) {
        change('endDate', moment(utcClone).add(60, 'd').toDate());
      }
    }}
    dateFormat={DATE_FORMAT_JS_DATE}
    selectsStart
    selected={value}
    startDate={value}
    endDate={endDate}
    minDate={moment.utc().subtract(65, 'day').toDate()}
    maxDate={moment.min(moment(endDate), moment.utc()).toDate()}
    monthsShown={2}
  />
);

const EndDatePicker = ({
  input: { onChange, value }, startDate,
}: PropsWithStartDate) => {
  const startDateClonePlus60 = moment(startDate).add(60, 'd');
  return (
    <ReactDatePicker
      onChange={(endDateValue) => {
        const utcClone = moment(endDateValue).utc().toDate();
        onChange(utcClone);
      }}
      dateFormat={DATE_FORMAT_JS_DATE}
      selectsEnd
      selected={value}
      startDate={startDate}
      endDate={value}
      minDate={moment.max(moment.utc().subtract(65, 'day'), moment(startDate)).toDate()}
      maxDate={moment.min(moment.utc(), startDateClonePlus60).toDate()}
      monthsShown={2}
    />
  );
};

// @ts-ignore child class
const StartDatePickerComponent = formValues('endDate')(StartDatePicker);
// @ts-ignore child class
const EndDatePickerComponent = formValues('startDate')(EndDatePicker);

export const DateTemplate = (component) => (
  ({ fieldName, disabled, change }: Props) => (
    <Field
      id={fieldName}
      name={fieldName}
      component={component}
      disabled={disabled}
      change={change}
    />
  )
);

export const StartDate = DateTemplate(StartDatePickerComponent);
export const EndDate = DateTemplate(EndDatePickerComponent);

/* ------------ PAGE TWO DATE PICKER ------------ */

type PageTwoDatePickerProps = {
  onChange: (date: Date) => void,
  startDate: Moment,
  endDate: Moment,
  selectsStart?: boolean,
  selectsEnd?: boolean,
  selected: Moment,
  minDate: Moment,
  maxDate: Moment,
};

export const PageTwoDatePicker = (
  { onChange, selectsStart, selectsEnd, startDate, endDate, selected, minDate, maxDate }: PageTwoDatePickerProps,
) => (
  <ReactDatePicker
    customInput={<Input />}
    onChange={(date) => {
      const utcClone = moment(date).utc().toDate();
      onChange(utcClone);
    }}
    dateFormat={DATE_FORMAT_JS_DATE}
    className="react-datepicker-grey"
    selectsStart={selectsStart}
    selectsEnd={selectsEnd}
    selected={selected}
    startDate={startDate}
    endDate={endDate}
    minDate={minDate}
    maxDate={maxDate}
    monthsShown={2}
  />
);
