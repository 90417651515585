import _ from 'lodash';
import React, { Component } from 'react';
import { OrderedMap } from 'immutable';
import {
  Button, Grid, Progress, Dropdown, Divider,
} from 'buildingBlocks';
import Toggle from 'components/Toggle';
import { REPORT_TYPES } from 'containers/StrategyAnalytics/components/View/Helios/constants';

type Props = {
  /** Currently selected flights for which reports will be downloaded. */
  selectedItems?: OrderedMap<unknown, unknown>,
  /** Function called when user cancels dialog. */
  onCancel: Function,
  /** Function called when user triggers a helios report download. */
  heliosDownloadReport: Function,
  /** Whether helios report data is currently downloading. */
  downloadingReport: boolean,
  /**
   * Total number of downloaded reports for this strategy. Used to populate a
   * progress bar in a modal dialog from which users may download reports.
   * Relevant only to helios strategies.
   */
  downloadedReport: number,
};

type State = {
  bundle: boolean,
  reportType: string,
};

class HeliosReportModalFooter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      bundle: false,
      reportType: REPORT_TYPES.BUCKETED,
    };
  }

  onChangeOption(data: { value: string }) {
    this.setState({ reportType: data.value });
  }

  download(reportType: string) {
    if (this.props.selectedItems.size !== 0) {
      const [...flights] = this.props.selectedItems.values();
      this.props.heliosDownloadReport({
        strategy: this.props, flights, reportType, bundle: this.state.bundle,
      });
    }
  }

  switchBundle() {
    this.setState((prevState) => ({
      bundle: !prevState.bundle,
    }));
  }

  render() {
    const disabled = this.props.selectedItems.size === 0;
    return (
      <Grid>
        {
        this.props.downloadingReport
          ? (
            <Grid.Row columns={12}>
              <Grid.Column width={12} textAlign="right">
                <Progress
                  value={this.props.downloadedReport}
                  total={this.props.selectedItems.size}
                  color="blue"
                  progress="ratio"
                  active
                />
              </Grid.Column>
            </Grid.Row>
          )
          : (
            <Grid.Row columns={12}>
              <Grid.Column width={12}>
                <Dropdown
                  id="reportType"
                  name="reportType"
                  options={_.map(REPORT_TYPES, (v) => ({ text: _.capitalize(v), value: v }))}
                  onChange={(_event, data: { value: string }) => this.onChangeOption(data)}
                  defaultValue={this.state.reportType}
                  selection
                  fluid
                />
                <Divider hidden />
              </Grid.Column>
              <Grid.Column width={4}>
                {/*
                  // @ts-ignore - extra props */}
                <Toggle
                  checked={this.state.bundle}
                  label="Download as single file"
                  onChange={() => this.switchBundle()}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Button
                  primary
                  disabled={disabled}
                  floated="right"
                  onClick={() => this.download(this.state.reportType)}
                >
                  Download
                </Button>
                <Button floated="right" onClick={() => this.props.onCancel()}>Cancel</Button>
              </Grid.Column>
              <Grid.Column width={12} textAlign="right" style={{ lineHeight: '2em' }}>
                Reports are limited to 100,000 rows of data
              </Grid.Column>
            </Grid.Row>
          )
      }
      </Grid>
    );
  }
}

export default HeliosReportModalFooter;
