import { CSSProperties } from 'react';
import { COPILOT_COLORS } from 'globalStyles';

const { WPP: { grey800 } } = COPILOT_COLORS;

const fullScreenModalStyles: { [key: string]: CSSProperties } = {
  modalHeaderContainerStyle: {
    width: '100%',
    display: 'block',
  },
  modalHeaderStyle: {
    width: '100%',
    display: 'flex',
  },
  modalHeaderTitleStyle: {
    width: '97%',
  },
  modalHeaderIconStyle: {
    width: '2%',
  },
  modalCrossIcon: {
    color: grey800,
  },
  modalBodyStyle: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  modalButtonAlignment: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export default fullScreenModalStyles;
