import React from 'react';
import { Label, SemanticCOLORS } from 'buildingBlocks';
import { Job } from 'containers/Jobs/types';

type Props = {
  extraLabelProps?: {},
  color: SemanticCOLORS,
  job: Job
};

const JobStatusLabel = (props: Props) => (
  <Label tag {...props.extraLabelProps} color={props.color}>
    {props.job.status}
  </Label>
);

export default JobStatusLabel;
