import { CSSProperties } from 'react';

export const msgStyle: CSSProperties = {
  color: 'grey',
  fontWeight: 'normal',
  marginTop: 'initial',
};

export const btnStyle: CSSProperties = {
  borderRadius: '4px',
  fontWeight: 500,
};

export const centerAuto: CSSProperties = { margin: 'auto' };
