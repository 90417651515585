/**
*
* FormSectionHeader
*
*/

import React, { ReactNode } from 'react';
import { WppTypography } from 'buildingBlocks';
import Tooltip from 'containers/Tooltip/';

import formSectionHeaderStyle from './styles';

type Props = {
  /** Tooltip group. */
  group?: string
  /** User-facing title of the FormSectionHeader. */
  title?: string | ReactNode
  /** Tooltip content. */
  help?: string | ReactNode
  /** Tooltip name. */
  tooltipName?: string
  /** Whether to align FormSectionHeader to the left.  */
  leftJustified?: boolean
  /** Style object to apply. */
  headerStyles?: Object
  /** Children to render in between the header and the tooltip */
  children?: ReactNode
  /** whether to style inline */
  shouldStyleHeaderInline?: boolean
  bottom?: number
  top?: number
};

const FormSectionHeader = ({
  group, title, help, tooltipName, leftJustified, headerStyles, children, shouldStyleHeaderInline, bottom, top,
}: Props) => {
  let style = { ...formSectionHeaderStyle.header };
  let divStyle: { [key: string]: string } = { display: 'flex', gap: '4px', justifyContent: leftJustified ? 'left' : '', marginBottom: `${bottom}px`, marginTop: `${top}px` };
  if (leftJustified) {
    style = { ...formSectionHeaderStyle.header, ...formSectionHeaderStyle.leftJustified };
  }
  if (headerStyles) {
    style = { ...style, ...headerStyles };
  }
  if (shouldStyleHeaderInline) {
    style = { ...style, ...formSectionHeaderStyle.inline };
    divStyle = { display: 'inline' };
  }
  return (
    <div style={divStyle}>
      <WppTypography data-testid="header-text" style={style} type="s-strong">{title}</WppTypography>
      {children}
      {help
      && (
      <div className="tooltipIcon" style={formSectionHeaderStyle.icon}>
        <Tooltip content={help} group={group} name={tooltipName || title as string} />
      </div>
      )}
    </div>
  );
};

export default FormSectionHeader;
