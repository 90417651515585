import { assertUnreachable } from 'utils/types';
import { ActionTypes, StatusOptions, Action } from './constants';

type State = {
  status: StatusOptions,
  stratId?: number,
  externalType?: { [key: string]: unknown },
};

export const flightExtIdInitialState = {
  status: StatusOptions.LOADING,
  stratId: null,
  externalType: null,
};

export const flightExtIdReducer = (_state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.STRATEGY_FOUND:
      return { status: StatusOptions.STRATEGY_FOUND, stratId: action.payload.stratId };
    case ActionTypes.NO_STRATEGY_FOUND:
      return { status: StatusOptions.NO_STRATEGY_FOUND };
    case ActionTypes.STRATEGY_FETCH_ERROR:
      return { status: StatusOptions.STRATEGY_FETCH_ERROR };
    case ActionTypes.FLIGHT_EXISTS_BUT_NO_STRATEGY_ATTACHED:
      return {
        status: StatusOptions.FLIGHT_EXISTS_BUT_NO_STRATEGY_ATTACHED,
        externalType: action.payload.externalType,
      };
    default:
      return assertUnreachable(action.type);
  }
};
