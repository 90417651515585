import React, { useState } from 'react';
import { WppButton, WppIconSupportChat } from 'buildingBlocks';
import ChatbotWindow from './components/ChatbotWindow';
import CHATBOT_STYLES from './styles';
import './chatbotOverrides.scss';

const { openChatButton, buttonIcon } = CHATBOT_STYLES;

const Chatbot = () => {
  const [chatOpen, setChatOpen] = useState<boolean>(false);

  return (
    <>
      <WppButton
        className="chatbot-button"
        style={{ ...openChatButton, display: chatOpen ? 'none' : 'block' }}
        onClick={() => setChatOpen(true)}
      >
        <WppIconSupportChat
          style={buttonIcon}
        />
      </WppButton>
      <ChatbotWindow
        chatOpen={chatOpen}
        setChatOpen={setChatOpen}
      />
    </>
  );
};

export default Chatbot;
