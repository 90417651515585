import _ from 'lodash';
import { DSP } from 'constantsBase';
import {
  DataFromEndpoint,
  KPIsByVariantWithVariant,
  KPIs,
  FlightExtIdToAlias,
  VariantToFlightExtId,
  KPIsByVariantWithDate,
  KPIsWithExtFlightId,
  DeviceCodeToText,
  Devices,
  KPIsByDeviceFlight,
  KPIsByFlightDevice as KPIsByFlightDeviceType,
  KPIsWithFlightAndDevice,
  KPIsWithSizeFlight,
  KPIsBySizeFlight as KPIsBySizeFlightType,
  FlightExtIdToVariant,
  AugmentedStatBoxData,
} from './types';

export class ABInsightsData {
  data: DataFromEndpoint;

  variantToFlightExtId: VariantToFlightExtId;

  flightExtIdToVariant: FlightExtIdToVariant;

  flightExtIdToAlias: FlightExtIdToAlias;

  country: string;

  currency: string;

  constructor(
    data: DataFromEndpoint,
    flightLeftExtId: string,
    flightRightExtId: string,
    flightExtIdToAlias: FlightExtIdToAlias,
  ) {
    this.data = data;

    const a = flightLeftExtId;
    const b = flightRightExtId;
    this.variantToFlightExtId = { a, b };
    this.flightExtIdToVariant = {
      [flightLeftExtId]: 'a',
      [flightRightExtId]: 'b',
    };

    this.flightExtIdToAlias = flightExtIdToAlias;

    // @ts-ignore - type issue related to currency being a string
    this.currency = _.get(data, 'metadata.currency', 'USD');
  }

  getAugmentedStatBoxData(): AugmentedStatBoxData {
    return {
      statBoxData: this.data.statBoxData,
      flightExtIdToAlias: this.flightExtIdToAlias,
      variantToFlightExtId: this.variantToFlightExtId,
      flightExtIdToVariant: (_.invert(this.variantToFlightExtId) as FlightExtIdToVariant),
    };
  }

  getKpisByVariantWithVariant(): KPIsByVariantWithVariant {
    const { KPIsByFlight, metadata } = this.data;
    const { a, b } = this.variantToFlightExtId;
    return {
      a: { ...KPIsByFlight[a], label: this.flightExtIdToAlias[a], variant: 'a', isCopilot: metadata[a].isCopilot },
      b: { ...KPIsByFlight[b], label: this.flightExtIdToAlias[b], variant: 'b', isCopilot: metadata[b].isCopilot },
    };
  }

  getKpisByVariantWithDate(): KPIsByVariantWithDate {
    const { KPIsByFlightDate, KPIsByFlightDateCumulative } = this.data;
    const { a, b } = this.variantToFlightExtId;
    return {
      daily: {
        a: _.map(KPIsByFlightDate[a], (v, k) => ({ ...v, date: k })),
        b: _.map(KPIsByFlightDate[b], (v, k) => ({ ...v, date: k })),
      },
      cumulative: {
        a: _.map(KPIsByFlightDateCumulative[a], (v, k) => ({ ...v, date: k })),
        b: _.map(KPIsByFlightDateCumulative[b], (v, k) => ({ ...v, date: k })),
      },
    };
  }

  getKpisByDomain(): { [domain: string]: Array<KPIsWithExtFlightId> } {
    const { KPIsByFlightDomain } = this.data;
    const unnestedDomainData = _.flatMap(
      KPIsByFlightDomain,
      (dataByDomain, flightExtId) => _.map(dataByDomain, (d, domain) => ({ ...d, domain, flightExtId })),
    );
    return _.groupBy(unnestedDomainData, 'domain');
  }

  static getFlatData(deviceCodeToText: DeviceCodeToText, kpisByFlightDevice: KPIsByFlightDeviceType): Array<KPIsWithFlightAndDevice> {
    return _.flatMap(
      kpisByFlightDevice,
      (deviceData, flightExtId) => _.map(deviceData, (KPIData: KPIs, device) => ({
        ...KPIData,
        flightExtId,
        device: deviceCodeToText[device],
      })),
    );
  }

  static getFlatCreativeSizeFlightData(KPIsBySizeFlight: KPIsBySizeFlightType): Array<KPIsWithSizeFlight> {
    return _.flatMap(
      KPIsBySizeFlight,
      (deviceData, dimensions) => _.map(deviceData, (KPIData: KPIs, flightExtId: string) => (
        {
          ...KPIData,
          flightExtId,
          dimensions,
        })),
    );
  }

  static getKPIsBySizeFlightWithSizeFlight(KPIsBySizeFlight: KPIsBySizeFlightType): { [size: string]: { [flight: string]: KPIsWithSizeFlight } } {
    return _.mapValues(
      KPIsBySizeFlight,
      (deviceData, dimensions) => _.mapValues(deviceData, (KPIData: KPIs, flightExtId: string) => ({
        ...KPIData,
        flightExtId,
        dimensions,
      })),
    );
  }

  getKPIsByDeviceFlight(deviceCodeToText: DeviceCodeToText): KPIsByDeviceFlight {
    const { KPIsByFlightDevice } = this.data;
    const unnestedDeviceFlightData = ABInsightsData.getFlatData(deviceCodeToText, KPIsByFlightDevice);

    return _.chain(unnestedDeviceFlightData)
      .groupBy('device')
      .mapValues((values) => _.chain(values)
        .groupBy('flightExtId')
        .mapValues((v) => _.first(v))
        .value())
      .value();
  }
}

export class XNDRABInsightsData extends ABInsightsData {
  deviceCodeToText = {
    phone: Devices.PHONE,
    tablet: Devices.TABLET,
    'pc & other devices': Devices.LAPTOP_OR_PC,
  };
}

export class TTDABInsightsData extends ABInsightsData {
  deviceCodeToText = {
    Mobile: Devices.PHONE,
    Tablet: Devices.TABLET,
    PC: Devices.LAPTOP_OR_PC,
    /* For future visualizations:
      TTD Devices:
      1: Other
      5: Roku
      6: ConnectedTV
      7: OutOfHome
    */
  };
}

export class DV360ABInsightsData extends ABInsightsData {
  deviceCodeToText = {
    Desktop: Devices.LAPTOP_OR_PC,
    Tablet: Devices.TABLET,
    'Smart Phone': Devices.PHONE,
    'Connected TV': Devices.CTV,
  };
}

export const getABInsightsDataAdapter = (
  dsp: number,
  data: DataFromEndpoint,
  flightLeftExtId: string,
  flightRightExtId: string,
  flightExtIdToAlias: FlightExtIdToAlias,
): ABInsightsDataDSPSubtype => {
  switch (dsp) {
    case (DSP.APN.id):
      return new XNDRABInsightsData(data, flightLeftExtId, flightRightExtId, flightExtIdToAlias);
    case (DSP.TTD.id):
      return new TTDABInsightsData(data, flightLeftExtId, flightRightExtId, flightExtIdToAlias);
    case (DSP.DBM.id):
      return new DV360ABInsightsData(data, flightLeftExtId, flightRightExtId, flightExtIdToAlias);
    default:
      console.log(`No adapter set up to handle ${dsp}`);
      // @ts-ignore
      return {};
  }
};

export type ABInsightsDataDSPSubtype = XNDRABInsightsData | TTDABInsightsData | DV360ABInsightsData;
