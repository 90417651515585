import { Moment } from 'moment';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import { Flight, Advertiser, Member, GoalType } from 'utils/types';
import { GeoJsonDatum } from 'charts/types';
import { VizId } from 'charts/ABReportViz/constants';

export type FormValues = {
  currentTab: VizId
  member: Member
  advertiser: Advertiser
  flightType: number
  flightLeft: Flight
  flightLeftAlias: string
  flightRight: Flight
  flightRightAlias: string
  startDate: Moment
  endDate: Moment
  title: string
  KPIs: Array<Metric>
};

export type QueryStringValues = {
  [K in keyof FormValues]: string
};

export type ErrorDetail = {
  flightFetchError?: string
  dataFetchError?: string
  endDateError?: string
};

export type KPIOptions = Array<Metric & GoalType>;

export type KPIs = {
  revenue: number
  clicks: number,
  conversions: number,
  viewableImpressions: number
  viewMeasuredImps: number
  advRevenue: number
  impressions: number
  advCost: number
  cost: number
  impressionValue: number
  ivrImps: number
  ivrMeasured: number
  engagementScore: number
  conversionRate: number
  cpa: number
  vcpm: number
  cpc: number,
  ctr: number
  conversionRevenue: number,
  cpm: number
};

export type KPIsWithDate = KPIs & {
  date: string
};

export type KPIsByFlightDomain = {
  [domain: string]: {
    [flightExtId: string]: KPIs,
  }
};

export type KPIsByFlightDevice = {
  [flightExtId: string]: {
    [device: string]: KPIs
  }
};

export type KPIsBySizeFlight = {
  [creativeSize: string]: {
    [flightExtId: string]: KPIs
  }
};

export type KPIsWithSizeFlight = KPIsWithExtFlightId & { dimensions: string };

export type KPIsByDeviceFlight = {
  [device: string]: {
    [flightExtId: string]: KPIs
  }
};

export type GeoKPIs = KPIs & { geoRegion: string, displayRegion: string };
export type GeoInsightsData = { [key: string]: Array<GeoKPIs> } & { geoJSON: Array<GeoJsonDatum> };

type OverviewStatBoxDataRaw = {
  diffRatio: KPIs
  overallPerformanceWinner: any
  projectedSuccessMetricsForLoser: KPIs
};
export type OverviewStatBoxDataTransformed = {
  winnerVariant: 'a' | 'b'
  winnerAlias: string
  loserVariant: 'a' | 'b'
  loserAlias: string
  diffRatio: number
  comparisonText: 'lower' | 'higher'
  primaryKPIText: string
  primaryKPIValue: string
  projectedSuccessMetrics: number
};

type BaseStatBoxDataRaw = {
  countBetterPerformingFeatures: KPIs
  majorityDeliveryShare: {
    impressions: number
    advRevenue: number
  }
  majorityDeliveryWinners: KPIs
  topPerformingFlight: KPIs
  totalFeatureCount: number
};
export type BaseStatBoxDataTransformed = {
  totalFeatureCount: number
  countFeaturesAPerformedBetter: number
  countFeaturesBPerformedBetter: number
  countFeaturesWinnerPerformedBetter: number
  topPerformingVariant: 'a' | 'b'
  topPerformingAlias: string
  bottomPerformingVariant: 'a' | 'b'
  bottomPerformingAlias: string
  comparisonText: 'lower' | 'higher',
  kpiText: string
  majorityDeliveryShareFeatureCount: number
  majorityDeliveryShare: number
  winnerVariantMajorityFeatures: 'a' | 'b'
  loserVariantMajorityFeatures: 'a' | 'b'
  budgetTypeText: string
};

export type DomainStatBoxDataRaw = BaseStatBoxDataRaw & {
  majorityDeliveringFeatures: {
    advRevenue: [{ domain: string, advRevenue: number }],
    impressions: [{ domain: string, impressions: number }],
  }
};
export type DomainStatBoxDataTransformed = BaseStatBoxDataTransformed;

export type GeoStatBoxDataRaw = BaseStatBoxDataRaw & {
  majorityDeliveringFeatures: {
    advRevenue: [{ displayRegion: string, advRevenue: number }],
    impressions: [{ displayRegion: string, impressions: number }],
  }
};
export type GeoStatBoxDataTransformed = BaseStatBoxDataTransformed;

export type DeviceStatBoxDataRaw = BaseStatBoxDataRaw & {
  majorityDeliveringFeatures: {
    advRevenue: [{ deviceType: string, advRevenue: number }],
    impressions: [{ deviceType: string, impressions: number }],
  }
};
export type DeviceStatBoxDataTransformed = BaseStatBoxDataTransformed & { highestDeliveringFeatures: string };

export type CreativeSizeStatBoxDataRaw = BaseStatBoxDataRaw & {
  majorityDeliveringFeatures: {
    advRevenue: [{ creativeSize: string, advRevenue: number }],
    impressions: [{ creativeSize: string, impressions: number }],
  }
};
export type CreativeSizeStatBoxDataTransformed = BaseStatBoxDataTransformed & { highestDeliveringFeatures: string };

export type StatBoxData = {
  Overview: OverviewStatBoxDataRaw
  DomainData: DomainStatBoxDataRaw
  GeoData: GeoStatBoxDataRaw
  DeviceData: DeviceStatBoxDataRaw
  CreativeSizeData: CreativeSizeStatBoxDataRaw
};

export type AugmentedStatBoxData = {
  statBoxData: StatBoxData
  variantToFlightExtId: VariantToFlightExtId
  flightExtIdToVariant: FlightExtIdToVariant
  flightExtIdToAlias: FlightExtIdToAlias
};

export type DataFromEndpoint = {
  GeoInsightsData: GeoInsightsData,
  KPIsByFlight: {
    [flightExtId: string]: KPIs
  }
  KPIsByFlightDate: {
    [flightExtId: string]: {
      [date: string]: KPIs
    }
  }
  KPIsByFlightDateCumulative: {
    [flightExtId: string]: {
      [date: string]: KPIs
    }
  }
  KPIsByFlightDomain: KPIsByFlightDomain
  KPIsByFlightDevice: KPIsByFlightDevice
  KPIsBySizeFlight: KPIsBySizeFlight
  KPIsByFlightDevicePlacementSize: {
    [flightExtId: string]: {
      [devicePlacementSize: string]: KPIs
    }
  }
  KPIsByDevicePlacementSize: {
    [devicePlacementSize: string]: KPIs
  }
  metadata: {
    [flightExtId: string]: {
      name: string
      isCopilot: boolean
    }
  }
  statBoxData: StatBoxData
};

export type KPIsWithExtFlightId = KPIs & {
  flightExtId: string
};

export type KPIsWithGeo = KPIs & {
  countryId: string
  geoId: string
};

export type KPIsWithFlightGeo = KPIsWithGeo & {
  flightExtId: string
};

export type KPIsWithDevice = KPIs & {
  device: string
};

export type KPIsWithFlightAndDevice = KPIsWithExtFlightId & {
  device: string
};

export type KPIsWithFlightByDevice = {
  [device: string]: Array<KPIsWithFlightAndDevice>
};

export type VariantToFlightExtId = {
  a: string // flightExtId
  b: string // flightExtId
};

export type FlightExtIdToVariant = {
  [flightExtId: string]: 'a' | 'b'
};

export type FlightExtIdToAlias = {
  [flightExtId: string]: string
};

export type KPIsByVariantWithVariant = {
  a: KPIs & { variant: 'a', label: string, isCopilot: boolean } // flight name
  b: KPIs & { variant: 'b', label: string, isCopilot: boolean }
};

export type KPIsByVariantWithDate = {
  daily: {
    a: Array<KPIsWithDate>
    b: Array<KPIsWithDate>
  }
  cumulative: {
    a: Array<KPIsWithDate>
    b: Array<KPIsWithDate>
  }
};

export enum Devices {
  PHONE = 'Mobile',
  TABLET = 'Tablet',
  LAPTOP_OR_PC = 'Desktop',
  CTV = 'CTV',
}

export enum Placements {
  ABOVE = 'above',
  BELOW = 'below',
  UNKNOWN = 'unknown',
}

export type DeviceCodeToText = { [key in Devices]: Devices } | { [key: number]: Devices };

export type PlacementCodeToText = { [key in Placements]: Placements } | { [key: number]: Placements };
