import React from 'react';
import { WppActionButton, WppIconExternalLink, WppIconInfo, WppTooltip, WppTypography } from 'buildingBlocks';
import {
  popupHeaderStyle, popupLinkColumnStyle, popupTitleStyle, NOTIFICATION_STYLES } from '../style';
import { preventBubbleUp, truncateMessageContent } from '../utils';

const { strategyAction, strategyInfo } = NOTIFICATION_STYLES;

type ExternalLinkProps = {
  header: string
  text: string
  link?: string
};

const ExternalLink: React.FC<ExternalLinkProps> = ({ header, link, text }: ExternalLinkProps): React.ReactElement => (
  <div style={popupTitleStyle}>
    <WppTypography tag="h4" type="xs-strong" style={popupHeaderStyle}>{header}</WppTypography>
    <WppTypography tag="p" type="xs-body" style={popupLinkColumnStyle}>
      {link ? <a onClick={preventBubbleUp} href={link} target="_blank" rel="noopener noreferrer">{text}</a> : text}
    </WppTypography>
  </div>
);

type StrategyPopupProps = {
  strategyId: string
  strategyLinkText: string
  strategyActive: boolean
  actionLink: string
  member: string
  liLink: string
  ioLink: string
  lineItem: string
  insertionOrder: string
};

const StrategyPopup: React.FC<StrategyPopupProps> = ({
  strategyId,
  strategyLinkText,
  strategyActive,
  actionLink,
  member,
  liLink,
  ioLink,
  lineItem,
  insertionOrder,
}: StrategyPopupProps): React.ReactElement => {
  const stratLink = `/strategies/${strategyId}`;
  const liText = truncateMessageContent(lineItem);
  const ioText = truncateMessageContent(insertionOrder);

  return (
    <div style={strategyAction}>
      {strategyId && (
        <WppTooltip theme="light" config={{ placement: 'bottom', allowHTML: true }}>
          <div style={strategyInfo}>
            <WppTypography type="xs-strong" tag="h5">Strategy info</WppTypography>
            <WppIconInfo size="s" color="var(--wpp-grey-color-800)" />
          </div>
          <div slot="tooltip-content">
            <div style={popupTitleStyle}>
              <WppTypography tag="h4" type="xs-strong" style={popupHeaderStyle}>Strategy</WppTypography>
              <span style={popupLinkColumnStyle}>
                <WppTypography tag="p" type="xs-body">
                  {strategyActive ? (
                    <a href={stratLink} onClick={preventBubbleUp} target="_blank" rel="noopener noreferrer">
                      {strategyLinkText}
                    </a>
                  ) : strategyLinkText}
                </WppTypography>
              </span>
            </div>
            {member && (
              <ExternalLink header="Member" text={member} />
            )}
            {insertionOrder && (
              <ExternalLink header="Insertion Order" text={ioText} link={ioLink} />
            )}
            {lineItem && (
              <ExternalLink header="Line Item" text={liText} link={liLink} />
            )}
          </div>
        </WppTooltip>
      )}
      {actionLink && (
        <a
          onClick={preventBubbleUp}
          href={actionLink}
          className="notif-dropdown-action-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <WppActionButton variant="secondary">
            Take Action
            <WppIconExternalLink slot="icon-end" />
          </WppActionButton>
        </a>
      )}
    </div>
  );
};

export default StrategyPopup;
