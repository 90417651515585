import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import parse from 'html-react-parser';
import { WppTypography, WppIconChevron, WppInlineMessage } from 'buildingBlocks';
import { FlightTypeLowerCase } from 'constantsBase';
import { NUMBER_DATE_FORMAT } from 'utils/dateTime';
import { circleCountTextStyle, NOTIFICATION_STYLES } from '../style';
import { HIGHER_ORDER_FLIGHT_TYPES } from '../constants';
import StrategyPopup from './StrategyPopup';
import { getMessageAction, getNotificationAge, truncateMessageContent } from '../utils';
import { NotificationMsgObject } from '../types';

const { notificationItem, notificationItemHeader, notificationItemHeading, notificationAgeStyle, notificationItemDescContainer, notificationItemDescAction } = NOTIFICATION_STYLES;

type NotificationItemProps = {
  notification: NotificationMsgObject
  onClick: (e: React.MouseEvent<HTMLDivElement>, selectedNotification: NotificationMsgObject) => void
  selectedMsg: boolean
};

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onClick,
  selectedMsg,
}: NotificationItemProps): React.ReactElement => {
  const {
    readAt, createdAt, id, subject, strategyId,
    strategyActive, strategyName, member, count, repeatedSince,
  } = notification;
  const content = _.get(notification, 'content', '');
  const isNewMsg = _.isNil(readAt);
  const notificationAge = getNotificationAge(createdAt);
  const metadata = JSON.parse(notification.metadata ?? '{}');

  let insertionOrder;
  let lineItem;
  let insertionOrderLink;
  let lineItemLink;
  if (strategyId) {
    const summary = _.get(metadata, 'summary', []);
    _.forEach(summary, ((info) => {
      const title = _.lowerCase(_.get(info, 'title'));
      if (HIGHER_ORDER_FLIGHT_TYPES.has(title)) {
        insertionOrder = _.get(info, 'displayValue');
        insertionOrderLink = _.get(info, 'link');
      }
      if (title === FlightTypeLowerCase.lineItem) {
        lineItem = _.get(info, 'displayValue');
        lineItemLink = _.get(info, 'link');
      }
    }));
  }

  const repeated = count > 1;
  const strategyLinkText = truncateMessageContent(strategyName);
  const memberText = truncateMessageContent(member);
  const actions = _.get(metadata, 'actions', []);
  const actionLink = getMessageAction(actions)?.link;

  return (
    <div
      role="presentation"
      style={{ ...notificationItem, cursor: selectedMsg ? 'auto' : 'pointer' }}
      key={id}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => onClick(event, notification)}
      className={`notification-item ${isNewMsg ? 'new' : 'read'} ${selectedMsg ? 'expanded' : ''}`}
    >
      <div style={notificationItemHeader}>
        <div style={notificationItemHeading}>
          <WppTypography type="s-strong" tag="h6" className={`notificationItemSubject ${!isNewMsg && 'read'}  ${selectedMsg ? '' : 'notificationItemSubjectLineClamp'}`}>
            {subject}
          </WppTypography>
          {repeated && (
            <WppTypography tag="p" type="xs-strong" style={circleCountTextStyle}>{count}</WppTypography>
          )}
        </div>
        <WppTypography type="2xs-strong" tag="span" style={notificationAgeStyle}>
          {notificationAge}
        </WppTypography>
      </div>
      <div style={notificationItemDescContainer}>
        <WppTypography type="s-body" tag="p" className={`notificationItemDesc ${!isNewMsg && 'read'} ${selectedMsg ? '' : 'notificationItemDescLineClamp'}`}>
          {parse(_.replace(content, '<a', '<a target="_blank"'))}
        </WppTypography>
        {
          !selectedMsg && (
            <div style={notificationItemDescAction}>
              <WppTypography type="xs-body" tag="span">Read More</WppTypography>
              <WppIconChevron dir="right" size="s" />
            </div>
          )
        }
      </div>
      {repeated && selectedMsg && (
        <WppInlineMessage size="s" message={`This notification has repeated ${count} times since ${moment(repeatedSince).format(NUMBER_DATE_FORMAT)}`} type="warning" />
      )}
      {strategyId && (
        <StrategyPopup
          strategyId={strategyId}
          strategyLinkText={strategyLinkText}
          strategyActive={strategyActive}
          actionLink={actionLink}
          member={memberText}
          liLink={lineItemLink}
          ioLink={insertionOrderLink}
          lineItem={lineItem}
          insertionOrder={insertionOrder}
        />
      )}
    </div>
  );
};

export default NotificationItem;
