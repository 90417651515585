import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form';
import { WppRadio } from 'buildingBlocks';
import userGroupPageStyles from 'containers/UserGroups/style';

const { setUserGroupsStyle } = userGroupPageStyles;

type Props = {
  selectedValue: number
  afterChange: Function
  flightTypeOptions: Array<unknown>
  input?: {
    onChange: Function
  }
  disabled?: boolean
};

const FlightType = ({
  selectedValue, flightTypeOptions, input: { onChange }, afterChange, disabled,
}: Props) => {
  const handleFlightFieldRadio = (value) => {
    onChange(value);
    afterChange(value);
  };

  return _.map(flightTypeOptions, ({ id, displayName }: { id: number, displayName: string }) => (
    <WppRadio
      key={id}
      value={id}
      className="set-users-group"
      style={setUserGroupsStyle.radionBtnStyle}
      labelConfig={{ text: `${displayName}` }}
      checked={selectedValue === id}
      onWppChange={({ detail: { value } }) => {
        handleFlightFieldRadio(value);
      }}
      disabled={disabled}
    />
  ));
};

const FlightTypeField = ({ selectedValue, flightTypeOptions, afterChange, disabled }: Omit<Props, 'input'>) => (
  <Field
    id="flightType"
    name="flightType"
    component={FlightType}
    selectedValue={selectedValue}
    afterChange={afterChange}
    disabled={disabled}
    flightTypeOptions={flightTypeOptions}
  />
);

export default FlightTypeField;
