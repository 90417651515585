import {
  NOTIFICATIONS_INITIAL_PAGE_SKIP, NOTIFICATIONS_STARTING_PAGE,
  ActiveTab, NOTIFICATIONS_PAGE_CHANGE, NOTIFICATIONS_SWITCH_CATEGORY,
  NOTIFICATIONS_CLICK_NOTIFICATION, NOTIFICATIONS_ON_BELL_CLICK,
} from './constants';
import { NotificationListState } from './types';

export const INITIAL_STATE = {
  open: false,
  id: null,
  startingPage: NOTIFICATIONS_STARTING_PAGE,
  skip: NOTIFICATIONS_INITIAL_PAGE_SKIP,
  activeTab: ActiveTab.actions,
};

const reducer = (state: NotificationListState = INITIAL_STATE, action): NotificationListState => {
  switch (action.type) {
    case NOTIFICATIONS_ON_BELL_CLICK:
      return {
        ...state,
        open: action.payload,
      };
    case NOTIFICATIONS_CLICK_NOTIFICATION:
      return {
        ...state,
        id: action.payload,
      };
    case NOTIFICATIONS_PAGE_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case NOTIFICATIONS_SWITCH_CATEGORY:
      return {
        ...INITIAL_STATE,
        ...action.payload,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
