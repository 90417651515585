import { COPILOT_LAYOUT } from 'globalStyles';

export const snackBar = {
  position: 'fixed',
  bottom: '0',
  zIndex: 10,
  textAlign: 'center',
  width: '100%',
  boxShadow: '0px 0px 10px 0px',
  padding: '10px',
};

export const deleteBtn = {
  marginRight: '30px',
  borderRadius: COPILOT_LAYOUT.SPACING[4],
  textTransform: 'none',
};
