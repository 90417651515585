import { Action, ActionTypes } from './constants';

export function strategyFound(stratId: number): Action {
  return {
    type: ActionTypes.STRATEGY_FOUND,
    payload: { stratId },
  };
}

export function noStrategyFound(): Action {
  return {
    type: ActionTypes.NO_STRATEGY_FOUND,
  };
}

export function strategyFetchError(): Action {
  return {
    type: ActionTypes.STRATEGY_FETCH_ERROR,
  };
}

export function flightExistsButNoStrategyAttached(externalType: { [key: string]: unknown }): Action {
  return {
    type: ActionTypes.FLIGHT_EXISTS_BUT_NO_STRATEGY_ATTACHED,
    payload: { externalType },
  };
}
