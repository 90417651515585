import React, { MouseEventHandler } from 'react';
import _ from 'lodash';
import { ELEMENT_ID } from 'cssTagConstants';
import { WppIconNotification, WppIconNotificationNew } from 'buildingBlocks';
import { notificationTriggerStyle } from '../style';

type NotificationTriggerProps = {
  onClick: MouseEventHandler<HTMLDivElement>
  notificationsActionsUnreadCount: number
};

const NotificationTrigger: React.FC<NotificationTriggerProps> = ({ onClick, notificationsActionsUnreadCount }: NotificationTriggerProps): React.ReactElement => (
  <div id={ELEMENT_ID.navBar.notificationBellContainer} style={notificationTriggerStyle} role="presentation" className="text" onClick={onClick}>
    {_.gte(notificationsActionsUnreadCount, 1) ? <WppIconNotificationNew /> : <WppIconNotification />}
  </div>
);

export default NotificationTrigger;
