import React, { CSSProperties } from 'react';
import PageForbidden from 'containers/403';
import PermissionWrapper, {
  PermissionWrapperProps,
} from 'components/PermissionWrapper/PermissionWrapper';

import WppPageTemplate, { PageTemplateProps } from './WppPageTemplate';

type Props = {
  /**
   * Forbidden page customize props
   * if button set to false, it will not show it.
   */
  unauthorizedProps?: {
    message?: string
    button?:
    | {
      url: string
      content: string
    }
    | boolean
  }
  subNavStyle?: CSSProperties
  withoutLayout?: boolean
};

const PermissionPageTemplate = ({
  member,
  permissions,
  allPermissions,
  unauthorized,
  unauthorizedProps,
  ...props
}: PermissionWrapperProps & PageTemplateProps & Props) => (
  <PermissionWrapper
    member={member}
    permissions={permissions}
    allPermissions={allPermissions}
    unauthorizedComponent={<PageForbidden {...unauthorizedProps} />}
    unauthorized={unauthorized}
  >
    <WppPageTemplate {...props} />
  </PermissionWrapper>
);

export default PermissionPageTemplate;
