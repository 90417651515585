import _ from 'lodash';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { S3 } from 'utils/copilotAPI';
import { isS3Url } from 'utils/functionHelpers';
import {
  TASK_FETCH_REQUEST_RESPONSE_DETAILS_FAILED,
  TASK_FETCH_REQUEST_RESPONSE_DETAILS_COMPLETED, TASK_FETCH_REQUEST_RESPONSE_DETAILS,
} from './constants';

function* getTaskDetailsSaga({ payload }) {
  try {
    const data = isS3Url(_.toString(payload.content))
      ? yield call(() => S3.modelFieldRequest(payload))
      : { response: JSON.stringify(payload.content) };

    yield put({
      type: TASK_FETCH_REQUEST_RESPONSE_DETAILS_COMPLETED,
      payload: data.response,
    });
  } catch (error) {
    yield put({ type: TASK_FETCH_REQUEST_RESPONSE_DETAILS_FAILED, error });
  }
}

export const sagas = [
  // @ts-ignore redux-saga
  takeEvery(TASK_FETCH_REQUEST_RESPONSE_DETAILS, getTaskDetailsSaga),
];

export function* taskDetailSagas() {
  yield all([
    ...sagas,
  ]);
}
