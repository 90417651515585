import _ from 'lodash';
import React, { ReactNode } from 'react';
import { WppTypography, WppActionButton } from 'buildingBlocks';
import styles from './style';

type FlightDetailsPopUpProps = {
  activeStatus?: { isInactive: boolean }
  header: string
  messages: Array<ReactNode | string>
};

const FlightDetailsPopUp = ({
  activeStatus, header, messages,
}: FlightDetailsPopUpProps) => {
  const isInactive = _.get(activeStatus, 'isInactive');
  return (
    <div style={styles.popUpMessage}>
      {isInactive ? _.get(activeStatus, 'message') : <WppTypography type="m-strong">{header}</WppTypography>}
      {_.map(messages, (msg, idx: number) => (
        <WppActionButton data-testid="flight-detail-popup" key={idx}>
          <WppTypography type="s-body" style={{ textDecoration: 'underline' }}>{msg}</WppTypography>
        </WppActionButton>
      ))}
    </div>
  );
};

export default FlightDetailsPopUp;
