import { TextareaInputChangeEventDetail } from '@wppopen/components-library';
import { WppTextareaInputCustomEvent } from '@wppopen/components-library/dist/types/components';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { WppTextareaInput, WppActionButton, WppIconSend, WppIconRefresh, WppTypography } from 'buildingBlocks';
import { HandleSubmitType, MAX_CHAR_COUNT } from '../constants';
import CHATBOT_STYLES from '../styles';

const { chatInputContainer, chatInputFooter, charCountContainer, sendButton, charCountText } = CHATBOT_STYLES;

type ChatInputProps = {
  lastUserMessage: string
  input: string
  handleInputChange: (e: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => void
  loading: boolean
  error: boolean
  handleSubmit: HandleSubmitType
};

const ChatInput = (props: ChatInputProps) => {
  const { lastUserMessage, input, handleInputChange, loading, error, handleSubmit } = props;
  const [charCount, setCharCount] = useState<number>(_.size(input));
  const disabled = loading || error || charCount > MAX_CHAR_COUNT;
  const inputRef = useRef<HTMLWppTextareaInputElement>(null);

  useEffect(() => {
    setCharCount(_.size(input));
  }, [input]);

  useEffect(() => {
    // reset the focus back to the input element whenever a new message is received
    // need to use setTimeout because focus returns to DOM body concurrently
    const focusTimeout = setTimeout(() => {
      inputRef.current?.setFocus();
    }, 10);

    // clear timeout when chat input unmounts or loading changes
    return () => clearTimeout(focusTimeout);
  }, [inputRef, loading]);

  const onEnterKeyDown = (e: React.KeyboardEvent<HTMLWppTextareaInputElement>) => {
    if (e.key === 'Enter') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      _.size(input) ? handleSubmit(e) : e.preventDefault();
    }
  };

  return (
    <div style={chatInputContainer}>
      <WppTextareaInput
        className="chatbot-input"
        placeholder="Ask a question"
        name="chatInput"
        rows={4}
        value={input}
        onWppChange={handleInputChange}
        onKeyDown={onEnterKeyDown}
        disabled={disabled}
        ref={inputRef}
        required
        autoFocus
      />
      <div style={chatInputFooter}>
        <WppActionButton
          variant="primary"
          onClick={(e) => handleSubmit(e, lastUserMessage)}
          disabled={disabled || !lastUserMessage}
        >
          <WppIconRefresh />
        </WppActionButton>
        <div style={charCountContainer}>
          <WppTypography style={charCountText} type="xs-body" tag="span">
            {`${charCount}/${MAX_CHAR_COUNT}`}
          </WppTypography>
          <WppActionButton
            variant="primary"
            onClick={handleSubmit}
            disabled={disabled || _.isEmpty(input)}
          >
            <WppIconSend style={sendButton} />
          </WppActionButton>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
