import { CSSProperties } from 'react';

export const dropzoneStyle: { [key: string]: CSSProperties } = {
  container: {
    border: '1px dashed grey',
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 4,
    textAlign: 'center',
    transition: 'opacity 0.25s',
    backfaceVisibility: 'hidden',
    borderRadius: '10px',
  },
  headerText: {
    position: 'relative',
    top: '43%',
    transform: 'translateY(-43%)',
  },
  subtitleText: {
    position: 'relative',
    top: '40%',
    transform: 'translateY(-40%)',
    fontWeight: 100,
    color: '#999',
  },
  browseText: {
    pointerEvents: 'all',
    cursor: 'pointer',
    color: 'var(--wpp-primary-color-500)',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'inter',
    textDecoration: 'underline',
  },
  dropZoneUpload: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '45%',
    position: 'relative',
  },
};

export default dropzoneStyle;
