import _ from 'lodash';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Button, Icon } from 'buildingBlocks';
import PageTemplate from 'components/PageTemplate';
import { msgStyle, centerAuto, btnStyle } from './styles';

type Props = {
  button?: boolean | { url: string, content: string },
};

const PageNotFound = ({ button }: Props) => {
  const location = useLocation();
  return (
    <PageTemplate
      title="Page not found"
      name="Page not found"
    >
      <Container
        fluid
        textAlign="center"
      >
        <div style={centerAuto}>
          <Icon
            size="massive"
            name="plane"
            flipped="vertically"
            circular
            inverted
            color="red"
          />
          <h1>- 404 -</h1>
          <h4 style={msgStyle}>
            There is nothing to see at {location.pathname}
          </h4>
          {button && (
            <Link to={_.get(button, 'url', '/')}>
              <Button
                style={btnStyle}
                className="primary hover-link"
                content={_.get(button, 'content', 'Go to listing page')}
              />
            </Link>
          )}
        </div>
      </Container>
    </PageTemplate>
  );
};

export default PageNotFound;
