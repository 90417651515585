/**
*
* OptionWithIcon
*
*/

import React, { ReactNode } from 'react';
import _ from 'lodash';
import DspIcon from 'components/DspIcon';
import { Flight, Member } from 'utils/types';
import { EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL } from 'containers/StrategyWizard/steps/AttachFlights/constants';

type Props = {
  text: string
  icon: ReactNode
  // eslint-disable-next-line react/no-unused-prop-types
  externalId?: string
};

type Option = {
  displayName: string
  dsp: number | { id: number }
  externalId?: string
};

const OptionWithIcon = ({ text, icon, externalId }: Props) => (
  <span id={externalId}>
    {icon
      && (
        <span style={{ marginRight: '10px' }}>
          {icon}
        </span>
      )}
    {text}
  </span>
);

export const getStrategyOptions = (objects: Array<{ text:string, value:string }>) => _.map(objects, (obj) => ({
  ...obj,
  content: <span id={obj.value}>{obj.text}</span>,
}));

export const WppOptionWithIcon = ({ text, icon }: Props) => (
  <>
    {
      icon && <div slot="right">{icon}</div>
    }
    <div slot="label">{text}</div>
  </>
);

/**
 * get Options with Icon in a format to be used with WPP List item.
 */
export const getWppOptionsWithDspIcon = (objects: Array<Option>) => _.map(objects, (obj) => ({
  ...obj,
  content: (
    <WppOptionWithIcon
      text={obj.displayName}
      icon={<DspIcon dspId={_.isNumber(obj.dsp) ? obj.dsp : obj.dsp.id} />}
    />
  ),
}));

/**
 * get Options with Icon in a format to be used with semantic dropdowns.
 */
export const getOptionsWithDspIcon = (objects: Array<Option>) => _.map(objects, (obj) => ({
  ...obj,
  content: <OptionWithIcon
    text={obj.displayName}
    icon={<DspIcon dspId={_.isNumber(obj.dsp) ? obj.dsp : obj.dsp.id} />}
    // externalId prop is not needed in OptionWithIcon. However, its needed here for search by externalId functionality
    // see 'searchByNameOrExtId' function in 'semanticUiSearch.ts'
    externalId={_.get(obj, 'externalId')}
  />,
}));

export const getMemberOptionsWithDspIcon = (members: Array<Member>) => _.map(members, (mem) => ({
  ...mem,
  content: <OptionWithIcon
    text={`[${mem.externalId}] ${mem.displayName}`}
    icon={<DspIcon dspId={_.isNumber(mem.dsp) ? mem.dsp : mem.dsp.id} />}
    externalId={_.get(mem, 'externalId')}
  />,
}));

export const getFlightOptionsWithDspIcon = (flightCandidates: Array<Flight>) => _.map(flightCandidates, (fc) => ({
  ...fc,
  content: <OptionWithIcon
    text={`[${fc.externalId}] - ${fc.name}`}
    icon={<DspIcon dspId={EXTERNAL_TYPE_TO_DSP_AND_DEFAULT_OPT_LEVEL[fc.externalType].dsp} iconStyles={{ width: 64 }} />}
    externalId={_.get(fc, 'externalId')}
  />,
}));

export default OptionWithIcon;
