import React, { ReactNode } from 'react';
import { Menu, Icon, SemanticICONS } from 'buildingBlocks';
import { MenuItemClickHandler } from 'utils/types';

type Props = {
  /** Function called when item is clicked. */
  onClick?: MenuItemClickHandler,
  /** Element or string to render as the content of this item. */
  element: ReactNode,
  /** Optional icon to display before `element`. */
  icon?: SemanticICONS,
  /** Zero-based index of the active item. */
  activeItem?: number,
  /** Size to render the item. */
  size?: string,
  /** Additional CSS classes for the item. */
  className?: string,
};

/**
 * A list item, used for two lists within the Pagination control: The Page size
 * selector, and the control selecting the page number.
 */
const ListItem = (props: Props) => (
  <Menu.Item
    active={props.activeItem === props.element}
    onClick={props.onClick}
    size={props.size}
    className={props.className}
  >
    {props.icon && <Icon name={props.icon} />}
    {props.element}
  </Menu.Item>
);

export default ListItem;
