import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const styles: { [key: string]: CSSProperties } = {
  popUpMessage: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    gap: SPACING[8],
  },
};

export default styles;
