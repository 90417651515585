import React, { Component, ReactNode } from 'react';
import { Segment } from 'buildingBlocks';

type Props = {
  /** The wrapped element. */
  children?: ReactNode,
  /**
   * A replacement component to use in the case of a caught exception in the
   * wrapped component. If not provided, a default error message is shown.
   */
  errorComponent?: ReactNode,
  callback?: () => void
};

type State = {
  hasError: boolean,
};

/**
* ErrorBoundary
* Use this component to wrap other components. It functions similar to a JS try/catch block, and will display a piece
* of UI in place of crashing the entire page. The error will be logged to the console.
*
* See more: https://reactjs.org/docs/error-boundaries.html
*/
class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = { hasError: false };

  componentDidCatch() {
    this.setState({ hasError: true });
    this.props.callback();
  }

  render() {
    if (this.state.hasError) {
      if (this.props.errorComponent) {
        return this.props.errorComponent;
      }
      return <Segment>There was an error loading this content.</Segment>;
    }
    return this.props.children ? this.props.children : null;
  }
}

export default ErrorBoundary;
