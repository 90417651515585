/* eslint-disable react-hooks/rules-of-hooks */
import { WppIconDocument } from '@wppopen/components-library-react';
import _ from 'lodash';
import qs from 'qs';
import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
  WppActionButton, WppIconAirplane, WppIconBarChart, WppIconDownload,
  WppIconEdit, WppIconMore, WppIconTrash, WppListItem, WppMenuContext,
} from 'buildingBlocks';
import {
  DELETE_STRATEGY, DOWNLOAD_REPORT, EDIT_STRATEGY, MARK_AS_TEST,
  STRATEGY_FLIGHT_RUNS, STRATEGY_TYPE, UNMARK_AS_TEST, VIEW_ANALYTICS,
} from 'constantsBase';
import { updateClientTestForStrategy } from 'containers/StrategyAnalytics/actions';
import { WIZARD_STEPS, WizardSteps } from 'containers/StrategyWizard/constants';
import { HOME_PAGE_ELEMENT_TAG } from 'cssTagConstants';
import { Permission, checkPermissions } from 'utils/featureFlags';
import { useNavigateHandler } from 'utils/hooks/useNavigateHander';
import { isAdminOrQAGlobal } from 'utils/functionHelpers';
import { Member, StrategyType, User } from 'utils/types';
import style from './style';
import './style.scss';

const { menuItem, menuContext, menuContainer } = style;

const HELIOS_STRATEGY_TYPE: Array<number> = [STRATEGY_TYPE.helios.id, STRATEGY_TYPE.heliosSegmentRecency.id];
const heliosStrategyType = (strategyTypeId: number) => HELIOS_STRATEGY_TYPE.includes(strategyTypeId);

type Props = {
  /** This strategy id. */
  id: number
  /** Function called when user triggers a helios report download. */
  user: User
  /** Function called when user download the report. */
  handleDownloadReport: any
  /** Whether to show a link taking user to the analytics page for the strategy. */
  showAnalyticsLink?: boolean
  /** The member to which this strategy belongs, used for permissioning. */
  member?: Member
  /** This strategy type. */
  strategyType?: StrategyType
  /** Function called when user deletes this strategy. */
  onDeleteClick?: (event: SyntheticEvent, data: unknown) => void
  /** Whether the 'Delete Strategy' button should be hidden. */
  hideDelete?: boolean
  /** Whether the 'Download Strategy' button should be hidden. */
  hideDownload?: boolean
  clientTest?: boolean
  showToast?: Function
};

function EditWppStrategyStep(props: Props) {
  const {
    user,
    member,
    id,
    showAnalyticsLink,
    hideDelete,
    strategyType,
    hideDownload,
    onDeleteClick,
    handleDownloadReport,
    clientTest,
    showToast,
  } = props;

  const dispatch = useDispatch();

  const onTestClick = () => {
    dispatch(updateClientTestForStrategy(id, !clientTest, true, showToast));
  };

  const canEdit = checkPermissions(user, Permission.accessStrategyWizard, member);
  const deleteStrategy = checkPermissions(user, Permission.deleteStrategy, member);
  const wizardStepBasedOnStratType = WIZARD_STEPS[WizardSteps.attachFlightsStep].id;
  const isAdmin = isAdminOrQAGlobal(user);
  const queryStringVals = { strategy: [id], limit: 25, skip: 0 };
  const stringified = qs.stringify(queryStringVals);
  const StrategyFlightRunsURL = `/strategyflightruns?${stringified}`;

  return (
    <WppMenuContext style={menuContext}>
      <WppActionButton id={HOME_PAGE_ELEMENT_TAG.stratDetailHamburgerMenu} slot="trigger-element" className="actionMenu">
        <WppIconMore color="var(--wpp-brand-color)" />
      </WppActionButton>
      <div style={menuContainer}>
        {canEdit && (
          <WppListItem key={EDIT_STRATEGY} onClick={useNavigateHandler(`/strategies/wizard/${id}/${wizardStepBasedOnStratType}`)}>
            <WppIconEdit slot="left" />
            <p slot="label" style={menuItem}>
              {EDIT_STRATEGY}
            </p>
          </WppListItem>
        )}
        {showAnalyticsLink && (
          <WppListItem key={VIEW_ANALYTICS} onClick={useNavigateHandler(`/strategies/${id}`)}>
            <p slot="label" style={menuItem}>
              {VIEW_ANALYTICS}
            </p>
            <WppIconBarChart slot="left" />
          </WppListItem>
        )}
        {isAdmin && (
          <>
            <WppListItem key={STRATEGY_FLIGHT_RUNS} linkConfig={{ href: StrategyFlightRunsURL, target: '_blank' }}>
              <p slot="label" style={menuItem}>
                {STRATEGY_FLIGHT_RUNS}
              </p>
              <WppIconAirplane slot="left" />
            </WppListItem>
            <WppListItem key={MARK_AS_TEST} onClick={onTestClick}>
              <p slot="label" style={menuItem}>
                {clientTest ? UNMARK_AS_TEST : MARK_AS_TEST}
              </p>
              <WppIconDocument slot="left" />
            </WppListItem>
          </>
        )}
        {heliosStrategyType(_.get(strategyType, 'id')) && !hideDownload && (
          <WppListItem key={DOWNLOAD_REPORT} onWppChangeListItem={() => handleDownloadReport(props)}>
            <p slot="label" style={menuItem}>
              {DOWNLOAD_REPORT}
            </p>
            <WppIconDownload slot="left" />
          </WppListItem>
        )}
        {deleteStrategy && !hideDelete && (
          <WppListItem id={HOME_PAGE_ELEMENT_TAG.stratDetailHamburgerMenuDelete} key={DELETE_STRATEGY} onWppChangeListItem={(event: any) => onDeleteClick(event, props)}>
            <p slot="label" style={menuItem}>
              {DELETE_STRATEGY}
            </p>
            <WppIconTrash slot="left" />
          </WppListItem>
        )}
      </div>
    </WppMenuContext>
  );
}

export default EditWppStrategyStep;
