import { Permission } from 'utils/featureFlags';
import { DSP } from 'constantsBase';
import { ELEMENT_ID } from 'cssTagConstants';
import { FRESHDESK_LINK } from 'containers/App/constants';

export const navItemList = {
  strategies: { name: 'Strategies', id: ELEMENT_ID.navBar.strategies, to: '/strategies', value: 'strategies' },
  helpCenter: { name: 'Help Center', to: FRESHDESK_LINK, id: ELEMENT_ID.navBar.helpCenter },
  tools: { name: 'Tools', id: ELEMENT_ID.navBar.tools },
  admin: { name: 'Admin', id: ELEMENT_ID.navBar.admin },
};

export type NavigationItem = {
  label: string
  value: string
  children: Array<NavigationItem>
  path?: string
  permission?: Array<string>
  dspIcons?: Array<number>
  id?: string
};

const strategiesMenuItems: Array<NavigationItem> = [];

const toolsMenuItems: Array<NavigationItem> = [
  {
    label: 'Users',
    value: 'users',
    path: '/users',
    permission: [Permission.adminUsers, Permission.manageUserRoles],
    children: [],
  },
  {
    label: 'Brands',
    value: 'brands',
    path: '/brands',
    permission: [Permission.manageBrands],
    children: [],
  },
  {
    label: 'Notifications',
    value: 'notifications',
    path: '/notifications',
    children: [],
  },
  {
    label: 'Set User Groups',
    value: 'usergroups',
    path: '/usergroups',
    permission: [Permission.userGroups],
    dspIcons: [DSP.APN.id],
    children: [],
  },
  {
    label: 'A/B Insights',
    value: 'abinsights',
    path: '/abinsights/1',
    permission: [Permission.abInsightsRedesign],
    dspIcons: [],
    children: [],
  },
  {
    label: 'Developer Token',
    value: 'developertoken',
    path: '/developertoken',
    permission: [Permission.developerAPIAccess],
    children: [],
  },
];

const adminMenuItems: Array<NavigationItem> = [
  {
    label: 'Flight Runs',
    value: 'strategyflightruns',
    path: '/strategyflightruns',
    permission: [Permission.strategyFlightRuns],
    children: [],
  },
  {
    label: 'Jobs',
    value: 'jobs',
    path: '/jobs',
    permission: [Permission.manageJobs],
    children: [],
  },
  {
    label: 'Copilot Health',
    value: 'health',
    path: '/health/jobs',
    permission: [Permission.manageJobs, Permission.strategyFlightRuns],
    children: [],
  },
  {
    label: 'Flight Sync',
    value: 'flightsync',
    path: '/flightsync',
    permission: [], // component will only render for admins or QAGlobal users
    children: [],
  },
  {
    label: 'Members',
    value: 'members',
    path: '/members',
    permission: [Permission.manageMembers],
    children: [],
  },
  {
    label: 'Roles',
    value: 'roles',
    path: '/roles',
    permission: [Permission.manageRoles],
    children: [],
  },
  {
    label: 'Demo Sites',
    value: 'demosites',
    path: '/demosites',
    permission: [Permission.demoAdminTool],
    children: [],
  },
  {
    label: 'Messages',
    value: 'messages',
    path: '/messages',
    permission: [Permission.manageMessages],
    children: [],
  },
  {
    label: 'Whitelist IP Address',
    value: 'whitelistip',
    path: '/whitelistip',
    permission: [], // component will only render for admins or QAGlobal users
    children: [],
  },
  {
    label: 'Auth Token Generator',
    value: 'authtoken',
    path: '/authtoken',
    permission: [Permission.generateAuthHeaders],
    children: [],
  },
];

export const navigationData: Array<NavigationItem> = [
  {
    label: 'Strategies',
    value: 'strategies',
    path: '/strategies',
    children: strategiesMenuItems,
    permission: [],
  },
  {
    label: 'Tools',
    value: ELEMENT_ID.navBar.tools,
    children: toolsMenuItems,
  },
  {
    label: 'Admin',
    value: ELEMENT_ID.navBar.admin,
    children: adminMenuItems,
  },
  {
    label: 'Help Center',
    value: ELEMENT_ID.navBar.helpCenter,
    path: FRESHDESK_LINK,
    children: [],
    permission: [],
  },
];
