import { TextareaInputChangeEventDetail } from '@wppopen/components-library';
import { WppTextareaInputCustomEvent } from '@wppopen/components-library/dist/types/components';
import { useState } from 'react';
import { Chatbot } from 'utils/copilotAPI';
import { ChatRole, errorMessage, initialMessagesState, MessagesType } from './constants';

const useChatbot = () => {
  const [messages, setMessages] = useState<MessagesType>(initialMessagesState);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleInputChange = (e: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => {
    setInput(e.detail.value);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLWppActionButtonElement> | React.KeyboardEvent<HTMLWppTextareaInputElement>, customInput?: string) => {
    e.preventDefault();

    let aiResponse;
    const question = customInput ?? input;
    try {
      // add user message to the chat history
      setLoading(true);
      setMessages((prevMessages: MessagesType) => [...prevMessages, { role: ChatRole.user, content: question }]);

      // make request to chatbot endpoint via copilot-ui proxy
      const res = await Chatbot.request({
        chat_history: messages,
        question,
      });
      aiResponse = res.data.reply;
    } catch (err) {
      console.error(err);
      setError(true);
      aiResponse = errorMessage;
    } finally {
      // update messages with the ai response
      setMessages([
        ...messages,
        { role: ChatRole.user, content: question },
        { role: ChatRole.assistant, content: aiResponse },
      ]);
      // clear the input field and set loading to false
      setInput('');
      setLoading(false);
    }
  };

  return {
    input,
    handleInputChange,
    messages,
    loading,
    error,
    handleSubmit,
  };
};

export default useChatbot;
