import _ from 'lodash';
import React, { CSSProperties, ReactElement } from 'react';
import { Checkbox } from 'buildingBlocks';
import Tooltip from 'containers/Tooltip';

export type Props = {
  id?: string,
  /** The name of the tooltip. This is ignored if `input.name` is defined. */
  name?: string,
  /** Function called when the toggle state changes. */
  onChange?: Function,
  /** Input to which the toggle is bound. */
  input?: {
    name: string,
    value: any,
    onChange: Function,
  },
  /** Text content for the tooltip. */
  tooltip?: ReactElement,
  /** The tooltip group. */
  group?: string,
  label?: string,
  checked?: boolean,
  style?: CSSProperties,
  isToggleStyledBtn?: boolean,
  disabled?: boolean
};

const Toggle = (props: Props) => {
  const { tooltip, group, input, isToggleStyledBtn = true, style, disabled, ...rest } = props;
  const iconStyle = { display: 'inline-block', marginLeft: '1rem', verticalAlign: 'middle' };
  const tooltipStyle = style ?? {};

  const onChange = (checked: boolean) => {
    if (props.onChange) {
      props.onChange(checked);
    }
    if (props.input && props.input.onChange) {
      props.input.onChange(checked);
    }
  };
  return (
    <div>
      <Checkbox
        toggle={isToggleStyledBtn}
        name={_.get(rest, 'input.name', '')}
        {..._.omit(rest, 'meta', 'input')}
        onChange={(_event, data) => onChange(data.checked)}
        disabled={disabled}
      />
      {
        tooltip
        && (
        <div className="tooltipIcon" style={iconStyle}>
          <Tooltip content={tooltip} group={group} name={((input && input.name) || rest.name)} tooltipstyling={tooltipStyle} />
        </div>
        )
      }
    </div>
  );
};

export default Toggle;
