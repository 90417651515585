import React from 'react';
import { Button, Icon } from 'buildingBlocks';
import { ButtonClickHandler } from 'utils/types';
import { COPILOT_COLORS } from 'globalStyles';
import createRippleEffect from './utils';

type Props = {
  onClick: ButtonClickHandler,
  name: string,
  iconName: string,
  style?: Object,
};

const MaterialButton = ({
  name,
  iconName,
  onClick,
  style,
} : Props) => (
  <Button
    className="material-style-btn"
    style={{
      borderRadius: '20px',
      textTransform: 'capitalize',
      padding: '10px 10px 5px',
      background: 'transparent',
      ...style,
    }}
    onClick={onClick}
    onMouseDown={createRippleEffect}
    onMouseUp={(e) => {
      const target = e.currentTarget;
      const ripple = target.querySelector('.ripple');
      ripple.parentNode.removeChild(ripple);
    }}
  >
    <Button.Content>
      <span style={{ fontSize: '1.571em', fontWeight: 500, color: '#63747A' }}>{name}</span>
      <Icon
        size="large"
        // @ts-ignore - semantic
        name={iconName}
        style={{
          color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE,
          marginLeft: '10px',
          lineHeight: '16px',
          ...(iconName === 'add' ? { WebkitTextStroke: '2px #EDEEED' } : { }),
        }}
      />
    </Button.Content>
  </Button>
);

export default MaterialButton;
