import React, { useEffect, useRef } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/python/python';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import { customTextAreaStyle } from './styles';

export type CustomTextAreaProps = {
  value: string
  onChange: Function
  name: string
  disabled: boolean
  height: number
  mode: 'python' | 'application/json'
  csvInputValue?: boolean
  className?: string
};

const CustomTextArea = ({
  name,
  value,
  onChange,
  disabled,
  height,
  mode,
  csvInputValue,
  ...rest
}: CustomTextAreaProps) => {
  const editorRef = useRef(null);

  // Ensure CodeMirror refreshes whenever value changes
  useEffect(() => {
    if (!editorRef.current) return undefined;

    const timeout = setTimeout(() => {
      editorRef.current.refresh();
    }, 100);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div
      id={name}
      style={{ ...customTextAreaStyle, height: `${height}px` }}
    >
      <CodeMirror
        editorDidMount={(editor) => {
          editorRef.current = editor;
        }}
        onBeforeChange={(_editor, _data, val) => onChange(val)}
        value={value}
        options={{
          mode,
          lineNumbers: true,
          indentWithTabs: true,
          indentUnit: 4,
          readOnly: disabled && 'nocursor',
        }}
        className={csvInputValue ? 'custom-codemirror-editor' : 'custom-codemirror-empty'}
        {...rest}
      />
    </div>
  );
};
export default CustomTextArea;
