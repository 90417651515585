import _ from 'lodash';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'buildingBlocks';
import OptionWithIcon from 'components/OptionWithIcon/OptionWithIcon';
import DspIcon from 'components/DspIcon';
import { Member as MemberType } from 'utils/types';
import { PAGE_FORBIDDEN_STRATEGY_WIZARD } from '../styles';

const { summaryDiv, requestAccessP, listStyle, buttonContainer, requestAccesBtn, goBackBtn, dspIcon } = PAGE_FORBIDDEN_STRATEGY_WIZARD;

const REQUEST_ACCESS_WIKI = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001209586-users-request-access-update';
const ROLES_AND_DEFINITIONS_WIKI = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001209585-roles-and-definitions';

type PageForbiddenSummaryProps = {
  strategyId: string
  unauthorizedMembers: Array<Pick<MemberType, 'dsp' | 'displayName' | 'externalId'>>
};

const PageForbiddenSummary = ({ strategyId, unauthorizedMembers }: PageForbiddenSummaryProps) => {
  const messageBase = `You do not have access to strategy ${strategyId} yet`;
  const unauthorizedMemSize = _.size(unauthorizedMembers);

  return (
    <div style={summaryDiv}>
      {unauthorizedMemSize ? (
        <>
          {`${messageBase}, because you do not have access to the following member${(unauthorizedMemSize > 1) ? 's' : ''}:`}
          <ul style={listStyle}>
            {_.map(unauthorizedMembers, (mem: { displayName: string, dsp: number, externalId: string }, index) => (
              <li key={mem.externalId} style={{ ...(index !== unauthorizedMemSize - 1 && { marginBottom: 8 }) }}>
                <OptionWithIcon
                  text={`[${mem.externalId}] ${mem.displayName}`}
                  icon={<DspIcon dspId={mem.dsp} iconStyles={dspIcon} />}
                  externalId={_.get(mem, 'externalId')}
                />
              </li>
            ))}
          </ul>
        </>
      ) : `${messageBase}.`}
    </div>
  );
};

type PageForbiddenMessageProps = {
  strategyId: string
  userId: number
  unauthorizedMembers: Array<Pick<MemberType, 'dsp' | 'displayName' | 'externalId'>>
};

export const PageForbiddenMessage = ({ strategyId, userId, unauthorizedMembers }: PageForbiddenMessageProps) => (
  <>
    <PageForbiddenSummary strategyId={strategyId} unauthorizedMembers={unauthorizedMembers} />
    <p style={requestAccessP}>
      Request access from your
      <Link to={`/users/${userId}`}> user profile </Link>
      or learn more about
      <a href={REQUEST_ACCESS_WIKI}> requesting access </a>
      from your Approver <br />and
      <a href={ROLES_AND_DEFINITIONS_WIKI}> Roles and Definitions</a>.
    </p>
  </>
);

export const PageForbiddenFooter = ({ userId }: { userId: number }) => {
  const navigate = useNavigate();
  const onRequestAccessClick = () => navigate(`/users/${userId}`);
  const onGoBackClick = () => navigate('/');

  return (
    <div style={buttonContainer}>
      <Button onClick={onRequestAccessClick} style={requestAccesBtn}>Request Access</Button>
      <Button onClick={onGoBackClick} style={goBackBtn}>Go Back</Button>
    </div>
  );
};
