import React, { ReactNode } from 'react';
import PageLoading from 'components/PageLoading';

type Props = {
  loading: boolean,
  pageName: string,
  children: ReactNode,
};

const DataLoading = (props: Props) => {
  const renderComponent = props.loading
    ? (
      <PageLoading pageName={props.pageName}>
        {`Loading data for ${props.pageName}`}
      </PageLoading>
    )
    : <>{props.children}</>;
  return renderComponent;
};

export default DataLoading;
