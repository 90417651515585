/**
 *
 * FormDropdown
 * This is a searchable dropdown component which wraps Semantic's Dropdown. This is a Controlled Component.
 */
import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { Dropdown, Form, Label } from 'buildingBlocks';
import { omitProps } from 'utils/wrapperComponents';
import formDropdown from './styles';

const CleanedDropdown = omitProps(Dropdown, ['meta']);

type Props = {
  /** User-facing label for the dropdown. */
  label: string,
  /** Form input to which this dropdown is bound. */
  input: {
    name: string,
    value: unknown,
    onChange: Function,
    onBlur: Function,
  }
  /** Possibly unused. */
  selectedValue: {
    name: string,
    value: unknown,
  },
  /** Initial value. */
  defaultValue:
  | string
  | number
  | Array<String>
  | Array<number>
  /** Function called when selected value changed. */
  onChange: Function,
  /** Function called when search input for dropdown changes. */
  onSearchChange: Function,
  /** Style object to apply. */
  style: CSSProperties,
  /** Various state variables for the dropdown. */
  meta: {
    touched: boolean,
    error: string,
    invalid: boolean,
  },
  value: unknown,
  required: boolean,
};

function FormDropdown({
  style,
  label,
  input,
  meta: { touched, error },
  defaultValue,
  ...rest
}: Props) {
  const passthrough: typeof rest & { defaultValue?: unknown, value: unknown } = { ...rest };
  if (defaultValue) {
    passthrough.defaultValue = defaultValue;
  } else {
    passthrough.value = input.value;
  }

  return (
    <div className="FormDropdown">
      <Form.Field required={rest.required} error={!!(touched && error)}>
        <label htmlFor={input.name}>{label}</label>
        {/*
          // @ts-ignore - hard */}
        <CleanedDropdown
          {...passthrough}
          {..._.omit(input, 'value')}
          // @ts-ignore - styling property does not exist on object
          style={{ ...formDropdown.searchField, ...style }}
          name={input.name}
          onChange={(_e, v) => input.onChange(v.value)}
          onBlur={(_e, select) => input.onBlur(select.value)}
        />
        <Label basic>{touched && error} &nbsp;</Label>
      </Form.Field>
    </div>
  );
}

export default FormDropdown;
