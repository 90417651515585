import React, { useReducer, Dispatch } from 'react';
import _ from 'lodash';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import PageLoading from 'components/PageLoading/PageLoading';
import ErrorComponent from 'components/ErrorComponent';
import { Flight } from 'utils/copilotAPI';
import { assertUnreachable } from 'utils/types';
import { flightExtIdReducer, flightExtIdInitialState } from './reducer';
import { noStrategyFound, strategyFound, strategyFetchError, flightExistsButNoStrategyAttached } from './actions';
import { StatusOptions, Action } from './constants';

export const fetchStrategy = async (dispatch: Dispatch<Action>, flightExtId: string, flightExtType: string) => {
  try {
    const { data } = await Flight.get({
      where: { externalId: flightExtId, externalType: flightExtType },
      populate: 'externalType',
    });
    if (data.length === 0) {
      dispatch(noStrategyFound());
    } else if (_.isNil(data[0].strategy)) {
      const externalType = data[0].externalType;
      dispatch(flightExistsButNoStrategyAttached(externalType));
    } else {
      const [{ strategy }] = data;
      dispatch(strategyFound(strategy));
    }
  } catch (e) {
    dispatch(strategyFetchError());
  }
};

const FlightExtIdRedirect = () => {
  const [state, dispatch] = useReducer(flightExtIdReducer, flightExtIdInitialState);
  const { status, stratId, externalType } = state;
  const params = useParams();
  const navigate = useNavigate();
  const { flightExtId, flightExtType } = params;

  useMount(() => {
    fetchStrategy(dispatch, flightExtId, flightExtType);
  });

  switch (status) {
    case StatusOptions.LOADING:
      return (
        <PageLoading>{`Looking for the Strategy related to external ID '${flightExtId}'`}</PageLoading>
      );
    case StatusOptions.STRATEGY_FETCH_ERROR:
      return (
        <ErrorComponent
          msg="There was a problem finding your Strategy. Please navigate to the Strategy using the home page."
          pageTitle=""
          onBack={() => navigate('')}
          buttonText="Home"
        />
      );
    case StatusOptions.NO_STRATEGY_FOUND:
    case StatusOptions.FLIGHT_EXISTS_BUT_NO_STRATEGY_ATTACHED: {
      const flightDispalyName = externalType ? externalType.displayName : 'Flight';
      return (
        <ErrorComponent
          // eslint-disable-next-line max-len
          msg={`Copilot was able to find the ${flightDispalyName} (${flightExtId}), but it is not attached to a Strategy.`}
          pageTitle=""
          onBack={() => navigate('')}
          buttonText="Home"
        />
      );
    }
    case StatusOptions.STRATEGY_FOUND:
      return (
        <Navigate to={`/strategies/${stratId}`} />
      );
    default:
      return assertUnreachable(status);
  }
};

export default FlightExtIdRedirect;
