/**
*
* QueryStringLink
*
*/

import React, { ReactNode } from 'react';
import qs from 'qs';

type Props = {
  to: string,
  params: {
    [key: string]: Array<string> | string | {},
  },
  children?: ReactNode,
};

const QueryStringLink = ({ to, params, children }: Props) => (
  <a href={`${to}?${qs.stringify(params)}`} target="_blank" rel="noopener noreferrer">{children}</a>
);

export default QueryStringLink;
