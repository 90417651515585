import React from 'react';
import DocumentTitle from 'react-document-title';
import { Dimmer, WppSpinner, WppTypography } from 'buildingBlocks';

type Props = {
  /** The name of the current page. */
  pageName?: string
  /** The contents of the page. */
  children?: React.ReactNode
  /** Whether or not to include a loader */
  loading?: boolean
  /** whether or not to include dimmer */
  showDimmer?: boolean
};

const PageLoading = ({ pageName, children = null, loading = true, showDimmer = true }: Props) => (
  <div>
    {pageName ? <DocumentTitle title={`${pageName} - Loading...`} /> : ''}
    {showDimmer
      ? (
        <Dimmer active page>
          {loading && <WppSpinner />}
          <WppTypography type="s-body" tag="p">{children}</WppTypography>
        </Dimmer>
      )
      : <WppSpinner />}
  </div>
);

export default PageLoading;
