/*
Create ripple effect
based on click press position, gets and sets top/left position for where to start the ripple animation
*/
const createRippleEffect = (e) => {
  const target = e.currentTarget;
  const rect = target.getBoundingClientRect();
  let ripple = target.querySelector('.ripple');
  ripple = document.createElement('span');
  ripple.className = 'ripple';
  ripple.style.height = `${Math.max(rect.width, rect.height)}px`;
  ripple.style.width = `${Math.max(rect.width, rect.height)}px`;
  target.appendChild(ripple);
  if (document.body) {
    const top = e.pageY - rect.top - ripple.offsetHeight / 2 - document.body.scrollTop;
    const left = e.pageX - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft;
    ripple.style.top = `${top}px`;
    ripple.style.left = `${left}px`;
  }
  return false;
};

export default createRippleEffect;
