import React, { CSSProperties } from 'react';
import { Menu, Segment, SegmentSizeProp, MenuProps } from 'buildingBlocks';
import {
  pageContainerStyle, itemStyle, activeItemStyle, compactStyle,
} from './style';
import ListItem from './ListItem';

export type PageSize = SegmentSizeProp & MenuProps['size'];

type Props = {
  /**
   * Array of options for page size to display to user for selection, e.g. [10, 25, 50].
   */
  elementsPerPageOptions: Array<number>,
  // eslint-disable-next-line react/no-unused-prop-types
  /** Function called when page size is changed. */
  onPageSizeChange: Function,
  /** Zero-based index of the active inner menu item. */
  activeItem: number,
  /**
   * Size option passed to the underlying Semantic <Segment> element.
   * One of:  [ 'mini', 'tiny', 'small', 'large', 'big', 'huge', 'massive']
   */
  size: PageSize,
  /** When true, renders a version of the component with less whitespace. */
  compact: boolean,
  /** change the color of the page size selector (default: #fff (white)) */
  backgroundColor?: CSSProperties['backgroundColor'],
};

const PageSizeSelector = (props: Props) => (
  <Segment basic size={props.size as SegmentSizeProp} style={{ ...pageContainerStyle, justifyContent: 'flex-end' }}>
    {
      props.compact
        ? (
          <div style={compactStyle}>
            {props.elementsPerPageOptions.map((item) => (
              <div key={item}>
                {props.activeItem === item
                  ? <p style={activeItemStyle}>{item}</p>
                  : (
                    <a
                      href=""
                      style={itemStyle}
                      onClick={(e) => {
                        e.preventDefault();
                        props.onPageSizeChange(item);
                      }}
                    >
                      {item}
                    </a>
                  )}
              </div>
            ))}
          </div>
        )
        : (
          <Menu size={props.size} pagination style={{ backgroundColor: props.backgroundColor }}>
            {props.elementsPerPageOptions.map((number) => (
              <ListItem
                key={number}
                element={number}
                activeItem={props.activeItem}
                onClick={() => props.onPageSizeChange(number)}
              />
            ))}
          </Menu>
        )
}
  </Segment>
);

export default PageSizeSelector;
