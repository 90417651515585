import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export default {
  base: {
    padding: '0 10px',
    marginLeft: '6px',
    marginRight: SPACING[2],
  },
};
