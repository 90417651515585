export const pullLeft = {
  float: 'left',
};

export const pullRight = {
  float: 'right',
};

export const paginationContainerStyle = {
  overflow: 'hidden',
  padding: 'initial',
  margin: '0px',
};

export const pageContainerStyle = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  textAlign: 'center',
  alignItems: 'center',
  padding: 'inherit',
  margin: 'initial',
};

export const compactStyle = {
  display: 'inline-flex',
};

export const itemStyle = {
  padding: '0px 5px',
};

export const activeItemStyle = {
  padding: '0px 5px',
  background: 'rgba(0, 0, 0, 0.15)',
};
