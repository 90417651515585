import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS },
  WPP: { blue500, grey0, grey1000 },
} = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;

const CHATBOT_STYLES: { [key: string]: CSSProperties } = {
  openChatButton: {
    position: 'fixed',
    height: '68px',
    width: '36px',
    right: 0,
    top: 'calc(50% - 34px)',
  },
  buttonIcon: {
    color: NEUTRALS.N0_WHITE,
    margin: '6px 0px 0px 4px',
  },
  chatbotWindow: {
    maxWidth: '440px',
    width: '440px',
    height: 'calc(100% - 104px)',
    boxShadow: '0px 1px 4px 0px #343A3F0D, 0px 4px 12px 0px #343A3F1F',
    borderRadius: SPACING[12],
    backgroundColor: NEUTRALS.N0_WHITE,
    position: 'fixed',
    padding: SPACING[20],
    right: SPACING[24],
    bottom: SPACING[24],
    zIndex: 100000000000,
  },
  loadingOverlay: {
    position: 'absolute',
    background: 'rgb(248, 249, 251, 0.75)',
    width: 'inherit',
    height: '100%',
    top: '0px',
    right: '0px',
    borderRadius: SPACING[8],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: SPACING[20],
    alignItems: 'center',
  },
  chatMessagesContainer: {
    height: 'calc(100% - 236px)',
    marginBottom: SPACING[20],
    maxWidth: '100%',
    overflow: 'hidden auto',
    borderRadius: SPACING[8],
  },
  messageStyle: {
    padding: '12px 16px',
    borderRadius: SPACING[8],
    color: grey1000,
    marginBottom: SPACING[8],
  },
  promptButton: {
    margin: '0px 8px 8px 0px',
  },
  chatInputContainer: {
    borderRadius: SPACING[8],
    border: '1px solid #A2A9B0',
    height: '138px !important',
  },
  chatInputFooter: {
    fontFamily: 'Inter',
    display: 'flex',
    justifyContent: 'space-between',
  },
  charCountContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  charCountText: {
    color: grey0,
    fontSize: '12px',
  },
  sendButton: {
    color: blue500,
  },
  supportLink: {
    width: '100%',
    height: SPACING[40],
    marginBottom: SPACING[8],
  },
  supportLinkIcon: {
    marginLeft: SPACING[4],
    color: blue500,
  },
  betaLabel: {
    color: grey0,
    marginTop: SPACING[8],
    marginLeft: '1px',
  },
};

export default CHATBOT_STYLES;
