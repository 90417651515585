import { Moment } from 'moment';
import { Flight, Member, Advertiser, $Values, LoadingStates } from 'utils/types';
import { LOADING_STATES, HTTP_STATUS } from 'constantsBase';
import { VizId } from 'charts/ABReportViz/constants';
import { Metric } from 'containers/StrategyAnalytics/constants/metricsConstants';
import {
  FETCH_FLIGHTS_SUCCEEDED,
  FETCH_FLIGHTS_FAILED,
  FETCH_DATA_COMPLETED,
  FETCH_DATA_FAILED,
  FORM_SUBMITTED,
  UPDATE_CURRENT_VISUALIZATION,
} from './constants';
import { ErrorDetail } from './types';
import { ABInsightsDataDSPSubtype } from './interfaces';

export type State = {
  flights: Array<Flight>
  member: Member
  advertiser: Advertiser,
  errorDetail: ErrorDetail,
  status: number,
  loading: $Values<LoadingStates>
  dataAdapter: ABInsightsDataDSPSubtype
  selectedFlights: [] | [Flight, Flight]
  flightExtIdToAlias?: {
    [flightExtId: string]: string
  }
  flightType: number
  title: string
  startDate: Moment
  endDate: Moment
  KPIs: Array<Metric>
  currentTab: VizId
};

const INITIAL_STATE: State = {
  flights: [],
  member: null,
  advertiser: null,
  errorDetail: {},
  status: HTTP_STATUS.VALID,
  loading: LOADING_STATES.loading,
  flightType: null,
  selectedFlights: [],
  dataAdapter: null,
  title: '',
  startDate: null,
  endDate: null,
  KPIs: [],
  currentTab: VizId.overview,
};

type Action = {
  type: string
  payload: Array<Flight> & Partial<State> & {
    flightLeft?: Flight
    flightRight?: Flight
    error: string
  }
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case FETCH_FLIGHTS_SUCCEEDED:
      return {
        ...state,
        flights: action.payload,
        errorDetail: {},
      };
    case FETCH_FLIGHTS_FAILED:
      return {
        ...state,
        flights: [],
        errorDetail: { flightFetchError: action.payload.error },
        status: action.payload.status,
      };
    case FETCH_DATA_COMPLETED:
      return {
        ...state,
        dataAdapter: action.payload.dataAdapter,
        loading: LOADING_STATES.notLoading,
      };
    case FETCH_DATA_FAILED:
      return {
        ...state,
        errorDetail: { dataFetchError: action.payload.error },
        status: action.payload.status,
        loading: LOADING_STATES.notLoading,
      };
    case UPDATE_CURRENT_VISUALIZATION:
      return {
        ...state,
        currentTab: action.payload.currentTab,
      };
    case FORM_SUBMITTED:
      return {
        ...state,
        loading: LOADING_STATES.loading,
        member: action.payload.member,
        advertiser: action.payload.advertiser,
        selectedFlights: [action.payload.flightLeft, action.payload.flightRight],
        flightExtIdToAlias: action.payload.flightExtIdToAlias,
        flightType: action.payload.flightType,
        title: action.payload.title,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        KPIs: action.payload.KPIs,
        currentTab: action.payload.currentTab,
      };
    default:
      return state;
  }
};
