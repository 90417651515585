import React from 'react';
import _ from 'lodash';
import { WppButton, WppInlineMessage, WppModal, WppTypography } from 'buildingBlocks';
import style from './style';
import './style.scss';

type Props = {
  itemsToDelete: { [key: string]: { id: number } }
  onCancel: (...args: Array<any>) => void
  onDelete: (...args: Array<any>) => void
  open: boolean
  deleting?: boolean
  footerMessage?: string
  modalName: string
  deleteMessage?: string
  itemName?: string
  deleteItemName?: string
  disabled?: boolean
};

type RemoveModalListProps = {
  itemsToDelete: { [key: string]: { id: number } }
  itemName?: string
};

const { list, listItem, listItemTitle } = style;

export const RemoveModalList: React.FC<RemoveModalListProps> = ({ itemsToDelete, itemName = 'name' }: RemoveModalListProps): React.ReactElement => (
  <ul style={list}>
    {
      _.map(itemsToDelete, (item) => (
        <li key={item.id} style={listItem}>
          <WppTypography style={listItemTitle} tag="p" type="s-strong">{_.get(item, itemName, item.id)}</WppTypography>
        </li>
      ))
    }
  </ul>
);

export const WppDeleteConfirmationModalComponent: React.FC<Props> = ({
  itemsToDelete,
  onCancel,
  open,
  onDelete,
  deleting,
  footerMessage,
  modalName,
  deleteMessage,
  itemName,
  deleteItemName,
  disabled,
}: Props): React.ReactElement => (
  <WppModal open={open} className="deleteConfirmationModal">
    <WppTypography slot="header" tag="p" type="xl-heading">{modalName}</WppTypography>
    <div slot="body">
      <p>{deleteMessage}&nbsp;Are you sure you wish to proceed? This action cannot be undone.</p>
      <p>The following {deleteItemName} will be deleted:</p>
      <RemoveModalList itemsToDelete={itemsToDelete} itemName={itemName} />
    </div>
    <div slot="actions">
      {footerMessage && (<WppInlineMessage size="s" message={footerMessage} type="error" />)}
      <div style={style.actionButton}>
        <WppButton variant="secondary" size="s" onClick={onCancel}>Cancel</WppButton>
        <WppButton variant="destructive" size="s" onClick={() => onDelete(itemsToDelete)} loading={deleting} disabled={disabled}>Delete</WppButton>
      </div>
    </div>
  </WppModal>

);

export default WppDeleteConfirmationModalComponent;
