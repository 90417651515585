import _ from 'lodash';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useRef } from 'react';
import { WppActionButton, WppIconExternalLink } from 'buildingBlocks';
import { createSupportTicketUrl } from 'containers/StrategyWizard/constants';
import { COPILOT_COLORS } from 'globalStyles';
import ChatbotPrompts from './ChatbotPrompts';
import { ChatMessageType, ChatRole, errorMessage, HandleSubmitType, initialMessagesState, MessagesType } from '../constants';
import CHATBOT_STYLES from '../styles';

const { WPP: { blue100, grey100 } } = COPILOT_COLORS;
const { chatMessagesContainer, messageStyle, supportLink, supportLinkIcon } = CHATBOT_STYLES;

type MessageProps = {
  message: ChatMessageType
};

const Message = ({ message: { role, content } }: MessageProps) => {
  const showCopilotSupportBtn = role === ChatRole.assistant && (
    content === errorMessage || _.includes(content, 'Copilot support') || _.includes(content, 'support ticket')
  );

  return (
    <>
      <div style={{ ...messageStyle, backgroundColor: role === ChatRole.user ? blue100 : grey100 }}>
        <Markdown>
          {content}
        </Markdown>
      </div>
      {showCopilotSupportBtn && (
        <WppActionButton
          className="support-link-msg"
          onClick={() => window.open(createSupportTicketUrl, '_blank')}
          style={supportLink}
        >
          Copilot Support
          <WppIconExternalLink
            width={19}
            height={19}
            style={supportLinkIcon}
          />
        </WppActionButton>
      )}
    </>
  );
};

type ChatMessagesProps = {
  messages: MessagesType
  error: boolean
  handleSubmit: HandleSubmitType
};

const ChatMessages = (props: ChatMessagesProps) => {
  const { messages, error, handleSubmit } = props;
  const chatMessagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      // scroll to bottom whenever a new message is sent/recieved
      chatMessagesRef.current?.scrollTo({
        top: chatMessagesRef.current.scrollHeight,
        behavior: 'smooth',
      });
    });

    resizeObserver.observe(chatMessagesRef.current);

    // clean up
    return () => resizeObserver.disconnect();
  }, [messages, error]);

  return (
    <div
      className="chat-messages-container"
      style={chatMessagesContainer}
      ref={chatMessagesRef}
    >
      {_.map(messages, (m: ChatMessageType, idx: number) => (
        <Message key={`message-${idx}`} message={m} />
      ))}
      {(!error && _.isEqual(messages, initialMessagesState)) && (
        <ChatbotPrompts handleSubmit={handleSubmit} />
      )}
    </div>
  );
};

export default ChatMessages;
