// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  height: 20px;
  display: flex;
  align-items: center;
}
.tag::part(typography) {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/StratTypeBadge/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":[".tag{\n  height: 20px;\n  display: flex;\n  align-items: center;\n  &::part(typography){\n    font-size: 12px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
