import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logout from 'containers/Logout/index';
import { Mode } from 'containers/User/types';
import { featureEnabled, MEMBER } from 'utils/featureFlags';
import { ELEMENT_ID } from 'cssTagConstants';
import { WppAvatar, WppMenuContext, WppListItem, WppIconGear } from 'buildingBlocks';
import { GlobalState } from 'reducers';
import { userAvatarStyle } from '../style';

const UserMenuItems = [
  {
    name: 'Settings',
    as: Link,
    to: '/usersettings',
    id: 'user-settings',
    IconComponent: WppIconGear,
  },
];

export const UserOptionsComponent = (): React.ReactElement => {
  // Kept type to 'any' because user under GlobalState has 'any' type. Assigning User type is giving an error
  const user: any = useSelector<GlobalState>((state) => _.get(state, 'login.user', { features: {} }));
  const navigate = useNavigate();

  const filteredItems = () => {
    const userHasPermission = (item) => _.isEmpty(item.permission)
      || _.some(item.permission, (permission) => featureEnabled(user, permission, MEMBER.ANY));
    return UserMenuItems.filter(userHasPermission);
  };

  return (
    <WppMenuContext id={ELEMENT_ID.navBar.user}>
      <WppAvatar
        slot="trigger-element"
        name={`${user.firstName} ${user.lastName}`}
        size="s"
        style={userAvatarStyle}
      />
      <WppListItem
        onWppChangeListItem={() => navigate('/userprofile', {
          state: { userId: user.id, userEmail: user.email, mode: _.isEmpty(user.roles) ? Mode.create : Mode.view },
        })}
      >
        <WppAvatar
          slot="left"
          name={`${user.firstName} ${user.lastName}`}
          size="xs"
          style={userAvatarStyle}
        />
        <p slot="label">
          {user.firstName}&nbsp;{user.lastName}
        </p>
      </WppListItem>
      {_.map(filteredItems(), ({ to, id, IconComponent, name }) => (
        <WppListItem key={id} onWppChangeListItem={() => navigate(to)}>
          <IconComponent slot="left" />
          <p slot="label">{name}</p>
        </WppListItem>
      ))}
      <Logout />
    </WppMenuContext>
  );
};

export default UserOptionsComponent;
