import React, { Component } from 'react';
import { truncate } from 'utils/formattingUtils';

type Props = {
  /** The text to truncate. */
  text: string,
  /** The maximum length of the displayed text. */
  length: number,
};

type State = {
  expand: boolean,
};

class ExpandText extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expand: false,
    };
  }

  onExpand() {
    this.setState((prevState) => ({
      expand: !prevState.expand,
    }));
  }

  render() {
    if (this.props.text.length < (this.props.length - 3)) {
      return <div style={{ padding: '0 1em' }}>{this.props.text}</div>;
    }

    return (
      <div
        style={{ cursor: 'pointer', color: 'inherit', padding: '0 1em' }}
        onClick={() => this.onExpand()}
        role="button"
        tabIndex={0}
      >
        {this.state.expand ? this.props.text : truncate(this.props.text, this.props.length)}
      </div>
    );
  }
}

export default ExpandText;
