import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateTimeFormatter } from 'utils/dateTime';
import { WppGrid, WppTypography, WppTag, WppButton, WppModal } from 'buildingBlocks';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { colorForStatus, jobStatusText, jobStatusVariant } from 'containers/Jobs/utils';
import { JobStatusValue } from 'containers/Jobs/types';
import { Member, Datum } from 'utils/types';
import DatumRow from 'components/DatumRow';
import FullScreenModal from 'components/FullScreenModal';
import fetchTaskDetails from './actions';
import { containerLayout, buttonGroupContainer, modalButtonAlignment } from './style';

type Task = {
  id: number
  request: string | object
  response: string | object
  statusDetail: string
  status: JobStatusValue
  createdAt: string
  updatedAt: string
  externalId: string
  type: string
  member: Member
  uuid?: string
};

type TaskCardProps = {
  type: string
  task: Task
};

const TaskCard: React.FC<TaskCardProps> = ({ type, task }) => {
  const dispatch = useDispatch();
  const requestResponseDetails = useSelector(
    (state: any) => state.taskDetail.requestResponseDetails,
  );

  const data: Datum[] = [
    {
      label: 'Status',
      value: <WppTag label={jobStatusText(task)} variant={jobStatusVariant(task)} categoricalColorIndex={colorForStatus(task.status)} />,
    },
    {
      label: 'Created',
      value: <span>{dateTimeFormatter.dateTime(task.createdAt, true)}</span>,
    },
    {
      label: 'Updated',
      value: <span>{dateTimeFormatter.dateTime(task.updatedAt, true)}</span>,
    },
    {
      label: 'External ID',
      value: task.externalId,
    },
    {
      label: 'Internal ID',
      value: task.id,
    },
  ];

  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
  const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);
  const [responseModalOpen, setResponseModalOpen] = useState<boolean>(false);

  const requestParams = {
    type,
    field: 'request',
    id: task.id,
    content: task.request,
  };

  const responseParams = {
    type,
    field: 'response',
    id: task.id,
    content: task.response,
  };

  const handleDetailsModalOpen = () => {
    setDetailsModalOpen(true);
  };

  const handleRequestModalOpen = () => {
    setRequestModalOpen(true);
    dispatch(fetchTaskDetails(requestParams)); // Use dispatch directly
  };

  const handleResponseModalOpen = () => {
    setResponseModalOpen(true);
    dispatch(fetchTaskDetails(responseParams));
  };

  const handleDetailsModalClose = () => {
    setDetailsModalOpen(false);
  };

  const handleRequestModalClose = () => {
    setRequestModalOpen(false);
  };

  const handleResponseModalClose = () => {
    setResponseModalOpen(false);
  };

  return (
    <WppGrid container fullWidth style={{ ...wppBodyContainer, ...containerLayout }}>
      <WppGrid item all={24}>
        <WppTypography tag="p" type="xl-heading">{_.isEqual(type, 'taskSync') ? 'Task Sync' : type}</WppTypography>
      </WppGrid>
      <WppGrid item all={24}>
        {data.map((datum) => (
          <DatumRow key={datum.label} {...datum} />
        ))}
      </WppGrid>
      <WppGrid item all={24} style={buttonGroupContainer}>
        <WppButton variant="secondary" size="s" onClick={handleDetailsModalOpen}>Details</WppButton>
        {detailsModalOpen && (
          <FullScreenModal
            detailsModalOpen={detailsModalOpen}
            handleDetailsModalClose={handleDetailsModalClose}
            headerTitle={`Task Details for ${type || ''}`}
            body={`test - ${task.statusDetail || ''}`}
          />
        )}
        <WppButton variant="secondary" size="s" onClick={handleRequestModalOpen} disabled={!task.request}>Request</WppButton>
        <WppModal
          size="s"
          open={requestModalOpen}
          onWppModalClose={handleRequestModalClose}
        >
          <WppTypography type="xl-heading" slot="header">Task Request for {type}</WppTypography>
          <WppTypography type="s-body" slot="body">{requestResponseDetails}</WppTypography>
          <div slot="actions" style={modalButtonAlignment}>
            <WppButton variant="primary" size="s" onClick={handleRequestModalClose}>Done</WppButton>
          </div>
        </WppModal>
        <WppButton variant="secondary" size="s" onClick={handleResponseModalOpen} disabled={!task.response}>Response</WppButton>
        <WppModal
          size="s"
          open={responseModalOpen}
          onWppModalClose={handleResponseModalClose}
        >
          <WppTypography type="xl-heading" slot="header">Task Response for {type}</WppTypography>
          <WppTypography type="s-body" slot="body">{requestResponseDetails}</WppTypography>
          <div slot="actions" style={modalButtonAlignment}>
            <WppButton variant="primary" size="s" onClick={handleResponseModalClose}>Done</WppButton>
          </div>
        </WppModal>
      </WppGrid>
    </WppGrid>
  );
};

export default TaskCard;
