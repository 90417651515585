import React, { CSSProperties } from 'react';
import _ from 'lodash';
import { DSP } from 'constantsBase';
import dspIconStyles from './style';

type Props = {
  dspId: number
  iconStyles?: CSSProperties
  style?: CSSProperties
};

const DspIcon = ({ dspId, style, iconStyles = {} }: Props) => {
  const dspContent = DSP.getById(dspId);
  const dspCode = _.get(dspContent, 'code');
  const dspSpanStyle: CSSProperties = { ...dspIconStyles.base, ...dspIconStyles[dspCode], ...iconStyles };

  return (
    <span style={style}>
      <span style={dspSpanStyle}>
        {dspCode}
      </span>
    </span>
  );
};

export default DspIcon;
