import React, { ChangeEvent, CSSProperties, SyntheticEvent } from 'react';
import Dropzone, { DropFilesEventHandler } from 'react-dropzone';
import { Form } from 'buildingBlocks';
import CustomTextArea from 'components/CustomTextArea';
import { dropzoneStyle } from './style';

type OnChangeFunction = (event: ChangeEvent<HTMLTextAreaElement>, data: unknown) => void;
type OnClickFunction = (event: SyntheticEvent) => void;

type CodeMirrorTextAreaFieldProps = {
  name?: string
  isDisabled?: boolean
  onChange?: OnChangeFunction
  height: number
  value?: any
};

const CodeMirrorTextAreaField = ({
  value, name, isDisabled, onChange, height,
}: CodeMirrorTextAreaFieldProps) => (
  // @ts-ignore
  <CustomTextArea
    value={value}
    name={name}
    onChange={onChange}
    disabled={isDisabled}
    height={height}
    mode="python"
  />
);

type BrowseDragDropPasteInputTextZoneProps = {
  name?: string
  multiple?: boolean
  hasInputTxt?: boolean
  isDisabled?: boolean
  onDrop?: DropFilesEventHandler
  onChange?: OnChangeFunction
  height?: number
  dropzonestyle?: CSSProperties
  onClick?: OnClickFunction
  accept?: string | Array<string>
  children?: React.ReactNode
};

const BrowseDragDropPasteInputTextZone = ({
  name,
  multiple = false,
  hasInputTxt = false,
  isDisabled,
  onChange,
  onDrop,
  height = 300,
  dropzonestyle,
  onClick,
  accept,
  children,
}: BrowseDragDropPasteInputTextZoneProps) => {
  const baseStyle = dropzonestyle ?? dropzoneStyle.container;
  const style = hasInputTxt
    ? { ...baseStyle, border: 0, opacity: 0.1 }
    : { ...baseStyle, opacity: 1 };
  return (
    <div style={{ position: 'relative' }}>
      <Form.Field disabled={isDisabled} style={{ borderRadius: '10px' }}>
        {hasInputTxt && (
          <CodeMirrorTextAreaField
            name={name}
            isDisabled={isDisabled}
            onChange={onChange}
            height={height}
          />
        )}
        <Dropzone
          onDrop={onDrop}
          onClick={onClick}
          accept={accept}
          multiple={multiple}
          style={style}
        >
          {children ?? (
            <>
              <p style={dropzoneStyle.headerText}>
                Drag & Drop your file here, or <span style={dropzoneStyle.browseText}>Browse</span>
              </p>
              <p style={dropzoneStyle.subtitleText}>
                Alternatively, type or paste into this field
              </p>
            </>
          )}
        </Dropzone>
      </Form.Field>
    </div>
  );
};

export default BrowseDragDropPasteInputTextZone;
