import _ from 'lodash';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { Container, Button, Icon } from 'buildingBlocks';
import { titleStyle, msgStyle, centerAuto, btnStyle } from './styles';

type Props = {
  button?: boolean | { url: string, content: string },
  message?: string | ReactElement,
  customFooter?: ReactElement,
};

const ForbiddenPage = ({ button = true, message, customFooter }: Props) => (
  <>
    <DocumentTitle title="Access Denied" />
    <Container
      fluid
      textAlign="center"
    >
      <div style={centerAuto}>
        <Icon
          size="massive"
          name="lock"
          circular
          inverted
          className="page-forbidden-lock-icon"
        />
        <h1 style={titleStyle}>- 403 -</h1>
        <div style={msgStyle}>
          {message ?? 'You do not have permission to view this page.'}
        </div>
        {(_.isNil(customFooter) && button)
          && (
          <Link to={_.get(button, 'url', '/')}>
            <Button
              style={btnStyle}
              className="primary hover-link"
              content={_.get(button, 'content', 'Go to listing page')}
            />
          </Link>
          )}
        {!_.isNil(customFooter) && !button && customFooter}
      </div>
    </Container>
  </>
);

export default ForbiddenPage;
