import React from 'react';
import { connect } from 'react-redux';
import { dateTimeFormatter } from 'utils/dateTime';
import JobStatusLabel from 'components/JobStatusLabel/index';
import {
  Button, Card, Modal, Table,
} from 'buildingBlocks';
import { colorForStatus } from 'containers/Jobs/utils';
import fetchTaskDetails from './actions';
import { cardHeader } from './style';

const DatumRow = (props: { label: string, value: string | number }) => (
  <Table.Row>
    <Table.Cell>
      <b>
        {props.label}
      </b>
    </Table.Cell>
    <Table.Cell>
      {props.value}
    </Table.Cell>
  </Table.Row>
);

/**
 * A "card" component showing basic details for a "Task".
 *
 * Note that tasks in general have been largely supplanted by DAGs on the
 * backend, so this component may no longer be useful.
 */
class TaskCard extends React.Component<Props> {
  data = (props) => [
    {
      label: 'Status',
      value: <JobStatusLabel
        job={props.task}
        color={colorForStatus(props.task.status)}
      />,
    },
    {
      label: 'Created',
      value: <span>{dateTimeFormatter.dateTime(props.task.createdAt, true)}</span>,
    },
    {
      label: 'Updated',
      value: <span>{dateTimeFormatter.dateTime(props.task.updatedAt, true)}</span>,
    },
    {
      label: 'External ID',
      value: props.task.externalId,
    },
    {
      label: 'Internal ID',
      value: props.task.id,
    },
  ];

  render() {
    const type = this.props.type;
    const id = this.props.task.id;

    const requestParams = {
      type, field: 'request', id, content: this.props.task.request,
    };
    const responseParams = {
      type, field: 'response', id, content: this.props.task.response,
    };

    return (
      <Card fluid>
        <Card.Content style={cardHeader}>
          <Card.Header>
            {type}
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Table basic="very" celled>
            <Table.Body>
              {this.data(this.props).map((datum) => (
                <DatumRow key={datum.label} {...datum} />
              ))}
            </Table.Body>
          </Table>
        </Card.Content>

        <Card.Content extra>
          <Button.Group size="mini">
            <Modal trigger={<Button>Details</Button>}>
              <Modal.Header>
                Task Details for {type}
              </Modal.Header>
              <Modal.Content>
                <pre>
                  {this.props.task.statusDetail}
                </pre>
              </Modal.Content>
            </Modal>
            <Modal
              trigger={(
                <Button
                  onClick={() => this.props.fetchTaskDetails(requestParams)}
                  disabled={!this.props.task.request}
                >Request
                </Button>
              )}
            >
              <Modal.Header>
                Task Request for {type}
              </Modal.Header>
              <Modal.Content>
                <pre>
                  {this.props.requestResponseDetails}
                </pre>
              </Modal.Content>
            </Modal>
            <Modal
              trigger={(
                <Button
                  onClick={() => this.props.fetchTaskDetails(responseParams)}
                  disabled={!this.props.task.response}
                >Response
                </Button>
              )}
            >
              <Modal.Header>
                Task Response for {type}
              </Modal.Header>
              <Modal.Content>
                <pre>
                  {this.props.requestResponseDetails}
                </pre>
              </Modal.Content>
            </Modal>
          </Button.Group>
        </Card.Content>
      </Card>
    );
  }
}

type Props = {
  type: string,
  fetchTaskDetails: Function,
  task: {
    id: number,
    request: string | object,
    response: string | object,
    statusDetail: string,
  },
  requestResponseDetails: string,
};

const mapStateToProps = (state) => ({
  requestResponseDetails: state.taskDetail.requestResponseDetails,
});

export default connect(mapStateToProps, { fetchTaskDetails })(TaskCard);
