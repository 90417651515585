import React from 'react';
import { Container, Segment, Grid, Button } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import PageTemplate from 'components/PageTemplate';

type Props = {
  msg: string | null
  onBack?: (event: React.MouseEvent<HTMLButtonElement>) => void
  pageTitle?: string
  buttonText?: string
  multiLineMsg?: boolean
};

const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;
const ErrorComponent = ({ msg, onBack, pageTitle = '', buttonText = 'Go Back', multiLineMsg = false }: Props) => (
  <PageTemplate title={pageTitle} name={pageTitle}>
    <Container>
      <Segment style={{ backGround: NEUTRALS.N0_WHITE, textAlign: 'center' }}>
        <div style={{ margin: '120px 0', ...(multiLineMsg && { whiteSpace: 'break-spaces' }) }}>{msg}</div>
        {onBack && (
          <Grid columns={2} stackable textAlign="center">
            <Grid.Row>
              <Button primary style={{ marginTop: '10px' }} onClick={onBack}>{buttonText}</Button>
            </Grid.Row>
          </Grid>
        )}
      </Segment>
    </Container>
  </PageTemplate>
);

export default ErrorComponent;
