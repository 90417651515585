import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { BLUES } } = COPILOT_COLORS;
type Props = {
  linkTo: string,
  name: string,
};

const TextLinkBack = ({
  linkTo,
  name,
} : Props) => (
  <Link to={linkTo}>
    <Icon size="small" name="chevron left" />
    <span style={{ textTransform: 'uppercase', color: BLUES.B500_WAVE }}>{name}</span>
  </Link>
);

export default TextLinkBack;
