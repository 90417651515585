import React from 'react';
import { WppTag } from 'buildingBlocks';
import { NmsObjectTags } from './constants';
import nmsIconStyles from './style';

type Props = {
  isProgrammaticGuaranteed: boolean
};

const NMSIcon = ({ isProgrammaticGuaranteed }: Props) => {
  const nmsObjectTag = isProgrammaticGuaranteed ? NmsObjectTags.pg.label : NmsObjectTags.rtb.label;
  const colorCode = isProgrammaticGuaranteed ? NmsObjectTags.pg.color : NmsObjectTags.rtb.color;

  return (
    <WppTag label={nmsObjectTag} style={nmsIconStyles.base} categoricalColorIndex={colorCode} />
  );
};

export default NMSIcon;
