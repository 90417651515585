import _ from 'lodash';
import React, { ReactElement, useState } from 'react';
import PageForbidden from 'containers/403';
import { PageForbiddenMessage, PageForbiddenFooter } from 'containers/403/components/PageForbiddenStrategy';
import PageNotFound from 'containers/404';
import { Member } from 'utils/copilotAPI';
import { useAsyncEffect } from 'utils/functionHelpers';
import { User, Member as MemberType } from 'utils/types';

type Strategy403Props = {
  strategyId: string
  user: User
  loadingComponent: ReactElement
};

const Strategy403 = ({ strategyId, user, loadingComponent }: Strategy403Props) => {
  const [unauthorizedMembers, setUnauthorizedMembers] = useState<Array<Pick<MemberType, 'dsp' | 'displayName' | 'externalId'>>>(null);
  const [error, setError] = useState<string>(null);

  useAsyncEffect(async () => {
    try {
      const res = await Member.getUnauthorizedStrategyMembers({ id: strategyId });
      setUnauthorizedMembers(res.data);
    } catch (e) {
      setError(_.get(e, 'response.data', `Strategy ${strategyId} does not exist`));
    }
  }, []);

  if (!unauthorizedMembers && !error) {
    return loadingComponent;
  }

  if (error) {
    return <PageNotFound button />;
  }

  return (
    <PageForbidden
      message={<PageForbiddenMessage strategyId={strategyId} userId={user.id} unauthorizedMembers={unauthorizedMembers} />}
      customFooter={<PageForbiddenFooter userId={user.id} />}
      button={false}
    />
  );
};

export default Strategy403;
