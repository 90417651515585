import { TASK_FETCH_REQUEST_RESPONSE_DETAILS_COMPLETED, TASK_FETCH_REQUEST_RESPONSE_DETAILS_FAILED } from './constants';

const INITIAL_STATE = {
  requestResponseDetails: '',
};

export default function taskDetail(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TASK_FETCH_REQUEST_RESPONSE_DETAILS_COMPLETED:
      return { ...state, requestResponseDetails: action.payload };
    case TASK_FETCH_REQUEST_RESPONSE_DETAILS_FAILED:
      return INITIAL_STATE;
    default:
      return state;
  }
}
